import { IonInput, IonButton } from '@ionic/react';
import '../../../theme/Home.css';
import { modalToggleStore, userFormStore } from '../../../store/Store';
const Profile2 = () => {
    const lastNameEmpty = modalToggleStore.useState((s) => s.lastNameEmpty);
    const detailUserForm = userFormStore.useState((s) => s.detailUserForm);
    const updateFormDoc = (value, field) => {
        if (value && field === 'last') {
            modalToggleStore.update((s) => {
                s.lastNameEmpty = false;
            });
            userFormStore.update((s) => {
                s.detailUserForm = { ...s.detailUserForm, last: value };
            });
        } else if (!value && field === 'last') {
            modalToggleStore.update((s) => {
                s.lastNameEmpty = true;
            });
        }
    };
    const openThirdPage = () => {
        modalToggleStore.update((s) => {
            s.profilePage = 3;
        });
    };
    return (
        <>
            <h2 className='ion-padding'>Hi, {detailUserForm.first}! What is your last name?</h2>
            <br />
            <IonInput
                className='ion-padding-horizontal'
                value={detailUserForm.last}
                placeholder='Enter last name'
                onIonChange={(e) => {
                    updateFormDoc(e.target.value, 'last');
                }}
            />
            <br /> <br />
            <IonButton
                className='ion-padding-horizontal'
                disabled={lastNameEmpty}
                shape='round'
                expand='block'
                color='tertiary'
                onClick={openThirdPage}
            >
                Continue
            </IonButton>
        </>
    );
};

export default Profile2;
