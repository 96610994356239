import { IonButton } from '@ionic/react';
import '../../../theme/Home.css';
import * as dayjs from 'dayjs';
import {
    toggleStore,
    appStore,
    permitFormStore,
    permitsStore,
    coordsStore,
} from '../../../store/Store';
import { loadStripe } from '@stripe/stripe-js';
import {
    fetchPermitTypeByName,
    addPermit,
    createSubCheckout,
    sendPermitEmailToAdmin,
} from '../../../firebaseConfig';
const ApplyPermit6 = () => {
    const subscribeToggle = toggleStore.useState((s) => s.subscribeToggle);
    const userInfo = appStore.useState((s) => s.userInfo);
    const permitForm = permitFormStore.useState((s) => s.permitForm);
    const currentTypeValid = permitsStore.useState((s) => s.currentTypeValid);
    const currentPermitAddress = permitsStore.useState((s) => s.currentPermitAddress);
    const currentBurnCoord = coordsStore.useState((s) => s.currentBurnCoord);

    const checkout = () => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });
        // console.log('permitForm', permitForm);
        fetchPermitTypeByName(permitForm.type).then((priceId) => {
            addPermit(permitForm, userInfo, currentPermitAddress, currentBurnCoord, priceId).then(
                async (permit) => {
                    if (permitForm.type === 'free') {
                        sendPermitEmailToAdmin(permit);
                    }
                    permit = { ...permit, subscribed: subscribeToggle };

                    const stripe = await loadStripe(
                        'pk_live_51KEN5sIiEock6T4NDeYtHGtuCNI81lBI5zWOzKavppVS4nOOVVTYYOKs30xYmVryMIZmv2wu1YBZYZHgY4v583Jm001xXdQSFz'
                    );
                    createSubCheckout({
                        ...permit,
                        url: window.location.origin,
                    }).then(async (response) => {
                        const sessionId = response.data;
                        stripe.redirectToCheckout({
                            sessionId: sessionId,
                        });
                    });
                }
            );
        });
    };
    return (
        <>
            <h2 className='ion-padding'>Confirm your application</h2>
            <br />
            <h5 className='ion-padding-horizontal'>Applicant</h5>
            <p className='ion-padding-horizontal'>
                {userInfo.first} {userInfo.last}
            </p>
            <h5 className='ion-padding-horizontal'>Type</h5>
            <p className='ion-padding-horizontal'>{permitForm.type}</p>
            <h5 className='ion-padding-horizontal'>Valid</h5>
            <p className='ion-padding-horizontal'>
                {dayjs().format('YYYY-MM-DD')} to{' '}
                {dayjs().add(currentTypeValid, 'day').format('YYYY-MM-DD')}{' '}
            </p>
            <h5 className='ion-padding-horizontal'>Address</h5>
            <p className='ion-padding-horizontal'>{currentPermitAddress}</p>
            <br />
            <IonButton
                className='ion-padding-horizontal'
                shape='round'
                expand='block'
                color='tertiary'
                onClick={checkout}
            >
                Proceed to payment
            </IonButton>
        </>
    );
};

export default ApplyPermit6;
