// importing components for react-google-map
import {
    LoadScript,
    GoogleMap,
    DrawingManager,
    Polygon,
    Marker,
    Circle,
    InfoWindow,
} from '@react-google-maps/api';
import { useEffect, useRef } from 'react';
import '../../theme/DashboardMap.css';
import { documentOutline } from 'ionicons/icons';
import activeLogo from '../../images/ActiveFlameLogo.png';
import inactiveLogo from '../../images/InactiveFlameLogo.png';
import {
    coordsStore,
    appStore,
    shapeStore,
    toggleStore,
    permitsStore,
    profileFormStore,
} from '../../store/Store';
import {
    fetchPolygonCoords,
    fetchCircleCoords,
    fetchRectangleCoords,
    updatePermitBurnCoord,
    updatePermitActiveness,
    fetchPermitActiveness,
    fetchPermitWithID,
} from '../../firebaseConfig';
import PermitForm from './PermitForm';
import PermitTypeForm from './PermitTypeForm';
import { IonHeader, IonToggle, IonIcon, IonText } from '@ionic/react';
import { useStoreState } from 'pullstate';

function DashboardMap() {
    const polygonCoords = coordsStore.useState((s) => s.polygonCoords);
    const circleCoords = coordsStore.useState((s) => s.circleCoords);
    const rectangleCoords = coordsStore.useState((s) => s.rectangleCoords);
    const mapCenterCoord = coordsStore.useState((s) => s.mapCenterCoord);
    const selectedCoord = coordsStore.useState((s) => s.selectedCoord);
    const markerCoord = appStore.useState((s) => s.markerCoord);
    const drawToggle = appStore.useState((s) => s.drawToggle);
    const permitFormToggle = toggleStore.useState((s) => s.permitFormToggle);
    const currentBurnCoord = coordsStore.useState((s) => s.currentBurnCoord);
    const idForActiveness = permitsStore.useState((s) => s.idForActiveness);
    const activeness = permitsStore.useState((s) => s.activeness);
    const permits = permitsStore.useState((s) => s.permits);
    const permitTypeFormToggle = toggleStore.useState((s) => s.permitTypeFormToggle);
    const currentShapeDataForMap = shapeStore.useState((s) => s.currentShapeDataForMap);

    const { mapsAPI } = useStoreState(profileFormStore);
    //next couple lines to prevent google map recenter after each re render
    const mapRef = useRef(null);
    const onMapLoad = (map) => {
        mapRef.current = map;
    };
    function handleCenter() {
        if (!mapRef.current) return;
        const lat = mapRef.current.getCenter().lat();
        const lng = mapRef.current.getCenter().lng();
        const newCenter = { lat, lng };
        coordsStore.update((s) => {
            s.mapCenterCoord = newCenter;
        });
    }
    const onDrawingLoad = (drawingManager) => {
        return;
    };
    const onPolygonComplete = (polygon) => {
        shapeStore.update((s) => {
            s.currentShapeData = [polygon.getPath().getArray().toString()];
        });

        shapeStore.update((s) => {
            s.currentShape = 'Polygon';
        });
        appStore.update((s) => {
            s.drawToggle = false;
        });
        appStore.update((s) => {
            s.formToggle = true;
        });
    };

    const onRectangleComplete = (rectangle) => {
        shapeStore.update((s) => {
            s.currentShapeData = rectangle;
        });

        shapeStore.update((s) => {
            s.currentShape = 'Rectangle';
        });
        appStore.update((s) => {
            s.drawToggle = false;
        });
        appStore.update((s) => {
            s.formToggle = true;
        });
    };

    const onCircleComplete = (circle) => {
        shapeStore.update((s) => {
            s.currentShapeData = circle;
        });

        shapeStore.update((s) => {
            s.currentShape = 'Circle';
        });
        appStore.update((s) => {
            s.drawToggle = false;
        });
        appStore.update((s) => {
            s.formToggle = true;
        });
    };

    useEffect(() => {
        fetchPolygonCoords().then((data) => {
            // after fetching data from firestore, transform it into the correct form so that the polygon component can use
            let final1Data = [];
            if (data.length > 0) {
                data.forEach((coord) => {
                    let finalData = [];
                    let transformedData = null;

                    transformedData = coord.coords
                        .toString()
                        .replace(/[{()}]/g, '')
                        .replace(/ /g, '')
                        .split(',')
                        .map((coord) => parseFloat(coord));
                    for (let i = 0; i < transformedData.length; i = i + 2) {
                        finalData.push({
                            lng: transformedData[i + 1],
                            lat: transformedData[i],
                        });
                    }
                    final1Data.push({
                        coords: finalData,
                        id: coord.id,
                        info: coord.info,
                    });
                });
                coordsStore.update((s) => {
                    s.polygonCoords = final1Data;
                });
            }
            return;
        });

        fetchCircleCoords().then((data) => {
            coordsStore.update((s) => {
                s.circleCoords = data;
            });
        });

        fetchRectangleCoords().then((data) => {
            coordsStore.update((s) => {
                s.rectangleCoords = data;
            });
        });
    }, []);

    //find which circle it clicked then set state:currentShapeDataForMap to that shape
    const clickCircle = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const coord = { lat, lng };
        coordsStore.update((s) => {
            s.selectedCoord = coord;
        });
        circleCoords.forEach((coord) => {
            const circleRaidusInDegree = (coord.radius / 1.11) * 0.00001;
            const circleX = coord.coords.lng;
            const circleY = coord.coords.lat;
            const xDifference = Math.abs(lng - circleX);
            const yDifference = Math.abs(lat - circleY);
            const pointRaidus = Math.sqrt(xDifference * xDifference + yDifference * yDifference);

            if (circleRaidusInDegree > pointRaidus) {
                shapeStore.update((s) => {
                    s.currentShapeDataForMap = coord;
                });
            }
        });
    };

    //find which Rectangle it clicked then set state:currentShapeDataForMap to that shape
    const clickRectangle = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const coord = { lat, lng };
        coordsStore.update((s) => {
            s.selectedCoord = coord;
        });
        rectangleCoords.forEach((coord) => {
            if (
                coord.coords[2].lat > lat &&
                coord.coords[0].lat < lat &&
                coord.coords[2].lng > lng &&
                coord.coords[0].lng < lng
            ) {
                shapeStore.update((s) => {
                    s.currentShapeDataForMap = coord;
                });
            }
        });
    };

    //find which Polygon it clicked then set state:currentShapeDataForMap to that shape
    const clickPolygon = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const coord = { lat, lng };
        coordsStore.update((s) => {
            s.selectedCoord = coord;
        });

        polygonCoords.forEach((coord) => {
            let count = 0;

            for (let i = 0; i < coord.coords.length - 1; i++) {
                if (
                    (coord.coords[i].lat > lat && coord.coords[i + 1].lat < lat) ||
                    (coord.coords[i].lat < lat && coord.coords[i + 1].lat > lat)
                ) {
                    const slope =
                        (coord.coords[i + 1].lat - coord.coords[i].lat) /
                        (coord.coords[i + 1].lng - coord.coords[i].lng);
                    const intercept = coord.coords[i + 1].lat - slope * coord.coords[i + 1].lng;
                    const cross = (lat - intercept) / slope;
                    if (cross > lng) {
                        count = count + 1;
                    }
                }
            }

            if (count % 2 === 1) {
                shapeStore.update((s) => {
                    s.currentShapeDataForMap = coord;
                });
            }
        });
    };
    const dragMarker = (e) => {
        const burnCoord = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };
        coordsStore.update((s) => {
            s.currentBurnCoord = burnCoord;
        });
    };

    const onMarkerMouseUp = (id) => {
        permitsStore.update((s) => {
            s.idForActiveness = id;
        });
        if (currentBurnCoord) {
            updatePermitBurnCoord(currentBurnCoord, id);
        } else {
            fetchPermitActiveness(id).then((data) => {
                permitsStore.update((s) => {
                    s.activeness = data;
                });
            });
            fetchPermitWithID(id).then((data) => {
                permitsStore.update((s) => {
                    s.permit = data;
                });
            });
        }
    };

    const showPermitInfo = (e) => {
        shapeStore.update((s) => {
            s.currentShapeDataForMap = null;
        });
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const coord = { lat, lng };
        coordsStore.update((s) => {
            s.selectedCoord = coord;
        });
    };

    const togglePermitActiveness = () => {
        updatePermitActiveness(idForActiveness, !activeness);

        permitsStore.update((s) => {
            s.activeness = !activeness;
        });
    };

    const clickActivateToggle = () => {
        const tempPermits = permits.map((permit) => {
            if (permit.id === idForActiveness) {
                const activate = permit.activate;

                permit = { ...permit, activate: !activate };
                return permit;
            } else {
                return permit;
            }
        });
        permitsStore.update((s) => {
            s.permits = tempPermits;
        });
    };

    const pdfGenerate = () => {
        window.location.href = `/permit/${idForActiveness}`;
    };

    return (
        <>
            <div className='main'>
                <div className='App'>
                    <LoadScript
                        id='script-loader'
                        googleMapsApiKey={`${mapsAPI}&libraries=drawing,places`}
                        language='en'
                        region='us'
                    >
                        {permitFormToggle && <PermitForm />}
                        {permitTypeFormToggle && <PermitTypeForm />}

                        {!permitFormToggle && !permitTypeFormToggle && (
                            <>
                                <br />
                                <GoogleMap
                                    mapContainerClassName='App-map'
                                    zoom={15}
                                    mapTypeId='satellite'
                                    onLoad={onMapLoad}
                                    onDragEnd={handleCenter}
                                    center={mapCenterCoord}
                                    version='weekly'
                                    on
                                >
                                    {drawToggle && (
                                        <DrawingManager
                                            onLoad={onDrawingLoad}
                                            onPolygonComplete={onPolygonComplete}
                                            onRectangleComplete={onRectangleComplete}
                                            onCircleComplete={onCircleComplete}
                                        />
                                    )}

                                    {markerCoord.lat && <Marker position={markerCoord} />}
                                    {permits &&
                                        permits.map((permit) => {
                                            let logo;
                                            if (permit.activate === false) {
                                                logo = inactiveLogo;
                                            } else {
                                                logo = activeLogo;
                                            }

                                            return (
                                                <Marker
                                                    key={permit.id}
                                                    icon={logo}
                                                    onDragEnd={dragMarker}
                                                    onClick={showPermitInfo}
                                                    onMouseUp={() => {
                                                        onMarkerMouseUp(permit.id);
                                                    }}
                                                    draggable
                                                    position={permit.burnCoord}
                                                />
                                            );
                                        })}
                                    {/*For each circle coord output a circle  */}
                                    {circleCoords.map((coord) => {
                                        return (
                                            <Circle
                                                center={coord.coords}
                                                radius={coord.radius}
                                                key={coord.id}
                                                onClick={clickCircle}
                                                options={{
                                                    strokeColor: '#FF0000',
                                                    strokeOpacity: 0.8,
                                                    strokeWeight: 2,
                                                    fillColor: '#FF0000',
                                                    fillOpacity: 0.35,
                                                }}
                                            />
                                        );
                                    })}
                                    {/*For each polygon coord output a polygon  */}
                                    {polygonCoords.map((coord) => {
                                        return (
                                            <Polygon
                                                onClick={clickPolygon}
                                                path={coord.coords}
                                                key={coord.id}
                                                options={{
                                                    strokeColor: '#FF0000',
                                                    strokeOpacity: 0.8,
                                                    strokeWeight: 2,
                                                    fillColor: '#FF0000',
                                                    fillOpacity: 0.35,
                                                }}
                                            />
                                        );
                                    })}
                                    {/*For each rectangle coord output a rectangle  */}
                                    {rectangleCoords.map((coord) => {
                                        return (
                                            <Polygon
                                                onClick={clickRectangle}
                                                path={coord.coords}
                                                key={coord.id}
                                                options={{
                                                    strokeColor: '#FF0000',
                                                    strokeOpacity: 0.8,
                                                    strokeWeight: 2,
                                                    fillColor: '#FF0000',
                                                    fillOpacity: 0.35,
                                                }}
                                            />
                                        );
                                    })}

                                    {/* when a shape is clicked, set latlng to selectedCoord and show infowindow */}
                                    {selectedCoord && currentShapeDataForMap && (
                                        <InfoWindow
                                            position={selectedCoord}
                                            onCloseClick={() => {
                                                coordsStore.update((s) => {
                                                    s.selectedCoord = null;
                                                });
                                                shapeStore.update((s) => {
                                                    s.currentShapeDataForMap = null;
                                                });
                                            }}
                                        >
                                            <div className='info-window'>
                                                <IonHeader>Info</IonHeader>
                                                <br />
                                                <IonText>Name: </IonText>
                                                <IonText>
                                                    {currentShapeDataForMap.info.name}
                                                </IonText>
                                                <br />
                                                <IonText>Description: </IonText>
                                                <IonText>
                                                    {currentShapeDataForMap.info.description}
                                                </IonText>
                                            </div>
                                        </InfoWindow>
                                    )}
                                    {selectedCoord && !currentShapeDataForMap && (
                                        <InfoWindow
                                            position={selectedCoord}
                                            onCloseClick={() => {
                                                coordsStore.update((s) => {
                                                    s.selectedCoord = null;
                                                });
                                            }}
                                        >
                                            <div className='info-window'>
                                                <IonHeader>
                                                    <h6>Permit</h6>
                                                </IonHeader>
                                                <div>
                                                    <IonText className='ion-padding'>
                                                        Active:
                                                    </IonText>
                                                    <br />
                                                    <IonToggle
                                                        checked={activeness}
                                                        onClick={clickActivateToggle}
                                                        onIonChange={togglePermitActiveness}
                                                    />
                                                </div>
                                                <IonText className='ion-padding'>
                                                    Download:{' '}
                                                </IonText>
                                                <br />
                                                <br />
                                                <IonIcon
                                                    onClick={pdfGenerate}
                                                    icon={documentOutline}
                                                />
                                            </div>
                                        </InfoWindow>
                                    )}
                                </GoogleMap>
                            </>
                        )}
                    </LoadScript>
                </div>
            </div>
        </>
    );
}

export default DashboardMap;
