import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonImg,
    IonCard,
    IonLoading,
} from '@ionic/react';
import { useEffect } from 'react';
import { menu } from 'ionicons/icons';
import '../../theme/Settings.css';
import 'bootstrap/dist/css/bootstrap.css';
import DepartmentInfo from '../../components/admin/DepartmentInfo';
import Menu from '../../components/admin/Menu';
import { settingsToggleStore, profileFormStore, rulesStore, appStore } from '../../store/Store';
import { fetchProfile } from '../../firebaseConfig';
import RulesEditor from '../../components/admin/RulesEditor';
import AdminList from '../../components/admin/AdminList';
import logo from '../../images/firepermitonlinelogo.png';
import UploadImg from '../../components/admin/UploadImg';
import LandingTogglesControl from '../../components/admin/LandingTogglesControl';
import SubscriptionControl from '../../components/admin/SubscriptionControl';
import StreetsEditor from '../../components/admin/StreetsEditor';
function Settings() {
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    const rulesInDraft = rulesStore.useState((s) => s.rulesInDraft);
    const isProfile = settingsToggleStore.useState((s) => s.isProfile);
    const isHome = settingsToggleStore.useState((s) => s.isHome);
    const isAddAdmin = settingsToggleStore.useState((s) => s.isAddAdmin);
    const isLoginToggles = settingsToggleStore.useState((s) => s.isLoginToggles);
    const isTerms = settingsToggleStore.useState((s) => s.isTerms);
    const isStreets = settingsToggleStore.useState((s) => s.isStreets);
    const isSubscription = settingsToggleStore.useState((s) => s.isSubscription);

    useEffect(() => {
        fetchProfile('parrysound').then((data) => {
            if (data) {
                profileFormStore.update((s) => {
                    s.settingsForm = data;
                });
                profileFormStore.update((s) => {});
            }
        });
    }, []);

    const updateToggleState = (activeToggle) => {
        settingsToggleStore.update((s) => {
            s.isProfile = false;
            s.isHome = false;
            s.isAddAdmin = false;
            s.isLoginToggles = false;
            s.isTerms = false;
            s.isSubscription = false;
            s.isStreets = false;
            s[activeToggle] = true;
        });
    };

    const clickProfile = () => updateToggleState('isProfile');
    const clickHome = () => updateToggleState('isHome');
    const clickAddAdmin = () => updateToggleState('isAddAdmin');
    const clickLoginToggles = () => updateToggleState('isLoginToggles');
    const clickTerms = () => updateToggleState('isTerms');
    const clickSubscription = () => updateToggleState('isSubscription');
    const clickStreets = () => updateToggleState('isStreets');
    return (
        <IonApp>
            <IonSplitPane when='lg' contentId='main-content'>
                <IonMenu contentId='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary-contrast'>
                            <IonImg src={logo} />
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <Menu />
                    </IonContent>
                </IonMenu>

                <div className='ion-page' id='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                            <IonTitle></IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent className='ion-padding'>
                        <IonLoading
                            isOpen={loadingSpinner}
                            message={'Processing...'}
                            duration={50000}
                        />
                        <IonCard>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-12 col-lg-10 col-xl-8 mx-auto'>
                                        <br />
                                        <h2 className='h3 mb-4 page-title'>Settings</h2>
                                        <div className='my-4'>
                                            <ul
                                                className='nav nav-tabs mb-4'
                                                id='myTab'
                                                role='tablist'
                                            >
                                                <li className='nav-item'>
                                                    <p
                                                        onClick={clickProfile}
                                                        className={
                                                            isProfile
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                        id='home-tab'
                                                        data-toggle='tab'
                                                        role='tab'
                                                        aria-controls='home'
                                                        aria-selected='true'
                                                    >
                                                        Profile
                                                    </p>
                                                </li>
                                                <li className='nav-item'>
                                                    <p
                                                        className={
                                                            isHome ? 'nav-link active' : 'nav-link'
                                                        }
                                                        id='profile-tab'
                                                        data-toggle='tab'
                                                        onClick={clickHome}
                                                        role='tab'
                                                        aria-controls='profile'
                                                        aria-selected='false'
                                                    >
                                                        Home Image
                                                    </p>
                                                </li>
                                                <li className='nav-item'>
                                                    <p
                                                        className={
                                                            isLoginToggles
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                        id='contact-tab'
                                                        data-toggle='tab'
                                                        onClick={clickLoginToggles}
                                                        role='tab'
                                                        aria-controls='contact'
                                                        aria-selected='false'
                                                    >
                                                        Widgets
                                                    </p>
                                                </li>
                                                <li className='nav-item'>
                                                    <p
                                                        className={
                                                            isAddAdmin
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                        id='contact-tab'
                                                        data-toggle='tab'
                                                        onClick={clickAddAdmin}
                                                        role='tab'
                                                        aria-controls='contact'
                                                        aria-selected='false'
                                                    >
                                                        Admins
                                                    </p>
                                                </li>

                                                <li className='nav-item'>
                                                    <p
                                                        className={
                                                            isTerms ? 'nav-link active' : 'nav-link'
                                                        }
                                                        id='contact-tab'
                                                        data-toggle='tab'
                                                        onClick={clickTerms}
                                                        role='tab'
                                                        aria-controls='contact'
                                                        aria-selected='false'
                                                    >
                                                        Terms
                                                    </p>
                                                </li>
                                                <li className='nav-item'>
                                                    <p
                                                        className={
                                                            isSubscription
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                        id='contact-tab'
                                                        data-toggle='tab'
                                                        onClick={clickSubscription}
                                                        role='tab'
                                                        aria-controls='contact'
                                                        aria-selected='false'
                                                    >
                                                        Subscription
                                                    </p>
                                                </li>
                                                <li className='nav-item'>
                                                    <p
                                                        className={
                                                            isStreets
                                                                ? 'nav-link active'
                                                                : 'nav-link'
                                                        }
                                                        id='contact-tab'
                                                        data-toggle='tab'
                                                        onClick={clickStreets}
                                                        role='tab'
                                                        aria-controls='contact'
                                                        aria-selected='false'
                                                    >
                                                        Streets
                                                    </p>
                                                </li>
                                            </ul>

                                            {isProfile && (
                                                <>
                                                    <DepartmentInfo /> <br />
                                                </>
                                            )}
                                            {isAddAdmin && <AdminList />}
                                            {isHome && (
                                                <>
                                                    <UploadImg />
                                                    <br />
                                                </>
                                            )}
                                            {isLoginToggles && (
                                                <>
                                                    <LandingTogglesControl />
                                                    <br />
                                                </>
                                            )}

                                            {isTerms && (
                                                <>
                                                    {rulesInDraft && (
                                                        <RulesEditor rulesInDraft={rulesInDraft} />
                                                    )}
                                                    <br />
                                                </>
                                            )}
                                            {isSubscription && (
                                                <>
                                                    <SubscriptionControl />
                                                    <br />
                                                </>
                                            )}
                                            {isStreets && (
                                                <>
                                                    <StreetsEditor />
                                                    <br />
                                                </>
                                            )}
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </IonCard>
                    </IonContent>
                </div>
            </IonSplitPane>
        </IonApp>
    );
}

export default Settings;
