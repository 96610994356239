import { IonItem, IonLabel, IonButton, IonTextarea, IonInput } from '@ionic/react';
import '../../theme/DepartmentInfo.css';
import PhoneInput from 'react-phone-input-2';
import { profileFormStore } from '../../store/Store';
import { updateProfile } from '../../firebaseConfig';

function DepartmentInfo() {
    const profileForm = profileFormStore.useState((s) => s.profileForm);

    const updateFormDoc = (value, field) => {
        profileFormStore.update((s) => {
            s.profileForm = { ...s.profileForm, [field]: value };
        });
    };

    const saveProfile = () => {
        updateProfile(profileForm, 'parrysound').then(() => {
            window.location.href = '/settings';
        });
    };
    return (
        <>
            {' '}
            <IonItem>
                <IonLabel>Department Name:</IonLabel>
                <IonInput
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'name');
                    }}
                    required
                    placeholder='Enter name'
                    value={profileForm.name}
                />
            </IonItem>
            <IonItem>
                <IonLabel>Department Email:</IonLabel>{' '}
                <IonInput
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'email');
                    }}
                    required
                    placeholder='Enter email'
                    value={profileForm.email}
                />
            </IonItem>
            <IonItem>
                <IonLabel>Department Address:</IonLabel>{' '}
                <IonInput
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'address');
                    }}
                    required
                    placeholder='Enter address'
                    value={profileForm.address}
                />
            </IonItem>
            <IonItem>
                <IonLabel>Department ByLaw Name:</IonLabel>{' '}
                <IonInput
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'byLawName');
                    }}
                    required
                    placeholder='Enter name of byLaw'
                    value={profileForm.byLawName}
                />
            </IonItem>
            <IonItem>
                <IonLabel>Department ByLaw URL:</IonLabel>{' '}
                <IonInput
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'byLaw');
                    }}
                    required
                    placeholder='Enter Law URL'
                    value={profileForm.byLaw}
                />
            </IonItem>
            <IonItem>
                <IonLabel>Department Phone:</IonLabel>{' '}
                <PhoneInput
                    id='phone-input'
                    country='ca'
                    value={profileForm.phone}
                    onChange={(e) => {
                        updateFormDoc(e, 'phone');
                    }}
                />
            </IonItem>
            <IonItem>
                <IonLabel>Blurb:</IonLabel>{' '}
            </IonItem>
            <br />
            <div className='blurb-input'>
                <IonTextarea
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'blurb');
                    }}
                    placeholder='Enter more information here...'
                    value={profileForm.blurb}
                ></IonTextarea>
            </div>
            <br />
            <IonButton onClick={saveProfile}>Save</IonButton>
        </>
    );
}

export default DepartmentInfo;
