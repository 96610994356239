import {
    IonItem,
    IonLabel,
    IonButton,
    IonTextarea,
    IonInput,
} from '@ionic/react';
import '../../theme/AnnouncementForm.css';
import { toggleStore, appStore, announcementFormStore } from '../../store/Store';
import { sendEmergencyToUserArr } from '../../firebaseConfig';

function EmergencyWidgetControl() {
    const emptyAnnouncementTitle = toggleStore.useState((s) => s.emptyAnnouncementTitle);
    const emptyAnnouncementDetails = toggleStore.useState((s) => s.emptyAnnouncementDetails);
    const userInfo = appStore.useState((s) => s.userInfo);

    const announcementTitle = announcementFormStore.useState((s) => s.announcementTitle);
    const announcementDetails = announcementFormStore.useState((s) => s.announcementDetails);
    const updateFormDoc = (value, field) => {
        if (field === 'title') {
            announcementFormStore.update((s) => {
                s.announcementTitle = value;
            });
        } else if (field === 'details') {
            announcementFormStore.update((s) => {
                s.announcementDetails = value;
            });
        }
    };

    const submitAnnouncementForm = () => {
        sendEmergencyToUserArr(userInfo, announcementTitle, announcementDetails);
    };

    return (
        <>
            <IonItem>
                <IonLabel>Title:</IonLabel>
                <IonInput
                    className='left-side-input'
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'title');
                    }}
                    required
                    placeholder='Enter title'
                />
            </IonItem>
            <p className={emptyAnnouncementTitle ? 'error-msg' : 'hide-error-msg'}>
                Please enter title{' '}
            </p>
            <br />
            <div>
                <IonTextarea
                    className='blurb-input'
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'details');
                    }}
                    placeholder='Enter more information here...'
                ></IonTextarea>
            </div>
            <p className={emptyAnnouncementDetails ? 'error-msg' : 'hide-error-msg'}>
                Please enter announcement{' '}
            </p>
            <br />

            <IonButton className='ion-padding' onClick={submitAnnouncementForm}>
                Send
            </IonButton>

            <br />
        </>
    );
}

export default EmergencyWidgetControl;
