import { IonButton, IonRow, IonCol, IonGrid } from '@ionic/react';
import '../../theme/WidgetLanding.css';
import RiskWidget from './RiskWidget';
import WeatherWidget from './WeatherWidget';
import GeneralEmergency from './GeneralEmergency';

function WidgetLanding() {
    const directToWidgetLogin = () => {
        window.location.href = '/widget/login';
    };
    return (
        <>
            <br />
            <div className='widgets-login'>
                <IonButton color='success' shape='round' onClick={directToWidgetLogin}>
                    Log in / Register
                </IonButton>
            </div>
            <br /> <br />
            <h2>Widget Examples</h2>
            <br />
            <IonGrid className='widget-examples-grid'>
                <IonRow>
                    <IonCol className='widget-example' size='6'>
                        <h4>Risk Level Widget:</h4>
                        <RiskWidget />
                        <br />
                    </IonCol>
                    <IonCol className='widget-example' size='6'>
                        <h4>Weather Widget:</h4>
                        <WeatherWidget />
                        <br />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol className='widget-example' size='12'>
                        <h4>Emergency Notification Widget:</h4>
                        <GeneralEmergency />
                        <br />
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
}

export default WidgetLanding;
