import { IonInput, IonButton } from '@ionic/react';
import '../../../theme/Home.css';
import { modalToggleStore, userFormStore } from '../../../store/Store';
const Profile1 = () => {
    const firstNameEmpty = modalToggleStore.useState((s) => s.firstNameEmpty);
    const detailUserForm = userFormStore.useState((s) => s.detailUserForm);
    const updateFormDoc = (value, field) => {
        if (value && field === 'first') {
            modalToggleStore.update((s) => {
                s.firstNameEmpty = false;
            });
            userFormStore.update((s) => {
                s.detailUserForm = { ...s.detailUserForm, first: value };
            });
        } else if (!value && field === 'first') {
            modalToggleStore.update((s) => {
                s.firstNameEmpty = true;
            });
        }
    };
    const openSecondPage = () => {
        modalToggleStore.update((s) => {
            s.profilePage = 2;
        });
    };
    return (
        <>
            <h2 className='ion-padding'>What is your first name?</h2>
            <br />
            <IonInput
                className='ion-padding-horizontal'
                value={detailUserForm.first}
                placeholder='Enter first name'
                onIonChange={(e) => {
                    updateFormDoc(e.target.value, 'first');
                }}
            />
            <br /> <br />
            <IonButton
                className='ion-padding-horizontal'
                disabled={firstNameEmpty}
                shape='round'
                expand='block'
                color='tertiary'
                onClick={openSecondPage}
            >
                Continue
            </IonButton>
        </>
    );
};

export default Profile1;
