// importing components for react-google-map
import { LoadScript } from '@react-google-maps/api';
import {
    IonLabel,
    IonRadioGroup,
    IonRadio,
    IonButton,
    IonRow,
    IonCol,
    IonInput,
    IonItem,
    IonImg,
} from '@ionic/react';
import axios from 'axios';
import '../../../theme/Home.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import poweredByGoogle from '../../../images/powered_by_google.png';
import {
    modalToggleStore,
    toggleStore,
    appStore,
    profileFormStore,
    permitsStore,
    userFormStore,
    addressFormStore,
    coordsStore,
} from '../../../store/Store';
import { useStoreState } from 'pullstate';
const ApplyPermit4 = () => {
    const polygonCoords = coordsStore.useState((s) => s.polygonCoords);
    const circleCoords = coordsStore.useState((s) => s.circleCoords);
    const rectangleCoords = coordsStore.useState((s) => s.rectangleCoords);
    const addressForm = addressFormStore.useState((s) => s.addressForm);
    const detailUserForm = userFormStore.useState((s) => s.detailUserForm);
    const addressEmpty = modalToggleStore.useState((s) => s.addressEmpty);
    const chooseAddressToggle = toggleStore.useState((s) => s.chooseAddressToggle);
    const userInfo = appStore.useState((s) => s.userInfo);
    const addressPass = appStore.useState((s) => s.addressPass);
    const manualAddress = toggleStore.useState((s) => s.manualAddress);
    const { mapsAPI, settingsForm } = useStoreState(profileFormStore);
    const editAddress = () => {
        toggleStore.update((s) => {
            s.manualAddress = true;
        });
    };
    const searchAddress = () => {
        toggleStore.update((s) => {
            s.manualAddress = false;
        });
    };
    const updateAddress = (value, field) => {
        addressFormStore.update((s) => {
            s.addressForm = { ...s.addressForm, [field]: value };
        });
        if (field === 'address' && value && addressForm.city && addressForm.province) {
            modalToggleStore.update((s) => {
                s.addressEmpty = true;
            });
        } else if (field === 'city' && value && addressForm.address && addressForm.province) {
            modalToggleStore.update((s) => {
                s.addressEmpty = true;
            });
        } else if (field === 'province' && value && addressForm.address && addressForm.city) {
            modalToggleStore.update((s) => {
                s.addressEmpty = true;
            });
        } else {
            modalToggleStore.update((s) => {
                s.addressEmpty = false;
            });
        }
    };

    const updateFormDoc = (value, field) => {
        if (field === 'smsSub') {
            userFormStore.update((s) => {
                s.detailUserForm = {
                    ...s.detailUserForm,
                    [field]: !detailUserForm.smsSub,
                };
            });
        } else if (field === 'emailSub') {
            userFormStore.update((s) => {
                s.detailUserForm = {
                    ...s.detailUserForm,
                    [field]: !detailUserForm.emailSub,
                };
            });
        } else {
            userFormStore.update((s) => {
                s.detailUserForm = { ...s.detailUserForm, [field]: value };
            });
        }
    };
    const handleChange = (e) => {
        modalToggleStore.update((s) => {
            s.addressEmpty = true;
        });

        updateFormDoc(e, 'address');
        const result = e.split(',');
        //clearing stuff after comma should also clear corresponding field in  manual address
        if (result[0] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: '' };
                s.addressForm = { ...s.addressForm, city: '' };
                s.addressForm = { ...s.addressForm, province: '' };
            });
        } else if (result[1] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: '' };
                s.addressForm = { ...s.addressForm, province: '' };
            });
        } else if (result[2] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, province: '' };
            });
        }

        if (result[0]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: `${result[0]}` };
            });
        }

        // if this line is not added you cant enter comma
        if (result[0] && result[1] !== undefined) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: `${result[0]},` };
            });
        }
        if (result[1]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: `${result[1]}` };
            });
        }
        // if this line is not added you cant enter comma
        if (result[1] && result[2] !== undefined) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: `${result[1]},` };
            });
        }
        if (result[2]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, province: `${result[2]}` };
            });
        }
    };
    const openFifthPage = () => {
        modalToggleStore.update((s) => {
            s.permitPage = 5;
        });
    };

    const updateChooseAddress = (value) => {
        toggleStore.update((s) => {
            s.chooseAddressToggle = value;
        });
        if (value === 'manual') {
            modalToggleStore.update((s) => {
                s.addressEmpty = false;
            });
        } else if (value === 'auto') {
            modalToggleStore.update((s) => {
                s.addressEmpty = true;
            });
        }
    };

    const validateAddress = () => {
        let formAddress;
        let formCity;
        let formProvince;
        let address;

        if (chooseAddressToggle === 'manual') {
            if (addressForm.address[addressForm.address.length - 1] !== ',') {
                formAddress = `${addressForm.address}, `;
            } else {
                formAddress = addressForm.address;
            }

            if (addressForm.city[addressForm.city.length - 1] !== ',') {
                formCity = `${addressForm.city}, `;
            } else {
                formCity = addressForm.city;
            }
            formProvince = addressForm.province;

            address = `${formAddress}${formCity}${formProvince}`;
        } else {
            address = userInfo.address;
        }
        permitsStore.update((s) => {
            s.currentPermitAddress = address;
        });

        axios
            .get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: address,
                    key: mapsAPI,
                },
            })
            .then((response) => {
                let pass = true;
                const checkLat = response.data.results[0].geometry.location.lat;
                const checkLng = response.data.results[0].geometry.location.lng;
                coordsStore.update((s) => {
                    s.currentBurnCoord = {
                        lat: response.data.results[0].geometry.location.lat,
                        lng: response.data.results[0].geometry.location.lng,
                    };
                });
                // check if the input address coord falls into the circle areas
                circleCoords.forEach((coord) => {
                    const circleRaidusInDegree = (coord.radius / 1.11) * 0.00001;
                    const circleX = coord.coords.lng;
                    const circleY = coord.coords.lat;
                    const xDifference = Math.abs(checkLng - circleX);
                    const yDifference = Math.abs(checkLat - circleY);
                    const pointRaidus = Math.sqrt(
                        xDifference * xDifference + yDifference * yDifference
                    );

                    if (circleRaidusInDegree > pointRaidus) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                        pass = false;
                    }
                });
                // check if the input address coord falls into the rectangle areas
                rectangleCoords.forEach((coord) => {
                    if (
                        coord.coords[2].lat > checkLat &&
                        coord.coords[0].lat < checkLat &&
                        coord.coords[2].lng > checkLng &&
                        coord.coords[0].lng < checkLng
                    ) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                        pass = false;
                    }
                });
                // check if the input address coord falls into the polygon areas
                polygonCoords.forEach((coord) => {
                    let count = 0;
                    //check last polyline
                    if (
                        (coord.coords[0].lat > checkLat &&
                            coord.coords[coord.coords.length - 1].lat < checkLat) ||
                        (coord.coords[0].lat < checkLat &&
                            coord.coords[coord.coords.length - 1].lat > checkLat)
                    ) {
                        const slope =
                            (coord.coords[coord.coords.length - 1].lat - coord.coords[0].lat) /
                            (coord.coords[coord.coords.length - 1].lng - coord.coords[0].lng);
                        const intercept =
                            coord.coords[coord.coords.length - 1].lat -
                            slope * coord.coords[coord.coords.length - 1].lng;
                        const cross = (checkLat - intercept) / slope;
                        if (cross > checkLng) {
                            count = count + 1;
                        }
                    }
                    for (let i = 0; i < coord.coords.length - 1; i++) {
                        if (
                            (coord.coords[i].lat > checkLat &&
                                coord.coords[i + 1].lat < checkLat) ||
                            (coord.coords[i].lat < checkLat && coord.coords[i + 1].lat > checkLat)
                        ) {
                            const slope =
                                (coord.coords[i + 1].lat - coord.coords[i].lat) /
                                (coord.coords[i + 1].lng - coord.coords[i].lng);
                            const intercept =
                                coord.coords[i + 1].lat - slope * coord.coords[i + 1].lng;
                            const cross = (checkLat - intercept) / slope;
                            if (cross > checkLng) {
                                count = count + 1;
                            }
                        }
                    }

                    if (count % 2 === 1) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                        pass = false;
                    }
                });

                return pass;
            })
            .then((response) => {
                if (response) {
                    appStore.update((s) => {
                        s.addressPass = true;
                    });
                    openFifthPage();
                }
            });
    };
    return (
        <>
            <h2 className='ion-padding'>Choose Address</h2>
            <br />
            <IonRadioGroup
                className='permit-type-item'
                value={chooseAddressToggle}
                onIonChange={(e) => updateChooseAddress(e.detail.value)}
            >
                <IonItem>
                    <IonLabel className='ion-text-wrap'>{userInfo.address}</IonLabel>
                    <IonRadio color='tertiary' slot='start' value='auto' />
                </IonItem>
                <IonItem>
                    <IonLabel className='ion-text-wrap'>Use another address</IonLabel>
                    <IonRadio color='tertiary' slot='start' value='manual' />
                </IonItem>
            </IonRadioGroup>
            {!addressPass && (
                <p className={!addressPass ? 'error-msg' : 'hide-error-msg'}>
                    The address you entered falls in the restricted area{' '}
                </p>
            )}
            <br />
            {chooseAddressToggle === 'manual' && !manualAddress && (
                <>
                    <LoadScript
                        id='script-loader'
                        googleMapsApiKey={`${mapsAPI}&libraries=drawing,places`}
                        language='en'
                        region='us'
                    >
                        <div className='modal-address-search'>
                            <GooglePlacesAutocomplete
                                selectProps={{
                                    placeholder: 'Search Address...',
                                    onChange: (e) => {
                                        handleChange(e.label);
                                    },
                                }}
                                autocompletionRequest={{
                                    bounds: [
                                        {
                                            lat: settingsForm.boundCoord.s,
                                            lng: settingsForm.boundCoord.w,
                                        },
                                        {
                                            lat: settingsForm.boundCoord.n,
                                            lng: settingsForm.boundCoord.e,
                                        },
                                    ],
                                    componentRestrictions: {
                                        country: ['ca'],
                                    },
                                    strictBounds: true,
                                }}
                            />
                            <IonImg id='google-logo' src={poweredByGoogle} />
                        </div>
                    </LoadScript>
                    <br />
                    <p className='ion-padding-horizontal'>
                        {' '}
                        Use the search box above by entering the address you're looking for. When
                        you see the correct address, please click/tap on it to set it.
                    </p>
                    <span className='ion-padding-horizontal'>Don't see your address listed?</span>
                    <p className='manual-address-btn' onClick={editAddress}>
                        manually enter address
                    </p>
                </>
            )}
            {manualAddress && chooseAddressToggle === 'manual' && (
                <>
                    <div className='manual-address-form'>
                        <IonRow>
                            <IonCol size='10'>
                                <IonItem>
                                    <IonLabel>Address:</IonLabel>{' '}
                                    <IonInput
                                        value={addressForm.address}
                                        onIonChange={(e) => {
                                            updateAddress(e.target.value, 'address');
                                        }}
                                        required
                                        placeholder='Enter Address'
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='10'>
                                <IonItem>
                                    <IonLabel>City:</IonLabel>{' '}
                                    <IonInput
                                        value={addressForm.city}
                                        onIonChange={(e) => {
                                            updateAddress(e.target.value, 'city');
                                        }}
                                        required
                                        placeholder='Enter City'
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='10'>
                                <IonItem>
                                    <IonLabel>Province:</IonLabel>{' '}
                                    <IonInput
                                        value={addressForm.province}
                                        onIonChange={(e) => {
                                            updateAddress(e.target.value, 'province');
                                        }}
                                        required
                                        placeholder='Enter Province'
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </div>
                    <div className='manual-address'>
                        <p className='manual-address-btn' onClick={searchAddress}>
                            search address
                        </p>
                    </div>
                </>
            )}
            <IonButton
                shape='round'
                expand='block'
                className='ion-padding-horizontal'
                color='tertiary'
                disabled={!addressEmpty}
                onClick={validateAddress}
            >
                Continue
            </IonButton>
        </>
    );
};

export default ApplyPermit4;
