import { IonText, IonButton, IonImg } from '@ionic/react';
import '../../theme/UploadImg.css';
import { fileStore } from '../../store/Store';
import { uploadHomeLogo, uploadHomeImage } from '../../firebaseConfig';

function UploadImg() {
    const homeImgFile = fileStore.useState((s) => s.homeImgFile);
    const homeLogoImg = fileStore.useState((s) => s.homeLogoImg);
    const homeImg = fileStore.useState((s) => s.homeImg);
    const homeLogoFile = fileStore.useState((s) => s.homeLogoFile);

    const updateImgForm = async (e) => {
        e.preventDefault();
        if (homeLogoFile) {
            await uploadHomeLogo(homeLogoFile, 'parrysound');
        }
        if (homeImgFile) {
            await uploadHomeImage(homeImgFile, 'parrysound');
        }

        window.location.href = '/settings';
    };
    const changeHomeImgFile = (e) => {
        fileStore.update((s) => {
            s.homeImgFile = e.target.files[0];
        });
    };
    const changeHomeLogoFile = (e) => {
        fileStore.update((s) => {
            s.homeLogoFile = e.target.files[0];
        });
    };
    return (
        <>
            <IonText>
                <h5>Home Image</h5>
            </IonText>
            {homeImg && <IonImg className='home-img' src={homeImg.imageURL} />}

            <input required type='file' onChange={changeHomeImgFile}></input>

            <IonText>
                <h5>Home Logo</h5>
            </IonText>
            {homeLogoImg && <IonImg className='home-img' src={homeLogoImg.imageURL} />}

            <input required type='file' onChange={changeHomeLogoFile}></input>
            <IonButton onClick={updateImgForm} type='submit'>
                Save
            </IonButton>
        </>
    );
}

export default UploadImg;
