import { announcementStore } from '../../store/Store';
import '../../theme/AnnouncementWidget.css';
import {
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
    IonItem,
    IonLabel,
    IonText,
} from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
function AnnouncementWidget() {
    const announcements = announcementStore.useState((s) => s.announcements);

    const clickAnnouncementLink = (link) => {
        window.location.href = `${link}`;
    };
    if (announcements.length > 0) {
        return (
            <div className='announ-div'>
                <IonCard mode='ios' className='announ-card'>
                    <IonCardHeader>
                        <IonCardSubtitle>Latest</IonCardSubtitle>
                        <IonCardTitle>Announcements</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {announcements.map((announcement) => {
                            if (announcement.link && announcement.sendToLanding) {
                                return (
                                    <IonItem
                                        key={announcement.id}
                                        onClick={() => {
                                            clickAnnouncementLink(announcement.link);
                                        }}
                                        className='announ-item-link'
                                    >
                                        <IonIcon
                                            slot='end'
                                            style={{ color: '#0084ff' }}
                                            icon={chevronForward}
                                        />
                                        <IonLabel>
                                            <div> {announcement.title} </div>
                                            <br />
                                            <span className='announcement-date'>
                                                {announcement.date_created}
                                            </span>{' '}
                                            - <IonText>{announcement.details}</IonText>
                                        </IonLabel>
                                    </IonItem>
                                );
                            } else if (!announcement.link && announcement.sendToLanding) {
                                return (
                                    <IonItem key={announcement.id} className='announ-item'>
                                        <IonLabel>
                                            <div> {announcement.title} </div>
                                            <br />
                                            <span className='announcement-date'>
                                                {announcement.date_created}
                                            </span>{' '}
                                            - <IonText>{announcement.details}</IonText>
                                        </IonLabel>
                                    </IonItem>
                                );
                            }
                        })}
                    </IonCardContent>
                </IonCard>
            </div>
        );
    } else {
        return null;
    }
}

export default AnnouncementWidget;
