import { IonButton, IonItem, IonLabel, IonCheckbox } from '@ionic/react';
import '../../../theme/Home.css';
import PhoneInput from 'react-phone-input-2';
import { appStore, userFormStore } from '../../../store/Store';
import { saveNoPwUserInfo } from '../../../firebaseConfig';
const NoPwProfile1 = () => {
    const noPwUserForm = userFormStore.useState((s) => s.noPwUserForm);
    const noPwUserId = appStore.useState((s) => s.noPwUserId);
    const updateFormDoc = (value, field) => {
        userFormStore.update((s) => {
            s.noPwUserForm = { ...s.noPwUserForm, [field]: value };
        });
    };
    const save = async () => {
        await saveNoPwUserInfo(noPwUserId, noPwUserForm);
        alert('Sign up Successfully! You can close the window now.');
    };
    return (
        <>
            <h4 className='ion-padding'>What is your phone number?</h4>
            <p className='ion-padding-horizontal'>
                (optional, if empty, you’ll only receive email notifications)
            </p>
            <br />
            <div className='ion-padding-horizontal'>
                <PhoneInput
                    value={noPwUserForm.phone}
                    id='phone-input'
                    onChange={(e) => {
                        updateFormDoc(e, 'phone');
                    }}
                    country='ca'
                />
            </div>
            <br />
            <h5 className='ion-padding-horizontal'>How will we use your phone number?</h5>
            <p className='ion-padding-horizontal'>
                Communications will come from Parry Sound Fire and Rescue Department and will be
                restricted to notifications of burn bans and other important notices. Your phone
                number will not be used for promotional or marketing purposes or shared with third
                parties.
            </p>
            <IonItem>
                <IonLabel>Check to receive notifications from Parry Sound Fire Department</IonLabel>
                <IonCheckbox
                    slot='start'
                    color='tertiary'
                    value={'123'}
                    checked={noPwUserForm.emergencySub}
                    onIonChange={(e) => {
                        updateFormDoc(!noPwUserForm.emergencySub, 'emergencySub');
                    }}
                />
            </IonItem>
            <br />
            <IonButton
                className='ion-padding-horizontal'
                shape='round'
                expand='block'
                color='tertiary'
                onClick={save}
            >
                Submit
            </IonButton>
        </>
    );
};

export default NoPwProfile1;
