import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonLoading, setupIonicReact } from '@ionic/react';
import { useEffect } from 'react';
import { IonReactRouter } from '@ionic/react-router';
import AdminDashboard from './pages/admin/AdminDashboard';
import WidgetLandingPage from './pages/common/WidgetLandingPage';
import GeneralEmergency from './components/common/GeneralEmergency';
import GeneralWeather from './components/common/GeneralWeather';
import GeneralRisk from './components/common/GeneralRisk';
import LandingPage from './pages/common/LandingPage';
import LandingPageForNoPw from './pages/common/LandingPageForNoPw';
import AdminPermitTypes from './pages/admin/AdminPermitTypes';
import AllPermits from './pages/admin/AllPermits';
import AdminPermitForm from './pages/admin/AdminPermitForm';
import WidgetLogin from './pages/common/WidgetLogin';
import WidgetControl from './pages/common/WidgetControl';
import EditUsername from './pages/common/EditUsername';
import WidgetPreview from './pages/common/WidgetPreview';
import Login from './pages/common/Login';
import PDFDoc from './components/common/PDFDoc';
import { useAuthState } from 'react-firebase-hooks/auth';
// import state
import {
    rulesStore,
    appStore,
    profileFormStore,
    permitsStore,
    announcementStore,
    fileStore,
    toggleStore,
    coordsStore,
} from './store/Store';
import {
    fetchProfile,
    fetchPermits,
    fetchPermitTypes,
    fetchAllAnnouncements,
    fetchRulesInDraft,
    fetchUserPermits,
    fetchUser,
    fetchAllImages,
    fetchPolygonCoords,
    fetchCircleCoords,
    fetchRectangleCoords,
} from './firebaseConfig';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Exclusion from './pages/admin/Exclusion';
import Announcement from './pages/admin/Announcement';
import MyPermits from './pages/user/MyPermits';
import Profile from './pages/user/Profile';
import Settings from './pages/admin/Settings';
import SignUpForm from './pages/user/SignUpForm';
import { getAuth } from 'firebase/auth';
setupIonicReact({
    mode: 'md',
});

function App() {
    const auth = getAuth();
    const [user, userLoading] = useAuthState(auth);
    const loading = appStore.useState((s) => s.loading);
    const adminId = appStore.useState((s) => s.adminId);
    const userId = appStore.useState((s) => s.userId);
    const noPwUserId = appStore.useState((s) => s.noPwUserId);
    const superAdminId = appStore.useState((s) => s.superAdminId);
    const widgetUser = appStore.useState((s) => s.widgetUser);
    useEffect(() => {
        //fetch city data
        fetchProfile('parrysound').then((data) => {
            if (data) {
                profileFormStore.update((s) => {
                    s.settingsForm = data;
                    s.mapsAPI = data.mapsAPI;
                });

                //only for admin settings
                const tempForm = {
                    name: data.name,
                    email: data.email,
                    address: data.address,
                    blurb: data.blurb,
                    byLaw: data.byLaw,
                    phone: data.phone,
                    byLawName: data.byLawName,
                };
                profileFormStore.update((s) => {
                    s.profileForm = tempForm;
                    s.riskLevel = data.riskLevel;
                });
            } else {
            }
        });

        //fetch all permits
        fetchPermits().then((data) => {
            permitsStore.update((s) => {
                s.permits = data;
            });
        });
        //getting permit types to use in ionselect type
        fetchPermitTypes().then((data) => {
            permitsStore.update((s) => {
                s.permitTypes = data;
            });
        });
        //fetch all announcements
        fetchAllAnnouncements().then((data) => {
            if (data.length > 0) {
                announcementStore.update((s) => {
                    s.announcements = data;
                });
            }
        });
        //fetch exclusion shapes

        fetchPolygonCoords().then((data) => {
            // after fetching data from firestore, transform it into the correct form so that the polygon component can use
            let final1Data = [];
            if (data.length > 0) {
                data.forEach((coord) => {
                    let finalData = [];
                    let transformedData = null;

                    transformedData = coord.coords
                        .toString()
                        .replace(/[{()}]/g, '')
                        .replace(/ /g, '')
                        .split(',')
                        .map((coord) => parseFloat(coord));
                    for (let i = 0; i < transformedData.length; i = i + 2) {
                        finalData.push({
                            lng: transformedData[i + 1],
                            lat: transformedData[i],
                        });
                    }
                    final1Data.push({
                        coords: finalData,
                        id: coord.id,
                        info: coord.info,
                    });
                });
                coordsStore.update((s) => {
                    s.polygonCoords = final1Data;
                });
            }
            return;
        });

        fetchCircleCoords().then((data) => {
            coordsStore.update((s) => {
                s.circleCoords = data;
            });
        });

        fetchRectangleCoords().then((data) => {
            coordsStore.update((s) => {
                s.rectangleCoords = data;
            });
        });

        //fetch term and regulations
        fetchRulesInDraft().then((data) => {
            rulesStore.update((s) => {
                s.rulesInDraft = data;
            });
        });
        //fetch imgs
        fetchAllImages().then((data) => {
            fileStore.update((s) => {
                s.allImg = data;
            });
            data.forEach((img) => {
                if (img.name === 'homeImage') {
                    fileStore.update((s) => {
                        s.homeImg = img;
                    });
                }
                if (img.name === 'homeLogo') {
                    fileStore.update((s) => {
                        s.homeLogoImg = img;
                    });
                }
            });
        });
    });
    // fetch user, and check if its passwordless user
    useEffect(() => {
        if (user) {
            const signInMethods = window.localStorage.getItem('signInMethods');
            fetchUser(user.uid).then((data) => {
                // if !data, means backend still creating user doc
                if (!data) {
                    appStore.update((s) => {
                        s.loading = true;
                    });
                } else if (data.superAdmin) {
                    appStore.update((s) => {
                        s.superAdminId = data.id;
                    });
                } else if (data.admin) {
                    appStore.update((s) => {
                        s.adminId = data.id;
                    });
                } else if (signInMethods && signInMethods === 'emailLink') {
                    appStore.update((s) => {
                        s.noPwUserId = user.uid;
                    });
                    appStore.update((s) => {
                        s.loading = false;
                    });
                } else {
                    //only verified user can access the app
                    if (user.emailVerified) {
                        toggleStore.update((s) => {
                            s.emailVerified = true;
                        });
                    } else {
                        toggleStore.update((s) => {
                            s.emailVerified = false;
                        });
                    }
                    appStore.update((s) => {
                        s.userId = data.id;
                    });
                }

                if (data) {
                    if (data.widgetUser) {
                        appStore.update((s) => {
                            s.widgetUser = true;
                        });
                    }
                    appStore.update((s) => {
                        s.userInfo = data;
                        s.loading = false;
                    });
                }
            });

            // });
        }
    });

    if (!userLoading && !user) {
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route render={() => <Redirect to='/' />} />
                        <Route exact path='/login'>
                            <Login />
                        </Route>
                        <Route exact path='/'>
                            <LandingPage />
                        </Route>
                        <Route exact path='/noPwSignIn/:city'>
                            <LandingPageForNoPw />
                        </Route>
                        <Route exact path='/widget'>
                            <WidgetLandingPage />
                        </Route>
                        <Route exact path='/emergency-sign-up/:city'>
                            <GeneralEmergency />
                        </Route>
                        <Route exact path='/risk-level/:city'>
                            <GeneralRisk />
                        </Route>
                        <Route exact path='/weather/:city'>
                            <GeneralWeather />
                        </Route>
                        <Route exact path='/widget/login'>
                            <WidgetLogin />
                        </Route>
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }

    if (loading) {
        return <IonLoading isOpen={true}></IonLoading>;
    }
    if (superAdminId && !loading) {
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact path='/admin'>
                            <AdminDashboard />
                        </Route>
                        <Route exact path='/permits'>
                            <AllPermits />
                        </Route>
                        <Route exact path='/permit-form'>
                            <AdminPermitForm />
                        </Route>
                        <Route exact path='/permit-types'>
                            <AdminPermitTypes />
                        </Route>
                        <Route exact path='/exclusion'>
                            <Exclusion />
                        </Route>
                        <Route exact path='/permit/:id'>
                            <PDFDoc />
                        </Route>
                        <Route exact path='/settings'>
                            <Settings />
                        </Route>
                        <Route exact path='/announcement'>
                            <Announcement />
                        </Route>
                        <Route exact path='/noPwSignIn/:city'>
                            <LandingPageForNoPw />
                        </Route>
                        <Route exact path='/emergency-sign-up/:city'>
                            <GeneralEmergency />
                        </Route>
                        <Route exact path='/risk-level/:city'>
                            <GeneralRisk />
                        </Route>
                        <Route exact path='/weather/:city'>
                            <GeneralWeather />
                        </Route>
                        <Route render={() => <Redirect to='/admin' />} />
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }
    if (adminId && !loading) {
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact path='/emergency-sign-up/:city'>
                            <GeneralEmergency />
                        </Route>
                        <Route exact path='/risk-level/:city'>
                            <GeneralRisk />
                        </Route>
                        <Route exact path='/weather/:city'>
                            <GeneralWeather />
                        </Route>
                        <Route exact path='/admin'>
                            <AdminDashboard />
                        </Route>
                        <Route exact path='/permit/:id'>
                            <PDFDoc />
                        </Route>
                        <Route exact path='/permits'>
                            <AllPermits />
                        </Route>
                        <Route exact path='/noPwSignIn/:city'>
                            <LandingPageForNoPw />
                        </Route>
                        <Route render={() => <Redirect to='/admin' />} />
                        <Route exact path='/emergency-sign-up/:city'>
                            <GeneralEmergency />
                        </Route>

                        <Redirect from='/login' to='/admin' exact />
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }

    if (noPwUserId && !loading) {
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact path='/register'>
                            <SignUpForm />
                        </Route>
                        <Route render={() => <Redirect to='/register' />} />
                        <Redirect from='/login' to='/register' exact />
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }

    if (userId && !loading && !widgetUser) {
        fetchUserPermits(user.email).then((data) => {
            permitsStore.update((s) => {
                s.currentUserPermits = data;
            });
        });
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact path='/'>
                            <LandingPage />
                        </Route>
                        <Route exact path='/noPwSignIn/:city'>
                            <LandingPageForNoPw />
                        </Route>
                        <Route exact path='/emergency-sign-up/:city'>
                            <GeneralEmergency />
                        </Route>
                        <Route exact path='/risk-level/:city'>
                            <GeneralRisk />
                        </Route>
                        <Route exact path='/weather/:city'>
                            <GeneralWeather />
                        </Route>
                        <Route exact path='/profile'>
                            <Profile />
                        </Route>
                        <Route exact path='/my-permits'>
                            <MyPermits />
                        </Route>
                        <Route exact path='/permit/:id'>
                            <PDFDoc />
                        </Route>

                        <Route exact path='/widget/login'>
                            <WidgetLogin />
                        </Route>

                        <Redirect from-='/login' to='/' exact />
                        <Route render={() => <Redirect to='/' />} />
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }
    if (userId && !loading && widgetUser) {
        fetchUserPermits(user.email).then((data) => {
            permitsStore.update((s) => {
                s.currentUserPermits = data;
            });
        });
        return (
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact path='/noPwSignIn/:city'>
                            <LandingPageForNoPw />
                        </Route>
                        <Route exact path='/emergency-sign-up/:city'>
                            <GeneralEmergency />
                        </Route>
                        <Route exact path='/risk-level/:city'>
                            <GeneralRisk />
                        </Route>
                        <Route exact path='/weather/:city'>
                            <GeneralWeather />
                        </Route>
                        <Route exact path='/widget/control'>
                            <WidgetControl />
                        </Route>
                        <Route exact path='/widget/login'>
                            <WidgetLogin />
                        </Route>
                        <Route exact path='/widget/preview'>
                            <WidgetPreview />
                        </Route>
                        <Route exact path='/widget/edit'>
                            <EditUsername />
                        </Route>

                        <Redirect from-='/' to='/widget/control' exact />
                        <Route render={() => <Redirect to='/widget/control' />} />
                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        );
    }
}

export default App;
