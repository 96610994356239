import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonImg,
    IonLoading,
    IonItem,
    IonLabel,
    IonInput,
} from '@ionic/react';
import { useEffect } from 'react';
import { menu } from 'ionicons/icons';
import '../../theme/Settings.css';
import 'bootstrap/dist/css/bootstrap.css';
import WidgetMenu from '../../components/widget/WidgetMenu';
import {
    settingsToggleStore,
    widgetControlStore,
    profileFormStore,
    rulesStore,
    toggleStore,
    appStore,
} from '../../store/Store';
import { fetchProfile, updateUserName } from '../../firebaseConfig';
import logo from '../../images/firepermitonlinelogo.png';
function EditUsername() {
    const username = widgetControlStore.useState((s) => s.username);
    const userInfo = appStore.useState((s) => s.userInfo);
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    const usernameNotVerify = toggleStore.useState((s) => s.usernameNotVerify);
    const usernameNotUnique = toggleStore.useState((s) => s.usernameNotUnique);
    useEffect(() => {
        fetchProfile('parrysound').then((data) => {
            if (data) {
                profileFormStore.update((s) => {
                    s.settingsForm = data;
                });
                profileFormStore.update((s) => {});
            }
        });
    }, []);

    const clickWeather = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = false;
            s.isEmergency = false;
            s.isWeather = true;
        });
    };

    const clickRisk = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = true;
            s.isEmergency = false;
            s.isWeather = false;
        });
    };

    const validateUsername = () => {
        toggleStore.update((s) => {
            s.usernameNotUnique = false;
        });
        if (!username) {
            toggleStore.update((s) => {
                s.usernameNotVerify = true;
            });
        } else if (!/[^a-zA-Z]/.test(username)) {
            toggleStore.update((s) => {
                s.usernameNotVerify = false;
            });

            let newUsername = username.toLowerCase();
            updateUserName(userInfo.id, newUsername).then((res) => {
                if (!res) {
                    toggleStore.update((s) => {
                        s.usernameNotUnique = true;
                    });
                }
            });
        } else {
            toggleStore.update((s) => {
                s.usernameNotVerify = true;
            });
        }
    };

    const clickEmergency = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = false;
            s.isEmergency = true;
            s.isWeather = false;
        });
    };

    const changeUserName = (e) => {
        widgetControlStore.update((s) => {
            s.username = e;
        });
    };

    return (
        <IonApp>
            <IonSplitPane when='lg' contentId='main-content'>
                <IonMenu contentId='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary-contrast'>
                            <IonImg src={logo} />
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <WidgetMenu />
                    </IonContent>
                </IonMenu>

                <div className='ion-page' id='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                            <IonTitle></IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <IonLoading
                            isOpen={loadingSpinner}
                            message={'Processing...'}
                            duration={50000}
                        />
                        <IonItem className='ion-padding'>
                            <IonLabel>Username:</IonLabel>
                            <IonInput
                                onIonChange={(e) => {
                                    changeUserName(e.target.value);
                                }}
                                required
                                placeholder='Enter a unique username'
                                // value={weatherAddress}
                            />

                            <IonButton
                                onClick={() => {
                                    validateUsername();
                                }}
                            >
                                Save
                            </IonButton>
                        </IonItem>

                        <p className={usernameNotVerify ? 'err' : 'hide'}>
                            &nbsp; &nbsp; &nbsp; Please only enter alphabets
                        </p>
                        <p className={usernameNotUnique ? 'err' : 'hide'}>
                            &nbsp; &nbsp; &nbsp; This username has already been taken, please try
                            again
                        </p>
                        <p className='ion-padding'>
                            &nbsp; Changing the username will change the iframe code as well, don't
                            forget to update your website.
                        </p>
                        <br />
                    </IonContent>
                </div>
            </IonSplitPane>
        </IonApp>
    );
}

export default EditUsername;
