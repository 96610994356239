import React, { useEffect, useState } from 'react';
import { IonLabel, IonButton, IonIcon, IonRadioGroup, IonItem, IonRadio } from '@ionic/react';
import { modalToggleStore, profileFormStore, toggleStore } from '../../../store/Store';
import { documentOutline } from 'ionicons/icons';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { Autocomplete, TextField } from '@mui/material';
import '../../../theme/Home.css';

const ApplyPermit1 = () => {
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);
    const agreeRules = toggleStore.useState((s) => s.agreeRules);
    const [streets, setStreets] = useState([]);
    const [selectedStreet, setSelectedStreet] = useState(null);

    const firestore = getFirestore();

    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(firestore, `municipalities/parrysound/streets`),
            (snapshot) => {
                const streetsList = snapshot.docs.map((doc) => doc.data().name || '');
                setStreets(streetsList.sort((a, b) => a.localeCompare(b)));
            }
        );

        return () => unsubscribe();
    }, [firestore]);

    const clickAgree = () => {
        toggleStore.update((s) => {
            s.agreeRules = true;
        });
    };

    const openSecondPage = () => {
        modalToggleStore.update((s) => {
            s.permitPage = 2;
        });
    };

    return (
        <>
            <h3 className='ion-padding terms-title'>
                PLEASE CONFIRM STREET AND AGREE TO BY-LAW TERMS AND CONDITIONS
            </h3>
            <div className='ion-padding-horizontal'>
                <Autocomplete
                    options={streets}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => setSelectedStreet(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Select Your Street'
                            variant='outlined'
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                sx: {
                                    '& .MuiAutocomplete-endAdornment': {
                                        top: '-10px', // Your custom top value
                                    },
                                },
                            }}
                        />
                    )}
                />
                <p className='streets-blurb'>
                    Don't see you street? Please confirm that your property is serviced by Parry
                    Sound Fire Department before proceeding.
                </p>
            </div>
            <p className='term-file'>
                <a href={settingsForm.byLaw} target='_blank' rel='noreferrer'>
                    <IonIcon icon={documentOutline} />
                    The Corporation of the Town of Parry Sound By-law 2021-7170
                </a>
            </p>

            <IonRadioGroup className='permit-type-item' onIonChange={clickAgree}>
                <IonItem>
                    <IonLabel className='ion-text-wrap'>
                        I have read, and I agree to abide by all of the terms and conditions set
                        forth in The Corporation of the Town of Parry Sound By-law 2021 - 7170.{' '}
                        <strong>
                            I also attest that my property is on the selected street and understand
                            that if I incorrectly apply with another address, my application fee may
                            not be returned
                        </strong>
                        .
                    </IonLabel>
                    <IonRadio color='tertiary' slot='start' value='agree' />
                </IonItem>
            </IonRadioGroup>
            <br />
            <IonButton
                className='ion-padding-horizontal'
                shape='round'
                expand='block'
                color='tertiary'
                disabled={!agreeRules || !selectedStreet}
                onClick={openSecondPage}
            >
                Continue
            </IonButton>
        </>
    );
};

export default ApplyPermit1;
