import { profileFormStore } from '../../store/Store';
import '../../theme/ContactWidget.css';
import { IonCardHeader, IonCardContent, IonText, IonGrid, IonRow, IonCol } from '@ionic/react';

function ContactWidget() {
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);

    return (
        <IonCardContent>
            <IonCardHeader className='contact-header-div'>
                <span className='contact-header'>Contact</span>
                <div> {settingsForm.blurb} </div>
            </IonCardHeader>
            <IonGrid>
                <IonRow>
                    <IonCol
                        className='contact-col-address'
                        size-xs='12'
                        size-lg='4'
                        style={{ textAlign: 'center' }}
                    >
                        <div style={{ textAlign: 'center' }}>
                            {/* <IonIcon
                                style={{ color: 'gray' }}
                                className='contact-icon'
                                icon={call}
                            />{' '}
                            &nbsp; &nbsp; */}
                            <IonText style={{ color: 'gray' }}>
                                <a href={`tel: ${settingsForm.phone}`}>{settingsForm.phone}</a>
                            </IonText>
                        </div>
                    </IonCol>

                    <IonCol className='contact-col-address' size-xs='12' size-lg='4'>
                        <div style={{ textAlign: 'center' }}>
                            {/* <IonIcon
                                style={{ color: 'gray' }}
                                className='contact-icon'
                                icon={home}
                            />{' '}
                            &nbsp; &nbsp; */}
                            <IonText> {settingsForm.address}</IonText>
                        </div>
                    </IonCol>
                    <IonCol className='contact-col-address' size-xs='12' size-lg='4'>
                        <div style={{ textAlign: 'center' }}>
                            <IonText style={{ fontSize: '0.9rem' }}>
                                Fire Prevention:
                                <br />
                                <a href={`mailto: ${settingsForm.email}`}>{settingsForm.fdEmail}</a>
                            </IonText>
                            <br />
                            <IonText style={{ fontSize: '0.9rem' }}>
                                Administration:
                                <br />
                                <a href={`mailto: ${settingsForm.email}`}>
                                    {settingsForm.adminEmail}
                                </a>
                            </IonText>
                            <br />
                            <IonText style={{ fontSize: '0.9rem' }}>
                                Technical Support:
                                <br />
                                <a href={`mailto: ${settingsForm.email}`}>{settingsForm.email}</a>
                            </IonText>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonCardContent>
    );
}

export default ContactWidget;
