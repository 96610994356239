import {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
} from '@ionic/react';
import dayjs from 'dayjs';

const EndOfYearWarning = () => {
    const currentYear = dayjs().year();
    const nextYear = currentYear + 1;
    return (
        <IonGrid>
            <IonRow>
                <IonCol>
                    <IonCard mode='ios' color='warning'>
                        <IonCardHeader>
                            <IonCardTitle>Please note</IonCardTitle>
                            <IonCardSubtitle>
                                Permits purchased today expire at the end of the month.
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <p>
                                Any permit purchased today will expire at the end of the current
                                calendar year (December 31st, {currentYear}). If you intend to
                                purchase a {nextYear} permit, please revisit this site after January
                                1st {nextYear}.
                            </p>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default EndOfYearWarning;
