import { initializeApp } from 'firebase/app';
import {
    getFirestore,
    collection,
    addDoc,
    doc,
    getDoc,
    getDocs,
    setDoc,
    deleteDoc,
    updateDoc,
    query,
    where,
    onSnapshot,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import * as dayjs from 'dayjs';
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    getAuth,
    sendSignInLinkToEmail,
    signOut,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    sendEmailVerification,
    isSignInWithEmailLink,
    signInWithEmailLink,
    fetchSignInMethodsForEmail,
    EmailAuthProvider,
} from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
    apiKey: 'AIzaSyDPSZLtYoMHrupVR8IZMcz6dNKyVY6rWNE',
    authDomain: 'firepermitonline.firebaseapp.com',
    projectId: 'firepermitonline',
    storageBucket: 'firepermitonline.appspot.com',
    messagingSenderId: '79911845221',
    appId: '1:79911845221:web:18bb84f55cc8cbbd65f58c',
    measurementId: 'G-W7C3BQYWEV',
};

// Initialize Firebase
initializeApp(firebaseConfig);
const storage = getStorage();
const functions = getFunctions(initializeApp(firebaseConfig));
const db = getFirestore();

const cityName = 'parrysound';
const municipality = 'Parry Sound';
const userRef = collection(db, 'users');
let domain;
if (window.location.hostname === 'localhost') {
    domain = 'http://localhost:8100';
} else {
    domain = 'https://parrysound.firepermit.online';
}
export async function addPolygonCoords(coords, infoForm) {
    const docRef = await addDoc(collection(db, 'municipalities', cityName, 'exclusions'), {
        coords,
        info: infoForm,
        shape: 'polygon',
    });

    await updateDoc(doc(db, 'municipalities', cityName, 'exclusions', docRef.id), {
        id: docRef.id,
    });
    return docRef.id;
}

export async function addCircleCoords(coords, radius, infoForm) {
    const docRef = await addDoc(collection(db, 'municipalities', cityName, 'exclusions'), {
        coords,
        radius,
        info: infoForm,
        shape: 'circle',
    });

    await updateDoc(doc(db, 'municipalities', cityName, 'exclusions', docRef.id), {
        id: docRef.id,
    });
    return;
}
export async function addRectangleCoords(coords, infoForm) {
    const docRef = await addDoc(collection(db, 'municipalities', cityName, 'exclusions'), {
        coords,
        info: infoForm,
        shape: 'rectangle',
    });
    await updateDoc(doc(db, 'municipalities', cityName, 'exclusions', docRef.id), {
        id: docRef.id,
    });
    return;
}

export async function fetchPolygonCoords() {
    const exclusionsRef = collection(db, 'municipalities', cityName, 'exclusions');
    const q = query(exclusionsRef, where('shape', '==', 'polygon'));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}

export async function fetchAllShapes() {
    const exclusionsRef = collection(db, 'municipalities', cityName, 'exclusions');
    const q = query(exclusionsRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}

export async function fetchCircleCoords() {
    const exclusionsRef = collection(db, 'municipalities', cityName, 'exclusions');
    const q = query(exclusionsRef, where('shape', '==', 'circle'));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}

export async function fetchRectangleCoords() {
    const exclusionsRef = collection(db, 'municipalities', cityName, 'exclusions');
    const q = query(exclusionsRef, where('shape', '==', 'rectangle'));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}

export async function fetchCurrentShapeData(id) {
    const docRef = doc(db, 'municipalities', cityName, `exclusions`, id);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
}

export async function deleteShapeData(id) {
    await deleteDoc(doc(db, 'municipalities', cityName, `exclusions`, id));
}

export async function updateShapeInfo(infoForm, id) {
    await updateDoc(doc(db, 'municipalities', cityName, `exclusions`, id), {
        info: infoForm,
    });
}

export async function loginWidgetUser(userForm) {
    const auth = getAuth();
    const userRef = collection(db, 'users');
    const dataArr = [];
    const data = {};
    const q = query(userRef, where('email', '==', userForm.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });

    if (dataArr.length === 0) {
        data.noUser = true;
    } else {
        try {
            const res = await signInWithEmailAndPassword(auth, userForm.email, userForm.password);
            data.user = res;
            window.location.href = '/widget/control';
        } catch (error) {
            data.error = error;
        }
    }

    return data;
}
export async function loginUser(userForm) {
    const auth = getAuth();
    const userRef = collection(db, 'users');
    const dataArr = [];
    const data = {};
    const q = query(userRef, where('email', '==', userForm.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });

    if (dataArr.length === 0) {
        data.noUser = true;
    } else if (dataArr[0].admin) {
        try {
            const res = await signInWithEmailAndPassword(auth, userForm.email, userForm.password);
            data.admin = res;
        } catch (error) {
            data.error = error;
        }
    } else if (dataArr[0].superAdmin) {
        try {
            const res = await signInWithEmailAndPassword(auth, userForm.email, userForm.password);
            data.superAdmin = res;
        } catch (error) {
            data.error = error;
        }
    } else {
        try {
            const res = await signInWithEmailAndPassword(auth, userForm.email, userForm.password);
            data.user = res;
        } catch (error) {
            data.error = error;
        }
    }

    return data;
}
export async function updateUserDarkMode(darkMode, id) {
    await updateDoc(doc(db, 'users', id), {
        darkMode,
    });
}

export async function fetchCurrentDarkMode(id) {
    const docRef = doc(db, 'users', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.data().darkMode) {
        return docSnap.data().darkMode;
    }
    return false;
}

export async function loginGoogle() {
    let id;

    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const result = await signInWithPopup(auth, provider);
    id = result.user.uid;
    const docRef = doc(db, 'users', id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.data()) {
        await setDoc(doc(db, 'users', result.user.uid), {
            email: result.user.email,
            id,
            municipality: 'parrysound',
        });
    }

    return id;
}
export async function loginFacebook() {
    let id;

    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    await signInWithPopup(auth, provider)
        .then((result) => {
            setDoc(doc(db, 'users', result.user.uid), {
                email: result.user.email,
                id: result.user.uid,
                municipality: 'parrysound',
            });
            id = result.user.uid;
        })
        .catch((error) => {
            console.log(error);
        });

    return id;
}
export async function setPermit(id, permitForm, userInfo, testAddress, burnCoord, priceId, paid) {
    let expired = '';

    const permitTypesRef = collection(db, 'municipalities', cityName, 'permitTypes');
    const q = query(permitTypesRef, where('name', '==', permitForm.type));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    const cost = dataArr[0].cost;
    const valid = parseInt(dataArr[0].valid);

    expired = dayjs().add(valid, 'day').format('YYYY-MM-DD');
    const docRef = await setDoc(doc(db, 'municipalities', cityName, 'permits', id), {
        first: permitForm.first,
        last: permitForm.last,
        phone: permitForm.phone,
        address: testAddress,
        type: permitForm.type,
        email: permitForm.email,
        burnCoord,
        date_created: dayjs().format('YYYY-MM-DD'),
        activate: false,
        cost,
        priceId,
        paid,
        userId: userInfo.id,
        inspected: false,
    });

    await updateDoc(doc(db, 'municipalities', cityName, 'permits', id), {
        id: id,
        expired,
    });
    // window.location.href = "/";
    const docSnap = await getDoc(docRef);
    return docSnap.data();
}
export async function addPermit(permitForm, userInfo, testAddress, burnCoord, priceId) {
    let expired = '';

    const permitTypesRef = collection(db, 'municipalities', cityName, 'permitTypes');
    const q = query(permitTypesRef, where('name', '==', permitForm.type));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    const cost = dataArr[0].cost;
    const valid = parseInt(dataArr[0].valid);

    expired = dayjs().add(valid, 'day').format('YYYY-MM-DD');
    const docRef = await addDoc(collection(db, 'municipalities', cityName, 'permits'), {
        first: permitForm.first,
        last: permitForm.last,
        phone: permitForm.phone,
        address: testAddress,
        type: permitForm.type,
        email: permitForm.email,
        burnCoord,
        date_created: dayjs().format('YYYY-MM-DD'),
        activate: false,
        cost,
        priceId,
        userId: userInfo.id,
        inspected: false,
    });

    await updateDoc(doc(db, 'municipalities', cityName, 'permits', docRef.id), {
        id: docRef.id,
        expired,
    });
    // window.location.href = "/";
    const docSnap = await getDoc(docRef);
    return docSnap.data();
}

export async function addPermitType(permitTypeForm) {
    const docRef = await addDoc(collection(db, 'municipalities', cityName, 'permitTypes'), {
        name: permitTypeForm.name,
        cost: permitTypeForm.cost,
        desc: permitTypeForm.desc,
        valid: permitTypeForm.valid,
        active: true,
    });

    await updateDoc(doc(db, 'municipalities', cityName, 'permitTypes', docRef.id), {
        id: docRef.id,
    });
    return docRef.id;
}

export async function fetchPermits() {
    const permitsRef = collection(db, 'municipalities', cityName, 'permits');
    const q = query(permitsRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });

    dataArr.forEach(async (data) => {
        if (typeof data.expired === 'number') {
            const newExpired = dayjs.unix(data.expired).format('YYYY-MM-DD');
            data.expired = newExpired;
            await updateDoc(doc(db, 'municipalities', cityName, `permits`, data.id), {
                expired: newExpired,
            });
        }
    });

    return dataArr;
}

export async function fetchSuperAdmins() {
    const permitsRef = collection(db, 'users');
    const q = query(permitsRef, where('superAdmin', '==', true));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });

    return dataArr;
}

export async function fetchAdmins() {
    const adminsRef = collection(db, 'users');
    const q1 = query(adminsRef, where('admin', '==', true));
    const querySnapshot1 = await getDocs(q1);
    const q2 = query(adminsRef, where('superAdmin', '==', true));
    const querySnapshot2 = await getDocs(q2);
    const dataArr = [];

    querySnapshot1.forEach((doc) => {
        dataArr.push(doc.data());
    });
    querySnapshot2.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}

export async function fetchPermitTypes() {
    const permitsRef = collection(db, 'municipalities', cityName, 'permitTypes');
    const q = query(permitsRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}

export async function deletePermitData(id) {
    await deleteDoc(doc(db, 'municipalities', cityName, `permits`, id));
}

export async function disablePermitTypeData(id) {
    await updateDoc(doc(db, 'municipalities', cityName, `permitTypes`, id), {
        active: false,
    });
}

export async function updatePermitType(permitTypeForm, id) {
    await updateDoc(doc(db, 'municipalities', cityName, `permitTypes`, id), {
        name: permitTypeForm.name,
        cost: permitTypeForm.cost,
        desc: permitTypeForm.desc,
        valid: permitTypeForm.valid,
    });
}

export async function updatePermit(permitForm, id, burnCoord) {
    let expired = permitForm.expired;
    if (permitForm.type === 'TypeA') {
        expired = dayjs(permitForm.date_created).add(30, 'day').format('YYYY-MM-DD');
    } else if (permitForm.type === 'TypeB') {
        expired = dayjs(permitForm.date_created).add(365, 'day').format('YYYY-MM-DD');
    } else if (permitForm.type === 'TypeC') {
        expired = `${dayjs(permitForm.date_created).get('year')}-12-31`;
    }
    await updateDoc(doc(db, 'municipalities', cityName, `permits`, id), {
        address: permitForm.address,
        first: permitForm.first,
        last: permitForm.last,
        phone: permitForm.phone,
        type: permitForm.type,
        expired,
        burnCoord,
    });
    window.location.href = '/';
}

export async function updatePermitBurnCoord(burnCoord, id) {
    await updateDoc(doc(db, 'municipalities', cityName, `permits`, id), {
        burnCoord,
    });
}

export async function updatePermitActiveness(id, activate) {
    await updateDoc(doc(db, 'municipalities', cityName, `permits`, id), {
        activate,
    });
}

export async function updatePermitInspect(id, inspected) {
    await updateDoc(doc(db, 'municipalities', cityName, `permits`, id), {
        inspected,
    });
    window.location.href = 'permits';
}

export async function fetchPermitActiveness(id) {
    const docRef = doc(db, 'municipalities', cityName, `permits`, id);
    const docSnap = await getDoc(docRef);

    return docSnap.data().activate;
}

export async function fetchPermitWithID(id) {
    const docRef = doc(db, 'municipalities', cityName, `permits`, id);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
}

export async function addRulesData(rules) {
    await updateDoc(doc(db, 'municipalities', cityName, 'permitRules', 'rulesInDraft'), {
        rules,
    });
    window.location.href = '/';
    return;
}

export async function fetchRulesInDraft() {
    const docRef = doc(db, 'municipalities', cityName, 'permitRules', 'rulesInDraft');
    const docSnap = await getDoc(docRef);

    if (docSnap.data()) {
        return docSnap.data().rules;
    }
}
export async function registerWidgetUser(userForm) {
    const auth = getAuth();
    const data = {};
    // const setUserDoc = httpsCallable(functions, 'setUserDoc');
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', userForm.email));
    const querySnapshot = await getDocs(q);
    const usersArr = [];
    querySnapshot.forEach((doc) => {
        usersArr.push(doc.data());
    });
    try {
        if (usersArr.length !== 0 && usersArr[0].widgetUser) {
            data.error = { message: 'Email already in used' };
        } else if (usersArr.length !== 0 && !usersArr[0].widgetUser) {
            //from parrysound user
        } else if (usersArr.length == 0) {
            const res = await createUserWithEmailAndPassword(
                auth,
                userForm.email,
                userForm.password
            );
            await setDoc(doc(db, 'users', res.user.uid), {
                id: res.user.uid,
                email: res.user.email,
                widgetUser: true,
                riskLevel: 1,
                weatherAddress: {
                    address: '123 Elizabeth St, Toronto (Default)',
                    lat: '43.6555048',
                    lng: '-79.3849606',
                },
                emailSubs: [],
                phoneSubs: [],
            });
            window.location.href = '/widget/control';
        }
    } catch (error) {
        data.error = error;
    }

    return data;
}

export async function registerUser(userForm) {
    const auth = getAuth();
    const data = {};
    const registerAdmin = httpsCallable(functions, 'registerAdmin');
    const registerTempAdmin = httpsCallable(functions, 'registerTempAdmin');
    // const setUserDoc = httpsCallable(functions, 'setUserDoc');
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', userForm.email));
    const querySnapshot = await getDocs(q);
    const usersArr = [];
    querySnapshot.forEach((doc) => {
        usersArr.push(doc.data());
    });
    try {
        if (userForm.type === 'superAdmin' || userForm.type === 'admin') {
            // registerTempAdmin(userForm);
            registerAdmin(userForm).then(async (userRecord) => {
                await setDoc(doc(db, 'users', userRecord.data.uid), {
                    id: userRecord.data.uid,
                    email: userForm.email,
                    municipality: 'parrysound',
                    [userForm.type]: true,
                });
                window.location.href = '/settings';
            });
        } else if (usersArr.length !== 0 && usersArr[0].phone && !usersArr[0].address) {
            sendPasswordResetEmail(auth, userForm.email);
            data.upgradeToFull = true;
        } else {
            const res = await createUserWithEmailAndPassword(
                auth,
                userForm.email,
                userForm.password
            );
            await setDoc(doc(db, 'users', res.user.uid), {
                id: res.user.uid,
                email: res.user.email,
                municipality: 'parrysound',
            });
            // let userDoc = {};
            // userDoc.id = res.user.uid;
            // userDoc.email = res.user.email;
            // userDoc.city = 'parrysound1';

            // setUserDoc(userDoc);
            await sendEmailVerification(auth.currentUser);
            // window.location.href = '/';
        }
    } catch (error) {
        data.error = error;
    }

    return data;
}

export async function addPriceToPermitType(id) {
    const addProductPrice = httpsCallable(functions, 'addProductPrice');

    const unsub = onSnapshot(
        doc(db, 'municipalities', cityName, `permitTypes`, id),
        async (doc) => {
            if (doc.data().stripeProductId && !doc.data().stripePriceId) {
                await addProductPrice(doc.data());
                window.location.href = '/permit-types';
            }
        }
    );
}
export async function fetchPermitTypeByName(name) {
    const permitTypeRef = collection(db, 'municipalities', cityName, 'permitTypes');
    const q = query(permitTypeRef, where('name', '==', name));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr[0].stripePriceId;
}

export async function resetPassword(email) {
    const auth = getAuth();
    try {
        const res = await sendPasswordResetEmail(auth, email);
    } catch (error) {}

    return;
}

export async function uploadHomeImage(file, city) {
    const fileRef = await ref(storage, file.name);
    await uploadBytes(fileRef, file).then((snapshot) => {});
    const fileURL = await getDownloadURL(fileRef);
    const docRef = await setDoc(doc(db, 'municipalities', cityName, 'imagesURL', 'homeImage'), {
        imageURL: fileURL,
        name: 'homeImage',
    });

    return;
}

export async function uploadHomeLogo(file, city) {
    const fileRef = await ref(storage, file.name);
    await uploadBytes(fileRef, file).then((snapshot) => {});
    const fileURL = await getDownloadURL(fileRef);
    const docRef = await setDoc(doc(db, 'municipalities', cityName, 'imagesURL', 'homeLogo'), {
        imageURL: fileURL,
        name: 'homeLogo',
    });
    return;
}

export async function fetchAllImages() {
    const imagesRef = collection(db, 'municipalities', cityName, 'imagesURL');
    const q = query(imagesRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}
export async function updateUserName(id, username) {
    const dataArr = [];
    const userRef = collection(db, 'users');
    const q = query(userRef, where('username', '==', username));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });

    if (dataArr.length === 0) {
        await updateDoc(doc(db, 'users', id), {
            username,
        });
        window.location.href = '/widget/control';
        return true;
    } else {
        return false;
    }
}
export async function updateRiskWidget(id, riskLevel) {
    await updateDoc(doc(db, 'users', id), {
        riskLevel,
    });
    window.location.href = '/widget/control';
}
export async function updateWeather(id, addressForm) {
    await updateDoc(doc(db, 'users', id), {
        weatherAddress: {
            address: addressForm.address,
            lat: addressForm.lat,
            lng: addressForm.lng,
        },
    });
    window.location.href = '/widget/control';
}

export async function updateProfile(profileForm, city) {
    await updateDoc(doc(db, 'municipalities', city), {
        name: profileForm.name,
        address: profileForm.address,
        phone: profileForm.phone,
        byLaw: profileForm.byLaw,
        byLawName: profileForm.byLawName,
        blurb: profileForm.blurb,
        email: profileForm.email,
    });
}

export async function fetchProfile(city) {
    const docRef = doc(db, 'municipalities', city);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
}

export async function updateLoginWidgets(city, isOn, field) {
    if (field === 'riskToggle') {
        await updateDoc(doc(db, 'municipalities', city), {
            riskToggle: !isOn,
        });
    }
    if (field === 'weatherToggle') {
        await updateDoc(doc(db, 'municipalities', city), {
            weatherToggle: !isOn,
        });
    }
    if (field === 'contactToggle') {
        await updateDoc(doc(db, 'municipalities', city), {
            contactToggle: !isOn,
        });
    }
    if (field === 'announcementsToggle') {
        await updateDoc(doc(db, 'municipalities', city), {
            announcementsToggle: !isOn,
        });
    }
    if (field === 'riskLevel') {
        await updateDoc(doc(db, 'municipalities', city), {
            riskLevel: isOn,
        });
    }
    if (field === 'activateToggle') {
        await updateDoc(doc(db, 'municipalities', city), {
            activateToggle: !isOn,
        });
    }
    if (field === 'burnBanToggle') {
        await updateDoc(doc(db, 'municipalities', city), {
            burnBanToggle: !isOn,
        });
    }
    if (field === 'EOYWarningToggle') {
        await updateDoc(doc(db, 'municipalities', city), {
            EOYWarningToggle: !isOn,
        });
    }
}

export async function addAnnouncement(announcementForm) {
    const sendSMS = httpsCallable(functions, 'sendSMS');
    const sendAnnouncementEmail = httpsCallable(functions, 'sendAnnouncementEmail');

    const docRef = await addDoc(collection(db, 'municipalities', cityName, 'announcements'), {
        title: announcementForm.title,
        details: announcementForm.details,
        link: announcementForm.link,
        sendToLanding: announcementForm.sendToLanding,
        sendViaSMS: announcementForm.sendViaSMS,
        sendViaEmail: announcementForm.sendViaEmail,
        date_created: dayjs().format('YYYY-MM-DD'),
    });
    await updateDoc(doc(db, 'municipalities', cityName, 'announcements', docRef.id), {
        id: docRef.id,
    });
    const usersRef = collection(db, 'users');
    const q = query(usersRef);
    const querySnapshot = await getDocs(q);
    const phoneArr = [];
    const emailArr = [];
    await querySnapshot.forEach((doc) => {
        if (doc.data().phone && doc.data().smsSub) {
            phoneArr.push(doc.data().phone);
        }
    });
    await querySnapshot.forEach((doc) => {
        if (doc.data().email && doc.data().emailSub) {
            emailArr.push(doc.data().email);
        }
    });
    const newCityName = municipality.replace(/\s/g, '');
    if (announcementForm.sendViaEmail && announcementForm.sendViaSMS) {
        let smsData = {};
        smsData.phoneArr = phoneArr;
        smsData.text = `${municipality}: ${announcementForm.title} - ${announcementForm.details}`;
        await sendSMS(smsData).then();
        let emailData = {};
        emailData.emailArr = emailArr;
        emailData.title = announcementForm.title;
        emailData.details = announcementForm.details;
        if (announcementForm.link) {
            emailData.link = announcementForm.link;
        } else {
            emailData.link = `https://${newCityName}.firepermit.online`;
        }
        await sendAnnouncementEmail(emailData).then();
        window.location.href = '/announcement';
    } else if (announcementForm.sendViaSMS) {
        let smsData = {};
        smsData.phoneArr = phoneArr;
        smsData.text = `${municipality}: ${announcementForm.title} - ${announcementForm.details}`;
        sendSMS(smsData).then(() => {
            window.location.href = '/announcement';
        });
    } else if (announcementForm.sendViaEmail) {
        let emailData = {};
        emailData.emailArr = emailArr;
        emailData.title = announcementForm.title;
        emailData.details = announcementForm.details;
        if (announcementForm.link) {
            emailData.link = announcementForm.link;
        } else {
            emailData.link = `https://${newCityName}.firepermit.online`;
        }
        sendAnnouncementEmail(emailData).then(() => {
            window.location.href = '/announcement';
        });
    }
}

export async function fetchAllAnnouncements() {
    const docsRef = collection(db, 'municipalities', cityName, 'announcements');
    const q = query(docsRef);
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}

export async function deleteAnnouncementById(id) {
    await deleteDoc(doc(db, 'municipalities', cityName, `announcements`, id));
    window.location.href = '/announcement';
}

export async function fetchUser(id) {
    const docRef = doc(db, 'users', id);
    const docSnap = await getDoc(docRef);

    return docSnap.data();
}

export async function saveDetailUserInfo(form, address, id) {
    await updateDoc(doc(db, 'users', id), {
        first: form.first,
        last: form.last,
        phone: `+${form.phone}`,
        address: address,
        smsSub: form.smsSub,
        emailSub: form.emailSub,
        emergencySub: form.emergencySub,
    });
    window.location.href = '/profile';
    return;
}

export async function fetchUserPermits(email) {
    const exclusionsRef = collection(db, 'municipalities', cityName, 'permits');
    const q = query(exclusionsRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr;
}

export async function deleteAccountById(id) {
    const cancelSubs = httpsCallable(functions, 'cancelSubs');
    const auth = getAuth();
    const email = auth.currentUser.email;

    const permitsRef = collection(db, 'municipalities', cityName, 'permits');
    const q = query(permitsRef, where('email', '==', email), where('autoRenew', '==', true));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    await deleteDoc(doc(db, 'users', id));
    await cancelSubs(dataArr);
    await signOut(auth);
    window.location.href = '/login';
    return;
}

export async function deleteAdminAccountById(id) {
    const deleteAdmin = httpsCallable(functions, 'deleteAdmin');

    const auth = getAuth();
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('superAdmin', '==', true));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data().id);
    });
    if (dataArr.includes(auth.currentUser.uid)) {
        deleteAdmin(id).then((data) => {});
        // window.location.href = '/settings';
    }
}
export async function sendPermitInfoEmail(data) {
    const sendPermitInfoEmail = httpsCallable(functions, 'sendPermitInfoEmail');
    return sendPermitInfoEmail(data);
}
export async function createSubCheckout(data) {
    const createSubCheckout = httpsCallable(functions, 'createSubCheckout');
    return createSubCheckout(data);
}

export async function addStripeProduct(data) {
    const addStripeProduct = httpsCallable(functions, 'addStripeProduct');
    await addStripeProduct(data);
    return;
}

export async function unarchiveProduct(data) {
    const unarchiveProduct = httpsCallable(functions, 'unarchiveProduct');
    return unarchiveProduct(data);
}
export async function archiveProduct(data) {
    const archiveProduct = httpsCallable(functions, 'archiveProduct');
    return archiveProduct(data);
}

export async function refundPermit(data) {
    const refundPermit = httpsCallable(functions, 'refundPermit');
    return refundPermit(data);
}
export async function sendPermitEmailToAdmin(data) {
    try {
        const sendPermitEmailToAdmin = httpsCallable(functions, 'sendPermitEmailToAdmin');
        sendPermitEmailToAdmin(data);
    } catch (error) {
        console.log(error);
    }
}

export async function reactivateSub(data) {
    const reactivateSub = httpsCallable(functions, 'reactivateSub');
    return reactivateSub(data);
}

export async function cancelSub(data) {
    const cancelSub = httpsCallable(functions, 'cancelSub');
    return cancelSub(data);
}

export async function registerAdmin(data) {
    const registerAdmin = httpsCallable(functions, 'registerAdmin');
    return registerAdmin(data);
}
export async function sendEmergencyNotification(form) {
    const sendEmergencyEmail = httpsCallable(functions, 'sendEmergencyEmail');
    const sendSMS = httpsCallable(functions, 'sendSMS');
    const usersRef = collection(db, 'users');
    const q = query(usersRef);
    const querySnapshot = await getDocs(q);
    const emailArr = [];
    const phoneArr = [];
    await querySnapshot.forEach((doc) => {
        if (doc.data().email && doc.data().emergencySub) {
            emailArr.push(doc.data().email);
        }
    });
    await querySnapshot.forEach((doc) => {
        if (doc.data().phone && doc.data().emergencySub) {
            phoneArr.push(doc.data().phone);
        }
    });

    let emailData = {};
    emailData.emailArr = emailArr;
    emailData.content = form.details;
    let smsData = {};
    smsData.phoneArr = phoneArr;
    smsData.text = `${municipality}: ${form.title} - ${form.details}`;

    await sendEmergencyEmail(emailData);
    await sendSMS(smsData);

    window.location.href = '/announcement';
}
export async function sendEmergencyToUserArr(userInfo, title, details) {
    const sendGeneralEmergencyEmail = httpsCallable(functions, 'sendGeneralEmergencyEmail');
    let emailData = {};
    emailData.emailArr = userInfo.emailSubs;
    emailData.username = userInfo.username;
    emailData.title = title;
    emailData.content = details;
    // await sendGeneralEmergencyEmail(emailData);
    // window.location.href = '/widget/control';
}

export async function signUpForEmergency(email) {
    const auth = getAuth();

    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    // email not registered
    if (dataArr.length === 0) {
        const sendNonUserVerification = httpsCallable(functions, 'sendNonUserVerification');
        sendNonUserVerification(email);
        return true;
    } else {
        return false;
    }
}

export async function resendEmailVerification() {
    const auth = getAuth();

    sendEmailVerification(auth.currentUser);
    return;
}

export async function sessionIdToPermit(id, sessionId) {
    await updateDoc(doc(db, 'municipalities', cityName, `permits`, id), {
        sessionId,
    });
    return;
}

//for general emergency sign up
export async function noPwSignUp(email, city) {
    if (!city) {
        city = 'parrysound';
    }
    const actionCodeSettings = {
        url: `${domain}/noPwSignIn/${city}`,
        handleCodeInApp: true,
    };
    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings);
    return true;
}
export async function subToCity(email, city) {
    const exclusionsRef = collection(db, 'users');
    const q = query(exclusionsRef, where('username', '==', city));
    const querySnapshot = await getDocs(q);
    const dataArr = [];

    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    const emailSubs = dataArr[0].emailSubs;
    if (!emailSubs.includes(email)) {
        emailSubs.push(email);
        await updateDoc(doc(db, 'users', dataArr[0].id), {
            emailSubs,
        });
    }
}
// export async function noPwSignIn(city) {
//     const auth = getAuth();
//
//     // const setUserDoc = httpsCallable(functions, 'setUserDoc');
//     if (isSignInWithEmailLink(auth, window.location.href)) {
//         // Additional state parameters can also be passed via URL.
//         // This can be used to continue the user's intended action before triggering
//         // the sign-in operation.
//         // Get the email if available. This should be available if the user completes
//         // the flow on the same device where they started it.
//         let email = window.localStorage.getItem('noPwEmail');

//         signInWithEmailLink(auth, email, window.location.href)
//             .then(async (result) => {
//                 // let userForm = {};
//                 // userForm.email = result.user.email;
//                 // userForm.id = result.user.uid;
//                 // userForm.city = city;

//                 // setUserDoc(userForm);
//                 await setDoc(doc(db, 'users', result.user.uid), {
//                     id: result.user.uid,
//                     email: result.user.email,
//                     municipality: 'parrysound',
//                 });
//                 if (city) {
//                     await updateDoc(doc(db, 'users', result.user.uid), {
//                         municipality: city,
//                     });
//                 }
//                 const signInMethods = await fetchSignInMethodsForEmail(auth, email);
//                 window.localStorage.setItem('signInMethods', signInMethods);
//                 // Clear email from storage.
//                 window.localStorage.removeItem('noPwEmail');

//                 // You can access the new user via result.user
//                 // Additional user info profile not available via:
//                 // result.additionalUserInfo.profile == null
//                 // You can check if the user is new or existing:
//                 // result.additionalUserInfo.isNewUser
//                 window.location.href = `${domain}/register`;
//             })
//             .catch((error) => {
//                 // Some error occurred, you can inspect the code: error.code
//                 // Common errors could be invalid email and invalid or expired OTPs.
//             });
//     }
// }

export async function noPwSignIn(city, email) {
    const auth = getAuth();

    let data = {};
    // const setUserDoc = httpsCallable(functions, 'setUserDoc');
    try {
        await signInWithEmailLink(auth, email, window.location.href).then(async (result) => {
            // let userForm = {};
            // userForm.email = result.user.email;
            // userForm.id = result.user.uid;
            // userForm.city = city;

            // setUserDoc(userForm);
            const dataArr = [];
            const userRef = collection(db, 'users');
            const q = query(userRef, where('email', '==', email));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                dataArr.push(doc.data());
            });

            //edge case for widget user doing emergency sign up with Parry Sound
            if (dataArr.length === 0) {
                await setDoc(doc(db, 'users', result.user.uid), {
                    id: result.user.uid,
                    email: result.user.email,
                    municipality: 'parrysound',
                });
            } else {
                await updateDoc(doc(db, 'users', result.user.uid), {
                    municipality: 'parrysound',
                });
            }

            if (city) {
                await updateDoc(doc(db, 'users', result.user.uid), {
                    municipality: city,
                });
            }

            const signInMethods = await fetchSignInMethodsForEmail(auth, email);
            window.localStorage.setItem('signInMethods', signInMethods);

            window.location.href = `${domain}/register`;
            data.res = true;
            return data;
        });
    } catch (error) {
        data.error = error;
    }

    return data;
}

export async function saveNoPwUserInfo(id, noPwUserForm) {
    const auth = getAuth();
    await updateDoc(doc(db, 'users', id), {
        phone: noPwUserForm.phone,
        emergencySub: noPwUserForm.emergencySub,
    });
    localStorage.clear();
    signOut(auth);
}

export async function updateAdminEmails(email, adminEmailSub) {
    const docRef = doc(db, 'municipalities', cityName, `adminEmails`, 'adminEmails');
    const docSnap = await getDoc(docRef);

    let data = docSnap.data().emails;

    //unsubscribe
    if (adminEmailSub && data.includes(email)) {
        data = data.filter((e) => e !== email);
        await updateDoc(doc(db, 'municipalities', cityName, `adminEmails`, 'adminEmails'), {
            emails: data,
        });
        //subscribe
    } else if (!adminEmailSub && !data.includes(email)) {
        data.push(email);
        await updateDoc(doc(db, 'municipalities', cityName, `adminEmails`, 'adminEmails'), {
            emails: data,
        });
    }
    return;
}

export async function fetchAdminEmails() {
    const docRef = doc(db, 'municipalities', cityName, `adminEmails`, 'adminEmails');
    const docSnap = await getDoc(docRef);

    return docSnap.data();
}

export async function fetchRiskByUsername(username) {
    const dataArr = [];
    const q = query(userRef, where('username', '==', username));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr[0].riskLevel;
}

export async function fetchWeatherByUsername(username) {
    const dataArr = [];
    const q = query(userRef, where('username', '==', username));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        dataArr.push(doc.data());
    });
    return dataArr[0].weatherAddress;
}

export const updatePermitNotes = async (permitId, notes) => {
    const permitRef = doc(db, 'municipalities', cityName, 'permits', permitId);

    // First, check if the document exists
    const docSnap = await getDoc(permitRef);

    if (!docSnap.exists()) {
        console.error(`Permit document with ID ${permitId} does not exist`);
        throw new Error(`Permit document with ID ${permitId} does not exist`);
    }

    console.log(`Updating notes for permit ${permitId}`);

    try {
        await updateDoc(permitRef, { notes: notes });
        console.log(`Notes updated successfully for permit ${permitId}`);
    } catch (error) {
        console.error(`Error updating notes for permit ${permitId}:`, error);
        throw error;
    }
};
