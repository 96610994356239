import {
    IonItem,
    IonLabel,
    IonButton,
    IonTextarea,
    IonToggle,
    IonInput,
    IonAlert,
    IonCard,
} from '@ionic/react';
import '../../theme/AnnouncementForm.css';
import { toggleStore, appStore, announcementFormStore } from '../../store/Store';
import { addAnnouncement, sendEmergencyNotification } from '../../firebaseConfig';

function AnnouncementForm() {
    const confirmAnnouncementToggle = toggleStore.useState((s) => s.confirmAnnouncementToggle);
    const confirmAnnouncementSMSToggle = toggleStore.useState(
        (s) => s.confirmAnnouncementSMSToggle
    );
    const emptyAnnouncementTitle = toggleStore.useState((s) => s.emptyAnnouncementTitle);
    const emptyAnnouncementDetails = toggleStore.useState((s) => s.emptyAnnouncementDetails);
    const confirmAnnouncementEmailToggle = toggleStore.useState(
        (s) => s.confirmAnnouncementEmailToggle
    );
    const announcementForm = announcementFormStore.useState((s) => s.announcementForm);

    const updateFormDoc = (value, field) => {
        if (field === 'sendToLanding') {
            const sendToLanding = announcementForm.sendToLanding;
            announcementFormStore.update((s) => {
                s.announcementForm = { ...s.announcementForm, [field]: !sendToLanding };
            });
        } else if (field === 'sendViaSMS') {
            const sendViaSMS = announcementForm.sendViaSMS;
            announcementFormStore.update((s) => {
                s.announcementForm = { ...s.announcementForm, [field]: !sendViaSMS };
            });
        } else if (field === 'sendViaEmail') {
            const sendViaEmail = announcementForm.sendViaEmail;
            announcementFormStore.update((s) => {
                s.announcementForm = { ...s.announcementForm, [field]: !sendViaEmail };
            });
        } else if (field === 'sendEmergency') {
            const sendEmergency = announcementForm.sendEmergency;
            announcementFormStore.update((s) => {
                s.announcementForm = { ...s.announcementForm, [field]: !sendEmergency };
            });
            if (!sendEmergency) {
                announcementFormStore.update((s) => {
                    s.announcementForm = {
                        ...s.announcementForm,
                        sendViaEmail: true,
                        sendViaSMS: true,
                        title: 'Emergenct Alert',
                    };
                });
            } else {
                announcementFormStore.update((s) => {
                    s.announcementForm = {
                        ...s.announcementForm,
                        title: '',
                    };
                });
            }
        } else {
            announcementFormStore.update((s) => {
                s.announcementForm = { ...s.announcementForm, [field]: value };
            });
        }
    };

    const closeAnnouncementForm = () => {
        toggleStore.update((s) => {
            s.announcementFormToggle = false;
        });
    };

    const submitAnnouncementForm = () => {
        if (!announcementForm.title) {
            toggleStore.update((s) => {
                s.emptyAnnouncementTitle = true;
            });
        } else {
            toggleStore.update((s) => {
                s.emptyAnnouncementTitle = false;
            });
        }
        if (!announcementForm.details) {
            toggleStore.update((s) => {
                s.emptyAnnouncementDetails = true;
            });
        } else {
            toggleStore.update((s) => {
                s.emptyAnnouncementDetails = false;
            });
        }

        if (announcementForm.sendViaSMS && announcementForm.sendViaEmail) {
            toggleStore.update((s) => {
                s.confirmAnnouncementToggle = true;
            });
        } else if (announcementForm.sendViaEmail) {
            toggleStore.update((s) => {
                s.confirmAnnouncementEmailToggle = true;
            });
        } else if (announcementForm.sendViaSMS) {
            toggleStore.update((s) => {
                s.confirmAnnouncementSMSToggle = true;
            });
        } else if (
            announcementForm.title &&
            announcementForm.details &&
            !announcementForm.sendEmergency
        ) {
            appStore.update((s) => {
                s.loadingSpinner = true;
            });
            addAnnouncement(announcementForm).then(() => {
                window.location.href = '/announcement';
            });
        } else if (
            announcementForm.title &&
            announcementForm.details &&
            announcementForm.sendEmergency
        ) {
            appStore.update((s) => {
                s.loadingSpinner = true;
            });
            sendEmergencyNotification(announcementForm);
        }
    };

    const closeAlert = () => {
        toggleStore.update((s) => {
            s.confirmAnnouncementEmailToggle = false;
            s.confirmAnnouncementSMSToggle = false;
            s.confirmAnnouncementToggle = false;
        });
    };
    return (
        <IonCard className='announcement-card'>
            <IonAlert
                isOpen={confirmAnnouncementEmailToggle}
                onDidDismiss={closeAlert}
                header={'Confirm'}
                message={
                    'By clicking confirm, all users will immediately reveive an email notification'
                }
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Confirm',

                        handler: () => {
                            if (!announcementForm.title || !announcementForm.details) {
                                alert('Please fill in required fields');
                            } else if (!announcementForm.sendEmergency) {
                                appStore.update((s) => {
                                    s.loadingSpinner = true;
                                });
                                addAnnouncement(announcementForm);
                            } else if (announcementForm.sendEmergency) {
                                appStore.update((s) => {
                                    s.loadingSpinner = true;
                                });
                                sendEmergencyNotification(announcementForm);
                            }
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={confirmAnnouncementSMSToggle}
                onDidDismiss={closeAlert}
                header={'Confirm'}
                message={
                    'By clicking confirm, all users will immediately reveive a text notification'
                }
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Confirm',

                        handler: () => {
                            if (!announcementForm.title || !announcementForm.details) {
                                alert('Please fill in required fields');
                            } else if (!announcementForm.sendEmergency) {
                                appStore.update((s) => {
                                    s.loadingSpinner = true;
                                });
                                addAnnouncement(announcementForm);
                            } else if (announcementForm.sendEmergency) {
                                appStore.update((s) => {
                                    s.loadingSpinner = true;
                                });
                                sendEmergencyNotification(announcementForm);
                            }
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={confirmAnnouncementToggle}
                onDidDismiss={closeAlert}
                header={'Confirm'}
                message={
                    'By clicking confirm, all users will immediately reveive both an email and a text notification'
                }
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Confirm',

                        handler: () => {
                            if (!announcementForm.title || !announcementForm.details) {
                                alert('Please fill in required fields');
                            } else if (!announcementForm.sendEmergency) {
                                appStore.update((s) => {
                                    s.loadingSpinner = true;
                                });
                                addAnnouncement(announcementForm);
                            } else if (announcementForm.sendEmergency) {
                                appStore.update((s) => {
                                    s.loadingSpinner = true;
                                });
                                sendEmergencyNotification(announcementForm);
                            }
                        },
                    },
                ]}
            />
            <IonItem>
                <IonLabel>Title:</IonLabel>
                <IonInput
                    className='left-side-input'
                    onIonChange={(e) => {
                        if (!announcementForm.sendEmergency) {
                            updateFormDoc(e.target.value, 'title');
                        } else {
                            updateFormDoc('Emergency Alert', 'title');
                        }
                    }}
                    required
                    placeholder='Enter title'
                    value={announcementForm.title}
                />
            </IonItem>
            <p className={emptyAnnouncementTitle ? 'error-msg' : 'hide-error-msg'}>
                Please enter title{' '}
            </p>
            <IonItem>
                <IonLabel>Link (optional):</IonLabel>
                <IonInput
                    className='left-side-input'
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'link');
                    }}
                    required
                    placeholder='Enter URL'
                />
            </IonItem>
            <IonItem>
                <IonLabel>Announcement</IonLabel>{' '}
            </IonItem>
            <br />
            <div>
                <IonTextarea
                    className='blurb-input'
                    onIonChange={(e) => {
                        updateFormDoc(e.target.value, 'details');
                    }}
                    placeholder='Enter more information here...'
                ></IonTextarea>
            </div>
            <p className={emptyAnnouncementDetails ? 'error-msg' : 'hide-error-msg'}>
                Please enter announcement{' '}
            </p>
            <br />
            {!announcementForm.sendEmergency && (
                <>
                    <div className='form-toggle'>
                        <IonLabel>Landing Page</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateFormDoc(e, 'sendToLanding');
                            }}
                            checked={announcementForm.sendToLanding}
                        />
                    </div>
                    <div className='form-toggle'>
                        <IonLabel>Send Text</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateFormDoc(e, 'sendViaSMS');
                            }}
                            checked={announcementForm.sendViaSMS}
                        />
                    </div>
                    <div className='form-toggle'>
                        <IonLabel>Send Email</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateFormDoc(e, 'sendViaEmail');
                            }}
                            checked={announcementForm.sendViaEmail}
                        />
                    </div>{' '}
                </>
            )}
            <div className='form-toggle'>
                <IonLabel>Send As Emergency</IonLabel>
                <IonToggle
                    onIonChange={(e) => {
                        updateFormDoc(e, 'sendEmergency');
                    }}
                    checked={announcementForm.sendEmergency}
                />
            </div>{' '}
            <IonButton className='ion-padding' onClick={submitAnnouncementForm}>
                Submit
            </IonButton>
            <IonButton className='ion-padding' onClick={closeAnnouncementForm}>
                Back
            </IonButton>
            <br />
            <br />
            <br />
        </IonCard>
    );
}

export default AnnouncementForm;
