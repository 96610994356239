import React from 'react';
import { IonIcon } from '@ionic/react';
import {
    createOutline,
    trashOutline,
    personRemoveOutline,
    personAddOutline,
    backspaceOutline,
    radioButtonOn,
    documentOutline,
    documentTextOutline,
} from 'ionicons/icons';
import {
    openCancelSubAlert,
    openReactivateSubAlert,
    openInspectAlert,
    openRefundAlert,
    editPermit,
    deletePermit,
    pdfGenerate,
    openNotesModal,
} from './permitActions';

export const columns = [
    'Status',
    'Notes',
    'Name',
    'Phone',
    'Address',
    'Expires',
    'Type',
    'Subscribe',
    'Inspect',
    'Refund',
    'Edit',
    'Delete',
    'PDF',
];

export const formatPermitData = (permits) => {
    return permits
        .filter((permit) => permit.paid)
        .map((permit) => {
            const {
                id,
                cancelled,
                inspected,
                first,
                last,
                phone,
                address,
                expired,
                type,
                autoRenew,
                refundable,
                paymentIntentId,
            } = permit;

            const status = cancelled ? 'Cancelled' : inspected ? 'Inspected' : 'Awaiting';
            const notesBtn = (
                <IonIcon onClick={() => openNotesModal(permit)} icon={documentTextOutline} />
            );
            const name = `${first} ${last}`;

            const subscribeBtn = autoRenew ? (
                <IonIcon
                    onClick={() => openCancelSubAlert(permit)}
                    className='subscribe-icon'
                    icon={personRemoveOutline}
                />
            ) : (
                <IonIcon
                    onClick={() => openReactivateSubAlert(permit)}
                    className='subscribe-icon'
                    icon={personAddOutline}
                />
            );

            const inspectBtn = (
                <IonIcon
                    onClick={() => openInspectAlert(permit)}
                    className={`inspect-icon ${inspected ? 'inspected' : ''}`}
                    icon={radioButtonOn}
                />
            );

            const refundBtn = refundable ? (
                <IonIcon onClick={() => openRefundAlert(permit)} icon={backspaceOutline} />
            ) : (
                'N/A'
            );

            const editBtn = <IonIcon onClick={() => editPermit(permit)} icon={createOutline} />;

            const deleteBtn = <IonIcon onClick={() => deletePermit(permit)} icon={trashOutline} />;

            const pdfBtn = <IonIcon onClick={() => pdfGenerate(id)} icon={documentOutline} />;

            return [
                status,
                notesBtn,
                name,
                phone,
                address,
                expired,
                type,
                paymentIntentId ? subscribeBtn : 'N/A',
                inspectBtn,
                refundBtn,
                editBtn,
                deleteBtn,
                pdfBtn,
            ];
        });
};
