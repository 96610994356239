import {
    IonGrid,
    IonRow,
    IonCol,
    IonLoading,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
} from '@ionic/react';
import { profileFormStore } from '../../store/Store';
const BurnBan = () => {
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);

    if (settingsForm) {
        return (
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonCard mode='ios' color='danger'>
                            <IonCardHeader>
                                <IonCardTitle>Fire Ban</IonCardTitle>
                                <IonCardSubtitle>
                                    is currently in effect for our region.
                                </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                                <p>
                                    {settingsForm.city} has instituted a fire ban. No burns are
                                    permitted. Please monitor this area for updates.
                                </p>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        );
    } else {
        return <IonLoading isOpen={true} />;
    }
};
export default BurnBan;
