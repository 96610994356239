import { IonLabel, IonToggle } from '@ionic/react';
import '../../theme/LoginToggles.css';
import { appStore, toggleStore } from '../../store/Store';
import { updateAdminEmails, fetchAdminEmails } from '../../firebaseConfig';
import { useEffect } from 'react';
function SubscriptionControl() {
    const adminEmails = appStore.useState((s) => s.adminEmails);
    const userInfo = appStore.useState((s) => s.userInfo);
    const adminEmailSub = toggleStore.useState((s) => s.adminEmailSub);

    const updateEmails = (e) => {
        updateAdminEmails(userInfo.email, adminEmailSub);
        toggleStore.update((s) => {
            s.adminEmailSub = !adminEmailSub;
        });
    };
    useEffect(() => {
        fetchAdminEmails().then((data) => {
            appStore.update((s) => {
                s.adminEmails = data;
            });
            if (data.emails.includes(userInfo.email)) {
                toggleStore.update((s) => {
                    s.adminEmailSub = true;
                });
            }
        });
    }, []);
    return (
        <>
            {adminEmails && (
                <div className='login-toggle'>
                    <IonLabel>Subscribe to permit notification</IonLabel>
                    <IonToggle
                        onIonChange={(e) => {
                            updateEmails(e);
                        }}
                        checked={adminEmailSub}
                    />
                    {/* <IonButton
                        onClick={() => {
                            sendPermitEmailToAdmin({
                                first: 'first',
                                last: 'last',
                                phone: 'phone',
                                email: 'email',
                                address: 'address',
                                id: '123',
                            });
                        }}
                    >
                        Test
                    </IonButton> */}
                </div>
            )}
        </>
    );
}

export default SubscriptionControl;
