import '../../theme/PermitForm.css';
import axios from 'axios';
import { LoadScript } from '@react-google-maps/api';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PhoneInput from 'react-phone-input-2';
import React, { useEffect } from 'react';
import {
    IonButton,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonCol,
    IonRow,
    IonLabel,
    IonToggle,
    IonItem,
    IonCard,
} from '@ionic/react';
import {
    permitFormStore,
    appStore,
    toggleStore,
    addressFormStore,
    permitsStore,
    coordsStore,
    profileFormStore,
} from '../../store/Store';
import {
    addPermit,
    setPermit,
    updatePermit,
    fetchPermitTypeByName,
    fetchPolygonCoords,
    fetchCircleCoords,
    fetchRectangleCoords,
    createSubCheckout,
    sendPermitEmailToAdmin,
} from '../../firebaseConfig';
import { loadStripe } from '@stripe/stripe-js';
import { useStoreState } from 'pullstate';
function PermitForm() {
    const permitFormToggle = toggleStore.useState((s) => s.permitFormToggle);
    const userInfo = appStore.useState((s) => s.userInfo);
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);
    const currentPermitId = permitsStore.useState((s) => s.currentPermitId);
    const permitTypes = permitsStore.useState((s) => s.permitTypes);
    const permitForm = permitFormStore.useState((s) => s.permitForm);
    const addressForm = addressFormStore.useState((s) => s.addressForm);
    const permitAddressToggle = toggleStore.useState((s) => s.permitAddressToggle);
    const userId = appStore.useState((s) => s.userId);

    const subscribeToggle = toggleStore.useState((s) => s.subscribeToggle);
    const userAddressInput = toggleStore.useState((s) => s.userAddressInput);
    const userManualAddressInput = toggleStore.useState((s) => s.userManualAddressInput);

    const typeEmptyToggle = toggleStore.useState((s) => s.typeEmptyToggle);
    const emailFailToggle = toggleStore.useState((s) => s.emailFailToggle);
    const addressEmptyToggle = toggleStore.useState((s) => s.addressEmptyToggle);
    const phoneFailToggle = toggleStore.useState((s) => s.phoneFailToggle);
    const addressPass = appStore.useState((s) => s.addressPass);
    const polygonCoords = coordsStore.useState((s) => s.polygonCoords);
    const circleCoords = coordsStore.useState((s) => s.circleCoords);
    const rectangleCoords = coordsStore.useState((s) => s.rectangleCoords);
    const { mapsAPI } = useStoreState(profileFormStore);
    const updateFormDoc = (value, field) => {
        permitFormStore.update((s) => {
            s.permitForm = { ...s.permitForm, [field]: value };
        });
    };

    useEffect(() => {
        //fetch PolygonCoords
        fetchPolygonCoords().then((data) => {
            // after fetching data from firestore, transform it into the correct form so that the polygon component can use
            let final1Data = [];
            if (data.length > 0) {
                data.forEach((coord) => {
                    let finalData = [];
                    let transformedData = null;

                    transformedData = coord.coords
                        .toString()
                        .replace(/[{()}]/g, '')
                        .replace(/ /g, '')
                        .split(',')
                        .map((coord) => parseFloat(coord));
                    for (let i = 0; i < transformedData.length; i = i + 2) {
                        finalData.push({
                            lng: transformedData[i + 1],
                            lat: transformedData[i],
                        });
                    }
                    final1Data.push({
                        coords: finalData,
                        id: coord.id,
                        info: coord.info,
                    });
                });
                coordsStore.update((s) => {
                    s.polygonCoords = final1Data;
                });
            }
            return;
        });

        // fetch CircleCoords
        fetchCircleCoords().then((data) => {
            coordsStore.update((s) => {
                s.circleCoords = data;
            });
        });

        // fetch RectangleCoords
        fetchRectangleCoords().then((data) => {
            coordsStore.update((s) => {
                s.rectangleCoords = data;
            });
        });
    }, []);

    const handleChange = (e) => {
        updateFormDoc(e, 'address');
        const result = e.split(',');
        //clearing stuff after comma should also clear corresponding field in manual address
        if (result[0] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: '' };
                s.addressForm = { ...s.addressForm, city: '' };
                s.addressForm = { ...s.addressForm, province: '' };
            });
        } else if (result[1] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: '' };
                s.addressForm = { ...s.addressForm, province: '' };
            });
        } else if (result[2] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, province: '' };
            });
        }

        if (result[0]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: `${result[0]}` };
            });
        }

        // if this line is not added you cant enter comma
        if (result[0] && result[1] !== undefined) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: `${result[0]}` };
            });
        }
        if (result[1]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: `${result[1]}` };
            });
        }
        // if this line is not added you cant enter comma
        if (result[1] && result[2] !== undefined) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: `${result[1]}` };
            });
        }
        if (result[2]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, province: `${result[2]}` };
            });
        }
    };
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const submitPermitFormNoPayment = () => {
        submitPermitForm('paid');
    };
    const submitPermitForm = (e) => {
        const paid = e === 'paid' ? true : false;
        appStore.update((s) => {
            s.loadingSpinner = false;
        });

        toggleStore.update((s) => {
            s.addressEmptyToggle = false;
        });

        //change address input to lat and lng using geo api
        let testAddress;
        if (userInfo.address) {
            testAddress = userInfo.address;
        }
        if (permitForm.address) {
            testAddress = permitForm.address;
        }

        axios
            .get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: testAddress,
                    key: mapsAPI,
                },
            })
            .then((response) => {
                let pass = true;
                const checkLat = response.data.results[0].geometry.location.lat;
                const checkLng = response.data.results[0].geometry.location.lng;
                // check if the input address coord falls into the circle areas
                circleCoords.forEach((coord) => {
                    const circleRaidusInDegree = (coord.radius / 1.11) * 0.00001;
                    const circleX = coord.coords.lng;
                    const circleY = coord.coords.lat;
                    const xDifference = Math.abs(checkLng - circleX);
                    const yDifference = Math.abs(checkLat - circleY);
                    const pointRaidus = Math.sqrt(
                        xDifference * xDifference + yDifference * yDifference
                    );

                    if (circleRaidusInDegree > pointRaidus) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                        pass = false;
                    }
                });
                // check if the input address coord falls into the rectangle areas
                rectangleCoords.forEach((coord) => {
                    if (
                        coord.coords[2].lat > checkLat &&
                        coord.coords[0].lat < checkLat &&
                        coord.coords[2].lng > checkLng &&
                        coord.coords[0].lng < checkLng
                    ) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                        pass = false;
                    }
                });
                // check if the input address coord falls into the polygon areas
                polygonCoords.forEach((coord) => {
                    let count = 0;
                    //check last polyline
                    if (
                        (coord.coords[0].lat > checkLat &&
                            coord.coords[coord.coords.length - 1].lat < checkLat) ||
                        (coord.coords[0].lat < checkLat &&
                            coord.coords[coord.coords.length - 1].lat > checkLat)
                    ) {
                        const slope =
                            (coord.coords[coord.coords.length - 1].lat - coord.coords[0].lat) /
                            (coord.coords[coord.coords.length - 1].lng - coord.coords[0].lng);
                        const intercept =
                            coord.coords[coord.coords.length - 1].lat -
                            slope * coord.coords[coord.coords.length - 1].lng;
                        const cross = (checkLat - intercept) / slope;
                        if (cross > checkLng) {
                            count = count + 1;
                        }
                    }
                    for (let i = 0; i < coord.coords.length - 1; i++) {
                        if (
                            (coord.coords[i].lat > checkLat &&
                                coord.coords[i + 1].lat < checkLat) ||
                            (coord.coords[i].lat < checkLat && coord.coords[i + 1].lat > checkLat)
                        ) {
                            const slope =
                                (coord.coords[i + 1].lat - coord.coords[i].lat) /
                                (coord.coords[i + 1].lng - coord.coords[i].lng);
                            const intercept =
                                coord.coords[i + 1].lat - slope * coord.coords[i + 1].lng;
                            const cross = (checkLat - intercept) / slope;
                            if (cross > checkLng) {
                                count = count + 1;
                            }
                        }
                    }

                    if (count % 2 === 1) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                        pass = false;
                    }
                });
                return pass;
            })
            .then((pass) => {
                if (pass) {
                    appStore.update((s) => {
                        s.addressPass = true;
                    });
                }
                if (!permitForm.type) {
                    toggleStore.update((s) => {
                        s.typeEmptyToggle = true;
                    });
                } else {
                    toggleStore.update((s) => {
                        s.typeEmptyToggle = false;
                    });
                }

                if (validateEmail(permitForm.email) === null) {
                    toggleStore.update((s) => {
                        s.emailFailToggle = true;
                    });
                } else {
                    toggleStore.update((s) => {
                        s.emailFailToggle = false;
                    });
                }

                if (permitForm.phone.length !== 11) {
                    toggleStore.update((s) => {
                        s.phoneFailToggle = true;
                    });
                } else {
                    toggleStore.update((s) => {
                        s.phoneFailToggle = false;
                    });
                }

                if (
                    pass &&
                    permitForm.type &&
                    permitForm.phone.length === 11 &&
                    validateEmail(permitForm.email)
                ) {
                    let testAddress;
                    if (userInfo.address) {
                        testAddress = userInfo.address;
                    }
                    if (permitForm.address) {
                        testAddress = permitForm.address;
                    }
                    appStore.update((s) => {
                        s.loadingSpinner = true;
                    });
                    document.body.style.cursor = 'wait';
                    appStore.update((s) => {
                        s.addressPass = true;
                    });
                    axios
                        .get('https://maps.googleapis.com/maps/api/geocode/json', {
                            params: {
                                address: testAddress,
                                key: mapsAPI,
                            },
                        })
                        .then((response) => {
                            let burnCoord = {
                                lat: response.data.results[0].geometry.location.lat,
                                lng: response.data.results[0].geometry.location.lng,
                            };
                            fetchPermitTypeByName(permitForm.type).then((priceId) => {
                                if (currentPermitId === '') {
                                    if (!paid) {
                                        addPermit(
                                            permitForm,
                                            userInfo,
                                            testAddress,
                                            burnCoord,
                                            priceId
                                        ).then(async (permit) => {
                                            if (permitForm.type === 'free') {
                                                sendPermitEmailToAdmin(permit);
                                            }
                                            permit = { ...permit, subscribed: subscribeToggle };

                                            const stripe = await loadStripe(
                                                'pk_live_51KEN5sIiEock6T4NDeYtHGtuCNI81lBI5zWOzKavppVS4nOOVVTYYOKs30xYmVryMIZmv2wu1YBZYZHgY4v583Jm001xXdQSFz'
                                            );

                                            createSubCheckout({
                                                ...permit,
                                                url: window.location.origin,
                                            }).then((response) => {
                                                const sessionId = response.data;
                                                stripe.redirectToCheckout({
                                                    sessionId: sessionId,
                                                });
                                            });
                                        });
                                    } else {
                                        const id = 'pp_' + Date.now();
                                        setPermit(
                                            id,
                                            permitForm,
                                            userInfo,
                                            testAddress,
                                            burnCoord,
                                            priceId,
                                            paid
                                        );

                                        setTimeout(() => {
                                            appStore.update((s) => {
                                                s.loadingSpinner = false;
                                            });
                                            window.location.href = '/permit/' + id;
                                        }, 5000);
                                    }
                                } else {
                                    updatePermit(permitForm, currentPermitId, burnCoord);
                                }
                            });
                        });
                }
            })
            .catch((err) => {
                if (err.message.includes('400')) {
                    toggleStore.update((s) => {
                        s.addressEmptyToggle = true;
                    });
                }

                if (validateEmail(permitForm.email) === null) {
                    toggleStore.update((s) => {
                        s.emailFailToggle = true;
                    });
                } else {
                    toggleStore.update((s) => {
                        s.emailFailToggle = false;
                    });
                }
                if (permitForm.phone.length !== 11) {
                    toggleStore.update((s) => {
                        s.phoneFailToggle = true;
                    });
                } else {
                    toggleStore.update((s) => {
                        s.phoneFailToggle = false;
                    });
                }
                if (!permitForm.type) {
                    toggleStore.update((s) => {
                        s.typeEmptyToggle = true;
                    });
                } else {
                    toggleStore.update((s) => {
                        s.typeEmptyToggle = false;
                    });
                }
            });
    };

    const editAddress = () => {
        toggleStore.update((s) => {
            s.permitAddressToggle = !permitAddressToggle;
            s.userAddressInput = !userAddressInput;
        });
    };

    const openManualAddress = () => {
        toggleStore.update((s) => {
            s.userManualAddressInput = true;
        });
    };
    const closeManualAddress = () => {
        toggleStore.update((s) => {
            s.userManualAddressInput = false;
        });
    };

    const updateAddress = (value, field) => {
        addressFormStore.update((s) => {
            s.addressForm = { ...s.addressForm, [field]: value };
        });
    };

    //when manual address input changes it also updates auto suggest input
    permitFormStore.update((s) => {
        s.permitForm = {
            ...s.permitForm,
            address: `${addressForm.address}${', '}${addressForm.city}${', '}${
                addressForm.province
            }`,
        };
    });
    React.useEffect(() => {
        console.log(permitForm.address);
    }, [permitForm.address]);
    const updateSubscribeToggle = () => {
        toggleStore.update((s) => {
            s.subscribeToggle = !subscribeToggle;
        });
    };
    if (userId) {
        return (
            <div>
                <IonCard>
                    <IonGrid className='permit-form-grid'>
                        <IonRow>
                            <IonCol className='permit-form-col' size='6'>
                                {' '}
                                <IonItem>
                                    <IonLabel position='stacked'>Type:</IonLabel>{' '}
                                    <IonSelect
                                        required
                                        placeholder='type'
                                        value={permitForm.type}
                                        okText='Okay'
                                        cancelText='Cancel'
                                        onIonChange={(e) => {
                                            updateFormDoc(e.target.value, 'type');
                                        }}
                                    >
                                        {permitTypes &&
                                            permitTypes.map((type) => (
                                                <IonSelectOption key={type.id} value={type.name}>
                                                    {type.name}
                                                </IonSelectOption>
                                            ))}
                                    </IonSelect>
                                </IonItem>
                                <p className={typeEmptyToggle ? 'error-msg' : 'hide-error-msg'}>
                                    Please select a type{' '}
                                </p>
                            </IonCol>

                            <IonCol className='permit-form-col' size='6'>
                                <IonItem>
                                    <IonLabel position='stacked'>Subscribe:</IonLabel>{' '}
                                    <div className='subscribe-toggle'>
                                        <IonToggle
                                            onIonChange={updateSubscribeToggle}
                                            checked={subscribeToggle}
                                        />
                                    </div>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        {!userAddressInput && (
                            <IonRow>
                                <IonCol className='permit-form-col' size='11'>
                                    <IonItem>
                                        <IonLabel position='stacked'>
                                            Address: {userInfo.address}
                                        </IonLabel>
                                    </IonItem>

                                    {!permitAddressToggle && (
                                        <p id='user-address-btn' onClick={editAddress}>
                                            Use other address
                                        </p>
                                    )}

                                    <p
                                        className={
                                            addressEmptyToggle ? 'error-msg' : 'hide-error-msg'
                                        }
                                    >
                                        Please enter an address{' '}
                                    </p>
                                    <p className={!addressPass ? 'error-msg' : 'hide-error-msg'}>
                                        The address you entered falls in the restricted area{' '}
                                    </p>
                                </IonCol>
                            </IonRow>
                        )}
                        {userAddressInput && !userManualAddressInput && (
                            <>
                                <IonRow>
                                    <IonCol className='permit-form-col' size='11'>
                                        {permitAddressToggle && (
                                            <p id='user-address-btn' onClick={openManualAddress}>
                                                Manually enter address
                                            </p>
                                        )}
                                        <p
                                            className={
                                                addressEmptyToggle ? 'error-msg' : 'hide-error-msg'
                                            }
                                        >
                                            Please enter an address{' '}
                                        </p>
                                        <p
                                            className={
                                                !addressPass ? 'error-msg' : 'hide-error-msg'
                                            }
                                        >
                                            The address you entered falls in the restricted area{' '}
                                        </p>
                                    </IonCol>
                                </IonRow>
                                <div className='address-search'>
                                    <GooglePlacesAutocomplete
                                        selectProps={{
                                            placeholder: 'Search Address...',
                                            onChange: (e) => {
                                                // handleChange(e.label);
                                                console.log(e.label);
                                            },
                                        }}
                                        autocompletionRequest={{
                                            bounds: [
                                                {
                                                    lat: settingsForm.boundCoord.s,
                                                    lng: settingsForm.boundCoord.w,
                                                },
                                                {
                                                    lat: settingsForm.boundCoord.n,
                                                    lng: settingsForm.boundCoord.e,
                                                },
                                            ],
                                            componentRestrictions: {
                                                country: ['ca'],
                                            },
                                            strictBounds: true,
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        <br />
                        {userManualAddressInput && (
                            <>
                                <IonRow>
                                    <IonCol className='permit-form-col' size='11'>
                                        {permitAddressToggle && (
                                            <p id='user-address-btn' onClick={closeManualAddress}>
                                                Search address
                                            </p>
                                        )}
                                        <p
                                            className={
                                                addressEmptyToggle ? 'error-msg' : 'hide-error-msg'
                                            }
                                        >
                                            Please enter an address{' '}
                                        </p>
                                        <p
                                            className={
                                                !addressPass ? 'error-msg' : 'hide-error-msg'
                                            }
                                        >
                                            The address you entered falls in the restricted area{' '}
                                        </p>
                                    </IonCol>
                                </IonRow>
                                <div className='address-form'>
                                    <IonRow>
                                        <IonCol className='permit-form-col' size='10'>
                                            <IonItem>
                                                <IonLabel>Address:</IonLabel>{' '}
                                                <IonInput
                                                    value={addressForm.address}
                                                    onIonChange={(e) => {
                                                        updateAddress(e.target.value, 'address');
                                                    }}
                                                    required
                                                    placeholder='Enter Address'
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className='permit-form-col' size='10'>
                                            <IonItem>
                                                <IonLabel>City:</IonLabel>{' '}
                                                <IonInput
                                                    value={addressForm.city}
                                                    onIonChange={(e) => {
                                                        updateAddress(e.target.value, 'city');
                                                    }}
                                                    required
                                                    placeholder='Enter City'
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                    <IonRow>
                                        <IonCol className='permit-form-col' size='10'>
                                            <IonItem>
                                                <IonLabel>Province:</IonLabel>{' '}
                                                <IonInput
                                                    value={addressForm.province}
                                                    onIonChange={(e) => {
                                                        updateAddress(e.target.value, 'province');
                                                    }}
                                                    required
                                                    placeholder='Enter Province'
                                                />
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                </div>
                            </>
                        )}
                        <div style={{ height: 40 }} />
                        <div className='form-btns'>
                            <IonButton
                                onClick={submitPermitForm}
                                disabled={
                                    !addressForm.address ||
                                    !addressForm.address ||
                                    !addressForm.address
                                }
                            >
                                {permitFormToggle && 'Update'}
                                {subscribeToggle && !permitFormToggle && 'Subscribe'}
                                {!subscribeToggle && !permitFormToggle && 'Pay once'}
                            </IonButton>
                        </div>
                        <div style={{ height: 40 }} />
                    </IonGrid>
                </IonCard>
            </div>
        );
    }
    return (
        <div>
            <IonCard>
                <IonGrid className='permit-form-grid'>
                    <IonRow>
                        <IonCol className='permit-form-col' size='6'>
                            {' '}
                            <IonItem>
                                <IonLabel position='stacked'>First Name:</IonLabel>
                                <IonInput
                                    autoComplete='autocomplete_off_hack_xfr4!k'
                                    value={permitForm.first}
                                    onIonChange={(e) => {
                                        updateFormDoc(e.target.value, 'first');
                                    }}
                                    required
                                    placeholder='Enter first name'
                                />
                            </IonItem>
                        </IonCol>

                        <IonCol className='permit-form-col' size='6'>
                            {' '}
                            <IonItem>
                                <IonLabel position='stacked'>Last Name:</IonLabel>{' '}
                                <IonInput
                                    autoComplete='autocomplete_off_hack_xfr4!k'
                                    value={permitForm.last}
                                    onIonChange={(e) => {
                                        updateFormDoc(e.target.value, 'last');
                                    }}
                                    required
                                    placeholder='Enter last name'
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        {/* check here email not done */}
                        <IonCol className='permit-form-col' size='6'>
                            {' '}
                            <IonItem>
                                <IonLabel position='stacked'>Email:</IonLabel>{' '}
                                <IonInput
                                    autoComplete='autocomplete_off_hack_xfr4!k'
                                    value={permitForm.email}
                                    onIonChange={(e) => {
                                        updateFormDoc(e.target.value, 'email');
                                    }}
                                    required
                                    placeholder='Enter email'
                                />
                            </IonItem>
                            <p className={emailFailToggle ? 'error-msg' : 'hide-error-msg'}>
                                Please enter a valid email{' '}
                            </p>
                        </IonCol>
                        <IonCol className='permit-form-col' size='6'>
                            {' '}
                            <IonItem>
                                <IonLabel position='stacked'>Phone Number:</IonLabel>{' '}
                                <PhoneInput
                                    id='phone-input'
                                    inputClass='phone-input'
                                    country='ca'
                                    value={permitForm.phone}
                                    onChange={(e) => {
                                        updateFormDoc(e, 'phone');
                                    }}
                                />
                            </IonItem>
                            <p className={phoneFailToggle ? 'error-msg' : 'hide-error-msg'}>
                                Please enter a valid number{' '}
                            </p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol className='permit-form-col' size='6'>
                            {' '}
                            <IonItem>
                                <IonLabel position='stacked'>Type:</IonLabel>{' '}
                                <IonSelect
                                    required
                                    placeholder='type'
                                    value={permitForm.type}
                                    okText='Okay'
                                    cancelText='Cancel'
                                    onIonChange={(e) => {
                                        updateFormDoc(e.target.value, 'type');
                                    }}
                                >
                                    {permitTypes &&
                                        permitTypes.map((type) => (
                                            <IonSelectOption key={type.id} value={type.name}>
                                                {type.name}
                                            </IonSelectOption>
                                        ))}
                                </IonSelect>
                            </IonItem>
                            <p className={typeEmptyToggle ? 'error-msg' : 'hide-error-msg'}>
                                Please select a type{' '}
                            </p>
                        </IonCol>
                        {!permitFormToggle && (
                            <IonCol className='permit-form-col' size='6'>
                                <IonItem>
                                    <IonLabel position='stacked'>Subscribe?:</IonLabel>{' '}
                                    <div className='subscribe-toggle'>
                                        <IonToggle
                                            onIonChange={updateSubscribeToggle}
                                            checked={subscribeToggle}
                                        />
                                    </div>
                                </IonItem>
                            </IonCol>
                        )}
                    </IonRow>
                    <IonRow>
                        <IonCol className='permit-form-col' size='11'>
                            <IonItem lines='none'>
                                <IonLabel position='stacked'>Address:</IonLabel>{' '}
                            </IonItem>
                            <p className={addressEmptyToggle ? 'error-msg' : 'hide-error-msg'}>
                                Please enter an address{' '}
                            </p>
                            <p className={!addressPass ? 'error-msg' : 'hide-error-msg'}>
                                The address you entered falls in the restricted area{' '}
                            </p>
                        </IonCol>
                    </IonRow>
                    <br />
                    {!permitAddressToggle && (
                        <div className='address-search'>
                            <LoadScript
                                id='script-loader'
                                googleMapsApiKey={`${mapsAPI}&libraries=drawing,places`}
                                language='en'
                                region='us'
                            >
                                <GooglePlacesAutocomplete
                                    selectProps={{
                                        placeholder: 'Search Address...',
                                        onChange: (e) => {
                                            handleChange(e.label);
                                        },
                                    }}
                                    autocompletionRequest={{
                                        bounds: [
                                            { lat: 45.327308, lng: -80.063932 },
                                            { lat: 45.373881, lng: -80.001123 },
                                        ],
                                        componentRestrictions: {
                                            country: ['ca'],
                                        },
                                        strictBounds: true,
                                    }}
                                />
                            </LoadScript>
                        </div>
                    )}
                    {permitAddressToggle && (
                        <div className='address-form'>
                            <IonRow>
                                <IonCol className='permit-form-col' size='10'>
                                    <IonItem>
                                        <IonLabel position='stacked'>Address:</IonLabel>{' '}
                                        <IonInput
                                            value={addressForm.address}
                                            onIonChange={(e) => {
                                                updateAddress(e.target.value, 'address');
                                            }}
                                            required
                                            placeholder='Enter Address'
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className='permit-form-col' size='10'>
                                    <IonItem>
                                        <IonLabel position='stacked'>City:</IonLabel>{' '}
                                        <IonInput
                                            value={addressForm.city}
                                            onIonChange={(e) => {
                                                updateAddress(e.target.value, 'city');
                                            }}
                                            required
                                            placeholder='Enter City'
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className='permit-form-col' size='10'>
                                    <IonItem>
                                        <IonLabel position='stacked'>Province:</IonLabel>{' '}
                                        <IonInput
                                            value={addressForm.province}
                                            onIonChange={(e) => {
                                                updateAddress(e.target.value, 'province');
                                            }}
                                            required
                                            placeholder='Enter Province'
                                        />
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </div>
                    )}
                    {permitAddressToggle && (
                        <p
                            className='ion-padding-horizontal'
                            id='address-btn'
                            onClick={editAddress}
                        >
                            Search address
                        </p>
                    )}
                    {!permitAddressToggle && (
                        <p
                            className='ion-padding-horizontal'
                            id='address-btn'
                            onClick={editAddress}
                        >
                            Manually edit address
                        </p>
                    )}
                    <div style={{ height: 40 }} />
                    <div className='form-btns'>
                        <IonButton
                            onClick={submitPermitForm}
                            disabled={
                                !addressForm.address || !addressForm.address || !addressForm.address
                            }
                        >
                            {permitFormToggle && 'Update'}
                            {subscribeToggle && !permitFormToggle && 'Subscribe'}
                            {!subscribeToggle && !permitFormToggle && 'Pay once'}
                        </IonButton>
                        {!subscribeToggle && !permitFormToggle ? (
                            <IonButton
                                onClick={submitPermitFormNoPayment}
                                disabled={
                                    !addressForm.address ||
                                    !addressForm.address ||
                                    !addressForm.address
                                }
                            >
                                {'Continue without payment'}
                            </IonButton>
                        ) : null}
                    </div>
                    <div style={{ height: 40 }} />
                </IonGrid>
            </IonCard>
        </div>
    );
}

export default PermitForm;
