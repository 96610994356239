import { appStore, toggleStore, permitsStore } from '../../store/Store';
import { useEffect } from 'react';
import { IonAlert, IonIcon } from '@ionic/react';
import { radioButtonOn } from 'ionicons/icons';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import '../../theme/Activity.css';
import {
    fetchPermitTypes,
    disablePermitTypeData,
    unarchiveProduct,
    archiveProduct,
} from '../../firebaseConfig';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
function AdminTypeList() {
    let theme = createTheme();
    theme = responsiveFontSizes(theme);
    const columns = ['Name', 'Price', 'Description', 'Valid(day)', 'Active'];
    const currentPermitTypeId = permitsStore.useState((s) => s.currentPermitTypeId);
    const archiveLoading = appStore.useState((s) => s.archiveLoading);
    const permitTypes = permitsStore.useState((s) => s.permitTypes);
    const tempPermitTypes = permitsStore.useState((s) => s.tempPermitTypes);
    const deletePermitTypeToggle = toggleStore.useState((s) => s.deletePermitTypeToggle);
    const unarchiveToggle = toggleStore.useState((s) => s.unarchiveToggle);
    useEffect(() => {
        fetchPermitTypes().then((data) => {
            const finalDataArr = [];
            data.map((permitType) => {
                let disableBtn;
                if (permitType.active) {
                    disableBtn = (
                        <IonIcon
                            onClick={() => {
                                deletePermitType(permitType);
                            }}
                            style={{ color: 'green' }}
                            icon={radioButtonOn}
                        />
                    );
                } else {
                    disableBtn = (
                        <IonIcon
                            onClick={() => {
                                activatePermitType(permitType);
                            }}
                            style={{ color: 'red' }}
                            icon={radioButtonOn}
                        />
                    );
                }
                const permitTypeArr = [];
                permitTypeArr.push(
                    permitType.name,
                    permitType.cost,
                    permitType.desc,
                    permitType.valid,
                    disableBtn
                );
                finalDataArr.push(permitTypeArr);
            });
            permitsStore.update((s) => {
                s.tempPermitTypes = finalDataArr;
            });
            permitsStore.update((s) => {
                s.permitTypes = data;
            });
        });
    }, []);

    const deletePermitType = (permitType) => {
        toggleStore.update((s) => {
            s.deletePermitTypeToggle = true;
        });
        permitsStore.update((s) => {
            s.currentPermitTypeId = permitType.id;
        });
    };
    const activatePermitType = (permitType) => {
        toggleStore.update((s) => {
            s.unarchiveToggle = true;
        });
        permitsStore.update((s) => {
            s.currentPermitTypeId = permitType.id;
        });
    };
    const closeDeleteAlert = () => {
        toggleStore.update((s) => {
            s.deletePermitTypeToggle = false;
        });
        permitsStore.update((s) => {
            s.currentPermitTypeId = '';
        });
    };
    const closeUnarchiveAlert = () => {
        toggleStore.update((s) => {
            s.unarchiveToggle = false;
        });
        permitsStore.update((s) => {
            s.currentPermitTypeId = '';
        });
    };

    const deletePermitTypeWithID = async () => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });

        await disablePermitTypeData(currentPermitTypeId);

        const tempPermitType = permitTypes.filter(
            (permitType) => permitType.id == currentPermitTypeId
        );

        await archiveProduct(tempPermitType[0]);

        window.location.href = '/permit-types';

        const tempPermitTypes = permitTypes.filter(
            (permitType) => permitType.id !== currentPermitTypeId
        );
        permitsStore.update((s) => {
            s.permitTypes = tempPermitTypes;
        });
        permitsStore.update((s) => {
            s.currentPermitTypeId = '';
        });
    };

    const unarchivePermitTypeWithID = async () => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });

        await disablePermitTypeData(currentPermitTypeId);

        const tempPermitType = permitTypes.filter(
            (permitType) => permitType.id == currentPermitTypeId
        );

        await unarchiveProduct(tempPermitType[0]);

        window.location.href = '/permit-types';

        const tempPermitTypes = permitTypes.filter(
            (permitType) => permitType.id !== currentPermitTypeId
        );
        permitsStore.update((s) => {
            s.permitTypes = tempPermitTypes;
        });
        permitsStore.update((s) => {
            s.currentPermitTypeId = '';
        });
    };

    return (
        <>
            {archiveLoading && (
                <div className='spinner'>
                    <div className='in-spinner'>
                        <Loader type='TailSpin' color='#00BFFF' height={80} width={80} />
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Processing your request...</p>
                    </div>
                </div>
            )}

            {tempPermitTypes && !archiveLoading && (
                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        title={'Permit Types'}
                        data={tempPermitTypes}
                        columns={columns}
                        options={{
                            selectableRows: 'none', // <===== will turn off checkboxes in rows
                            print: false,
                            viewColumns: false,
                        }}
                    />
                </ThemeProvider>
            )}
            <IonAlert
                isOpen={deletePermitTypeToggle}
                onDidDismiss={closeDeleteAlert}
                header={'Confirm'}
                message={'Are you sure you want disable this permit type?'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Disable',

                        handler: () => {
                            deletePermitTypeWithID();
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={unarchiveToggle}
                onDidDismiss={closeUnarchiveAlert}
                header={'Confirm'}
                message={'Are you sure you want enable this permit type?'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Enable',

                        handler: () => {
                            unarchivePermitTypeWithID();
                        },
                    },
                ]}
            />
        </>
    );
}

export default AdminTypeList;
