import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
} from '@ionic/react';
import '../../theme/Home.css';
import { modalToggleStore } from '../../store/Store';
import NoPwProfile1 from './ModalPages/NoPwProfile1';
import '../../theme/NoPwProfile.css';

function NoPwRegisterModal() {
    const profilePage = modalToggleStore.useState((s) => s.profilePage);

    return (
        <IonModal isOpen={true} backdrop-dismiss={false}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>
                        <div className='no-pw-header'>
                            <span className='no-pw-header-text'>Sign Up For Notifications</span>
                        </div>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            {profilePage === 0 && <NoPwProfile1 />}
        </IonModal>
    );
}

export default NoPwRegisterModal;
