import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonLoading,
} from '@ionic/react';
import { menu } from 'ionicons/icons';
import { appStore } from '../../store/Store';
import '../../theme/LandingPage.css';
import WidgetLanding from '../../components/common/WidgetLanding';
function WidgetLandingPage() {
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);

    return (
        <IonApp>
            {' '}
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot='start'>
                        <IonMenuToggle>
                            <IonButton>
                                {' '}
                                <IonIcon slot='icon-only' icon={menu}></IonIcon>
                            </IonButton>
                        </IonMenuToggle>
                    </IonButtons>
                    <IonTitle>
                        {' '}
                        <div className='landing-title'>
                            <span className='landing-title-text'>
                                Widgets Subscription - FirePermits.Online
                            </span>
                        </div>
                    </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className='ion-padding'>
                <IonLoading isOpen={loadingSpinner} message={'Processing...'} duration={50000} />
                <WidgetLanding />
            </IonContent>{' '}
        </IonApp>
    );
}

export default WidgetLandingPage;
