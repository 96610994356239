import {
    IonModal,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonIcon,
    IonTitle,
} from '@ionic/react';
import '../../theme/Home.css';
import { modalToggleStore } from '../../store/Store';
import Profile1 from './ModalPages/Profile1';
import Profile2 from './ModalPages/Profile2';
import Profile3 from './ModalPages/Profile3';
import Profile4 from './ModalPages/Profile4';
import Profile5 from './ModalPages/Profile5';
import { chevronBackOutline, closeOutline } from 'ionicons/icons';
function ProfileModal() {
    const profilePage = modalToggleStore.useState((s) => s.profilePage);
    const closeModal = () => {
        modalToggleStore.update((s) => {
            s.profilePage = 0;
        });
    };
    const updateStep = (profilePage) => {
        if (profilePage !== 0) {
            modalToggleStore.update((s) => {
                s.profilePage = s.profilePage + profilePage;
            });
        } else {
            modalToggleStore.update((s) => {
                s.profilePage = 0;
            });
        }
    };

    return (
        <IonModal isOpen={profilePage > 0} onDidDismiss={closeModal}>
            <IonHeader>
                <IonToolbar>
                    {profilePage > 1 ? (
                        <IonButtons slot='start'>
                            {profilePage !== 7 ? (
                                <IonButton
                                    style={{ color: '#3880ff' }}
                                    onClick={() => updateStep(-1)}
                                >
                                    <IonIcon icon={chevronBackOutline} slot='start' /> Back
                                </IonButton>
                            ) : null}
                        </IonButtons>
                    ) : null}
                    <IonTitle className={profilePage > 1 ? 'back' : 'no-back'}>
                        Step {profilePage}
                    </IonTitle>
                    <IonButtons slot='end'>
                        <IonButton style={{ color: '#3880ff' }} onClick={closeModal}>
                            <IonIcon icon={closeOutline} slot='icon-only' />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            {profilePage === 1 && <Profile1 />}
            {profilePage === 2 && <Profile2 />}
            {profilePage === 3 && <Profile3 />}
            {profilePage === 4 && <Profile4 />}
            {profilePage === 5 && <Profile5 />}
        </IonModal>
    );
}

export default ProfileModal;
