import {} from '../../store/Store';
import '../../theme/PermitTypeForm.css';
import {
    IonButton,
    IonInput,
    IonText,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonCol,
    IonRow,
    IonItem,
    IonCard,
    IonLabel,
} from '@ionic/react';
import { appStore, toggleStore, permitTypeFormStore, permitsStore } from '../../store/Store';
import {
    addPermitType,
    updatePermitType,
    addPriceToPermitType,
    addStripeProduct,
} from '../../firebaseConfig';
function PermitTypeForm() {
    const typeValidEmptyToggle = toggleStore.useState((s) => s.typeValidEmptyToggle);
    const permitTypeForm = permitTypeFormStore.useState((s) => s.permitTypeForm);
    const currentPermitTypeId = permitsStore.useState((s) => s.currentPermitTypeId);
    const updateFormDoc = (value, field) => {
        permitTypeFormStore.update((s) => {
            s.permitTypeForm = { ...s.permitTypeForm, [field]: value };
        });
    };

    const back = () => {
        toggleStore.update((s) => {
            s.permitTypeFormToggle = false;
            s.allPermitTypesToggle = false;
        });
        permitsStore.update((s) => {
            s.currentPermitTypeId = '';
        });
        permitTypeFormStore.update((s) => {
            s.permitTypeForm = {
                name: '',
                cost: '',
                desc: '',
                valid: '',
            };
        });
    };

    const submitPermitForm = async (e) => {
        e.preventDefault();
        if (!permitTypeForm.valid) {
            toggleStore.update((s) => {
                s.typeValidEmptyToggle = true;
            });
        } else {
            toggleStore.update((s) => {
                s.typeValidEmptyToggle = false;
            });
            appStore.update((s) => {
                s.loadingSpinner = true;
            });

            if (currentPermitTypeId === '') {
                addPermitType(permitTypeForm).then(async (id) => {
                    const newPermitTypeForm = { ...permitTypeForm, permitTypeId: id };
                    await addStripeProduct(newPermitTypeForm);
                    await addPriceToPermitType(id);
                });
            } else {
                await updatePermitType(permitTypeForm, currentPermitTypeId);
                window.location.href = '/permit-types';
            }
        }
    };

    return (
        <div>
            <form className='permit-form' onSubmit={submitPermitForm}>
                <IonText>
                    <h1>Add New Permit Type</h1>
                </IonText>

                <br />
                <br />
                <IonCard>
                    <IonGrid className='permit-form-grid'>
                        <IonRow>
                            <IonCol size='6'>
                                <IonItem>
                                    <IonLabel position='stacked'>Name:</IonLabel>{' '}
                                    <IonInput
                                        onIonChange={(e) => {
                                            updateFormDoc(e.target.value, 'name');
                                        }}
                                        value={permitTypeForm.name}
                                        required
                                        placeholder='Enter name'
                                    />
                                </IonItem>{' '}
                            </IonCol>
                            <IonCol size='6'>
                                {' '}
                                <IonItem>
                                    <IonLabel position='stacked'>Cost:</IonLabel>{' '}
                                    <IonInput
                                        onIonChange={(e) => {
                                            updateFormDoc(e.target.value, 'cost');
                                        }}
                                        value={permitTypeForm.cost}
                                        required
                                        placeholder='Enter price'
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol size='6'>
                                {' '}
                                <IonItem>
                                    <IonLabel position='stacked'>Description:</IonLabel>{' '}
                                    <IonInput
                                        onIonChange={(e) => {
                                            updateFormDoc(e.target.value, 'desc');
                                        }}
                                        value={permitTypeForm.desc}
                                        required
                                        placeholder='Describe permit type'
                                    />
                                </IonItem>
                            </IonCol>
                            <IonCol size='6'>
                                {' '}
                                <IonItem>
                                    <IonLabel position='stacked'>Valid For:</IonLabel>{' '}
                                    <IonSelect
                                        required
                                        placeholder='valid'
                                        value={permitTypeForm.valid}
                                        okText='Okay'
                                        cancelText='Cancel'
                                        onIonChange={(e) => {
                                            updateFormDoc(e.target.value, 'valid');
                                        }}
                                    >
                                        <IonSelectOption value='30'>1 month</IonSelectOption>
                                        <IonSelectOption value='365'>1 year</IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <p
                                    className={
                                        typeValidEmptyToggle ? 'error-msg' : 'hide-error-msg'
                                    }
                                >
                                    Please choose how many days this permit type will last{' '}
                                </p>
                            </IonCol>
                        </IonRow>
                        <br />
                        <div className='form-btns'>
                            <IonButton type='submit' id='permit-submit'>
                                Submit
                            </IonButton>{' '}
                            <IonButton onClick={back} id='permit-submit'>
                                Back
                            </IonButton>
                        </div>
                        <br />
                    </IonGrid>
                </IonCard>
            </form>
        </div>
    );
}

export default PermitTypeForm;
