import { IonLabel, IonButton, IonRadioGroup, IonItem, IonRadio, IonBadge } from '@ionic/react';
import '../../../theme/Home.css';
import { modalToggleStore, permitFormStore, permitsStore } from '../../../store/Store';
const ApplyPermit2 = () => {
    const permitForm = permitFormStore.useState((s) => s.permitForm);
    const permitTypes = permitsStore.useState((s) => s.permitTypes);
    const permitTypeEmpty = modalToggleStore.useState((s) => s.permitTypeEmpty);

    const updatePermitForm = (value, field) => {
        permitFormStore.update((s) => {
            s.permitForm = { ...s.permitForm, [field]: value };
        });
    };

    const setCurrentTypeValid = (valid) => {
        permitsStore.update((s) => {
            s.currentTypeValid = valid;
        });
        modalToggleStore.update((s) => {
            s.permitTypeEmpty = false;
        });
    };

    const openThirdPage = () => {
        modalToggleStore.update((s) => {
            s.permitPage = 3;
        });
    };

    return (
        <>
            <h2 className='ion-padding'>Choose Permit Type</h2>
            <br />
            <IonRadioGroup
                className='permit-type-item'
                value={permitForm.type}
                onIonChange={(e) => updatePermitForm(e.detail.value, 'type')}
            >
                {permitTypes.length > 0 &&
                    permitTypes.map((permitType, i) => {
                        if (permitType.active) {
                            return (
                                <IonItem
                                    key={i}
                                    onClick={() => {
                                        setCurrentTypeValid(permitType.valid);
                                    }}
                                >
                                    <IonLabel className='ion-text-wrap'>
                                        {permitType.name} - {permitType.desc}
                                    </IonLabel>
                                    <IonRadio
                                        color='tertiary'
                                        slot='start'
                                        value={permitType.name}
                                    />
                                    <IonBadge style={{ background: '#3880ff' }} slot='end'>
                                        ${permitType.cost} /&nbsp;
                                        {permitType.valid === '30' && 'month'}
                                        {permitType.valid === '365' && 'year'}
                                    </IonBadge>
                                </IonItem>
                            );
                        }
                        return null;
                    })}
            </IonRadioGroup>
            <p className='ion-padding'>
                Please contact the Fire Chief or Fire Prevention Officer at the Parry Sound Fire
                Department @ 705-746-2262 if you are requesting approval for a fire that does not
                comply with the regulations for an outdoor fire place permit.
            </p>
            <br />
            <IonButton
                className='ion-padding'
                shape='round'
                expand='block'
                color='tertiary'
                disabled={permitTypeEmpty}
                onClick={openThirdPage}
            >
                Continue
            </IonButton>
        </>
    );
};

export default ApplyPermit2;
