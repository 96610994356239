import { IonLabel, IonToggle, IonButton, IonItem } from '@ionic/react';
import '../../../theme/Home.css';
import { modalToggleStore, toggleStore } from '../../../store/Store';
const ApplyPermit3 = () => {
    const subscribeToggle = toggleStore.useState((s) => s.subscribeToggle);

    const openFourthPage = () => {
        modalToggleStore.update((s) => {
            s.permitPage = 4;
        });
    };

    const updateSubscribeToggle = () => {
        toggleStore.update((s) => {
            s.subscribeToggle = !subscribeToggle;
        });
    };
    return (
        <>
            <h2 className='ion-padding'>Would you like to subscribe to this permit?</h2>
            <p className='ion-padding-horizontal'>
                By subscribing, we will automatically renew your permit on your behalf. You can
                cancel your subscription at any time.
            </p>
            <p className='ion-padding-horizontal'>
                If you turn off the subscription option below, your permit will not auto-renew after
                the first year. You may turn on the subscription at a later time from your "My
                Permits" page.
            </p>
            <p className='ion-padding-horizontal'>
                You are responsible for informing the Parry Sound Fire Department if you intend to
                change your burn location and you permit may be subject to another inspection.
            </p>
            <br />
            <IonItem className='sub-item'>
                <IonLabel>Subscribe:</IonLabel>{' '}
                <div className='subscribe-toggle'>
                    <IonToggle
                        color='tertiary'
                        onIonChange={updateSubscribeToggle}
                        checked={subscribeToggle}
                    />
                </div>
            </IonItem>
            <br /> <br />
            <IonButton
                className='ion-padding-horizontal'
                shape='round'
                expand='block'
                color='tertiary'
                onClick={openFourthPage}
            >
                Continue
            </IonButton>
        </>
    );
};

export default ApplyPermit3;
