import {
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonInput,
} from '@ionic/react';
import { emergencyStore, toggleStore } from '../../store/Store';
import '../../theme/LandingPageForNoPw.css';
import { noPwSignIn } from '../../firebaseConfig';
import { useParams } from 'react-router-dom';
function LandingPageForNoPw() {
    const params = useParams();
    const wrongEmailToggle = toggleStore.useState((s) => s.wrongEmailToggle);
    const emergencyEmailEmpty = toggleStore.useState((s) => s.emergencyEmailEmpty);
    const email = emergencyStore.useState((s) => s.email);
    const validateEmail = (data) => {
        return data.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const updateEmail = (e) => {
        emergencyStore.update((s) => {
            s.email = e.detail.value;
        });
    };
    const passwordlessSignIn = () => {
        if (validateEmail(email)) {
            noPwSignIn(params.city, email).then((data) => {
                toggleStore.update((s) => {
                    s.emergencyEmailEmpty = false;
                    s.wrongEmailToggle = false;
                });

                if (data.error) {
                    toggleStore.update((s) => {
                        s.wrongEmailToggle = true;
                    });
                }
            });
        } else {
            toggleStore.update((s) => {
                s.emergencyEmailEmpty = true;
            });
        }
    };
    return (
        <IonCard>
            <IonCardHeader>
                <IonCardTitle>Please enter your email to manage notifications</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <IonInput
                    onIonChange={updateEmail}
                    className='nopw-input'
                    placeholder='Enter Email'
                ></IonInput>
                <p className={emergencyEmailEmpty ? 'err' : 'hide'}>
                    &nbsp; Please enter a valid email*
                </p>
                <p className={wrongEmailToggle ? 'err' : 'hide'}>
                    &nbsp; Email incorrect. Please enter the email that you recieved the link to
                    manage notifications*
                </p>
                <IonButton onClick={passwordlessSignIn}>Submit</IonButton>
            </IonCardContent>
        </IonCard>
    );
}

export default LandingPageForNoPw;
