import { IonLabel,  IonItem, IonRange, IonIcon } from '@ionic/react';
import '../../theme/LoginToggles.css';
import {  appStore } from '../../store/Store';
import { updateRiskWidget } from '../../firebaseConfig';
import { flame } from 'ionicons/icons';

function RiskWidgetControl() {
    const userInfo = appStore.useState((s) => s.userInfo);

    const updateToggle = (value) => {
        updateRiskWidget(userInfo.id, value);
    };

    return (
        <>
            {userInfo.riskLevel && (
                <>
                    <div className='login-toggle'>
                        <IonLabel>Risk Level:</IonLabel>
                        <IonItem className='risk-level-bar'>
                            <IonRange
                                onIonChange={(e) => {
                                    updateToggle(e.detail.value);
                                }}
                                value={userInfo.riskLevel}
                                min='1'
                                max='4'
                                step='1'
                                pin
                                snaps
                                color='danger'
                            >
                                <IonIcon
                                    slot='start'
                                    size='small'
                                    color='danger'
                                    icon={flame}
                                ></IonIcon>
                                <IonIcon slot='end' color='danger' icon={flame}></IonIcon>
                            </IonRange>
                        </IonItem>
                    </div>
                </>
            )}
        </>
    );
}

export default RiskWidgetControl;
