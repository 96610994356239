import { IonList, IonItem, IonLabel, IonListHeader, IonIcon, IonToast } from '@ionic/react';
import '../../theme/Menu.css';
import { getAuth, signOut } from 'firebase/auth';
import { useEffect } from 'react';
import { home, ban, bonfire, logOut, grid, add, settings, megaphone } from 'ionicons/icons';
import { fetchCurrentDarkMode } from '../../firebaseConfig';
import {
    addressFormStore,
    appStore,
    permitFormStore,
    toastStore,
    toggleStore,
} from '../../store/Store';
function Menu() {
    const darkModeToast = toastStore.useState((s) => s.darkModeToast);
    const adminId = appStore.useState((s) => s.adminId);
    const userInfo = appStore.useState((s) => s.userInfo);
    const superAdminId = appStore.useState((s) => s.superAdminId);

    const logout = async () => {
        appStore.update((s) => {
            s.superAdminId = null;
            s.adminId = null;
            s.userId = null;
        });
        const auth = getAuth();
        await signOut(auth);
        appStore.update((s) => {
            s.darkMode = false;
        });
        await localStorage.clear();
        window.location.href = '/login';
    };
    useEffect(() => {
        if (adminId) {
            fetchCurrentDarkMode(adminId).then((data) => {
                appStore.update((s) => {
                    s.darkMode = data;
                });
            });
        }
        if (superAdminId) {
            fetchCurrentDarkMode(superAdminId).then((data) => {
                appStore.update((s) => {
                    s.darkMode = data;
                });
            });
        }
    });
    const toggleAddPermit = () => {
        toggleStore.update((s) => {
            s.permitFormToggle = false;
        });

        permitFormStore.update((s) => {
            s.permitForm = {
                first: '',
                last: '',
                phone: '',
                address: '',
                type: '',
                burnCoord: {},
                email: '',
            };
        });

        addressFormStore.update((s) => {
            s.addressForm = {
                address: '',
                city: '',
                province: '',
            };
        });
    };
    return (
        <>
            <IonList lines='none'>
                <IonListHeader>Admin: {userInfo.email}</IonListHeader>

                <IonItem routerLink='/admin' routerDirection='none'>
                    <IonIcon slot='start' icon={home}></IonIcon>
                    <IonLabel>Dashboard</IonLabel>
                </IonItem>
                <IonItem routerLink='/permits' routerDirection='none'>
                    <IonIcon slot='start' icon={bonfire}></IonIcon>
                    <IonLabel>All Permits</IonLabel>
                </IonItem>

                {superAdminId && (
                    <>
                        <IonItem
                            onClick={toggleAddPermit}
                            routerLink='/permit-form'
                            routerDirection='none'
                        >
                            <IonIcon slot='start' icon={add}></IonIcon>
                            <IonLabel>Add New Permit</IonLabel>
                        </IonItem>
                        <IonItem routerLink='/permit-types' routerDirection='none'>
                            <IonIcon slot='start' icon={grid}></IonIcon>
                            <IonLabel>Permit Types</IonLabel>
                        </IonItem>
                        <IonItem routerLink='/exclusion' routerDirection='none'>
                            <IonIcon slot='start' icon={ban}></IonIcon>
                            <IonLabel>Exclusion</IonLabel>
                        </IonItem>
                        <IonItem routerLink='/announcement' routerDirection='none'>
                            <IonIcon slot='start' icon={megaphone}></IonIcon>
                            <IonLabel>Announcements</IonLabel>
                        </IonItem>
                    </>
                )}
            </IonList>
            <IonList lines='none'>
                <IonListHeader>Account</IonListHeader>
                {superAdminId && (
                    <IonItem routerLink='/settings' routerDirection='none'>
                        <IonIcon slot='start' icon={settings}></IonIcon>
                        <IonLabel>Settings</IonLabel>
                    </IonItem>
                )}

                <IonItem button onClick={logout}>
                    <IonIcon slot='start' icon={logOut}></IonIcon>
                    <IonLabel id='logout'>Log out</IonLabel>
                </IonItem>
            </IonList>
            <IonToast
                isOpen={darkModeToast}
                onDidDismiss={() =>
                    toastStore.update((s) => {
                        s.darkModeToast = false;
                    })
                }
                message='Your dark mode settings have been saved.'
                duration={1000}
            />
        </>
    );
}

export default Menu;
