import { IonList, IonItem, IonLabel, IonListHeader, IonIcon, IonToast } from '@ionic/react';
import '../../theme/Menu.css';
import { getAuth, signOut } from 'firebase/auth';
import { useEffect } from 'react';
import { logOut, addCircle, person, helpCircle, flame } from 'ionicons/icons';
import { fetchCurrentDarkMode, fetchUser } from '../../firebaseConfig';
import { appStore, toastStore, permitFormStore } from '../../store/Store';
function UserMenu() {
    const userInfo = appStore.useState((s) => s.userInfo);
    const darkModeToast = toastStore.useState((s) => s.darkModeToast);
    const userId = appStore.useState((s) => s.userId);
    const adminId = appStore.useState((s) => s.adminId);
    const superAdminId = appStore.useState((s) => s.superAdminId);

    const clickHelp = () => {
        window.location = 'mailto:info@firepermit.online?subject=Support request from Parry Sound';
    };

    const logout = async () => {
        appStore.update((s) => {
            s.superAdminId = null;
            s.adminId = null;
            s.userId = null;
        });
        const auth = getAuth();
        await signOut(auth);
        appStore.update((s) => {
            s.darkMode = false;
        });
        await localStorage.clear();
        window.location.href = '/login';
    };

    useEffect(() => {
        if (adminId) {
            fetchCurrentDarkMode(adminId).then((data) => {
                appStore.update((s) => {
                    s.darkMode = data;
                });
            });
        }
        if (superAdminId) {
            fetchCurrentDarkMode(superAdminId).then((data) => {
                appStore.update((s) => {
                    s.darkMode = data;
                });
            });
        }
        if (userId) {
            fetchUser(userId).then((data) => {
                if (data.phone) {
                    permitFormStore.update((s) => {
                        s.permitForm = {
                            ...s.permitForm,
                            phone: data.phone.substring(1),
                            first: data.first,
                            last: data.last,
                            email: data.email,
                        };
                    });
                }
            });
        }
    }, []);
    return (
        <>
            <IonList lines='none'>
                <IonListHeader>{userInfo.email}</IonListHeader>

                <IonItem routerLink='/' routerDirection='none'>
                    <IonIcon slot='start' icon={addCircle}></IonIcon>
                    <IonLabel>Apply</IonLabel>
                </IonItem>

                <IonItem routerLink='/profile' routerDirection='none'>
                    <IonIcon slot='start' icon={person}></IonIcon>
                    <IonLabel>My Profile</IonLabel>
                </IonItem>

                <IonItem routerLink='my-permits' routerDirection='none'>
                    <IonIcon slot='start' icon={flame}></IonIcon>
                    <IonLabel>My Permits</IonLabel>
                </IonItem>
            </IonList>
            <IonList lines='none'>
                <IonListHeader>Account</IonListHeader>
                <IonItem button onClick={clickHelp}>
                    <IonIcon slot='start' icon={helpCircle}></IonIcon>
                    <a
                        className='help-tab'
                        href='mailto:info@firepermit.online?subject=Support request from Parry Sound'
                    >
                        Help
                    </a>{' '}
                </IonItem>

                <IonItem button onClick={logout}>
                    <IonIcon slot='start' icon={logOut}></IonIcon>
                    <IonLabel id='logout'>Log out</IonLabel>
                </IonItem>
            </IonList>
            <IonToast
                isOpen={darkModeToast}
                onDidDismiss={() =>
                    toastStore.update((s) => {
                        s.darkModeToast = false;
                    })
                }
                message='Your dark mode settings have been saved.'
                duration={1000}
            />
        </>
    );
}

export default UserMenu;
