import { IonLabel, IonToggle, IonItem, IonRange, IonIcon } from '@ionic/react';
import '../../theme/LoginToggles.css';
import { profileFormStore } from '../../store/Store';
import { updateLoginWidgets } from '../../firebaseConfig';
import { flame } from 'ionicons/icons';

function LandingTogglesControl() {
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);
    const riskLevel = profileFormStore.useState((s) => s.riskLevel);
    const updateToggle = (value, field) => {
        if (field === 'riskLevel') {
            profileFormStore.update((s) => {
                s.riskLevel = value;
            });
            updateLoginWidgets('parrysound', value, field);
        } else {
            profileFormStore.update((s) => {
                s.settingsForm = { ...s.settingsForm, [field]: !settingsForm[field] };
            });
            updateLoginWidgets('parrysound', settingsForm[field], field);
        }
    };

    return (
        <>
            {settingsForm.name && (
                <>
                    <div className='login-toggle'>
                        <IonLabel>Risk Level</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateToggle(e, 'riskToggle');
                            }}
                            checked={settingsForm.riskToggle}
                        />
                    </div>
                    <div className='login-toggle'>
                        <IonLabel>Risk Level:</IonLabel>
                        <IonItem className='risk-level-bar'>
                            <IonRange
                                onIonChange={(e) => {
                                    updateToggle(e.detail.value, 'riskLevel');
                                }}
                                value={riskLevel}
                                min='1'
                                max='4'
                                step='1'
                                pin
                                snaps
                                color='danger'
                            >
                                <IonIcon
                                    slot='start'
                                    size='small'
                                    color='danger'
                                    icon={flame}
                                ></IonIcon>
                                <IonIcon slot='end' color='danger' icon={flame}></IonIcon>
                            </IonRange>
                        </IonItem>
                    </div>
                    <div className='login-toggle'>
                        <IonLabel>Weather</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateToggle(e, 'weatherToggle');
                            }}
                            checked={settingsForm.weatherToggle}
                        />
                    </div>
                    <div className='login-toggle'>
                        <IonLabel>Announcements</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateToggle(e, 'announcementsToggle');
                            }}
                            checked={settingsForm.announcementsToggle}
                        />
                    </div>
                    <div className='login-toggle'>
                        <IonLabel>Contact</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateToggle(e, 'contactToggle');
                            }}
                            checked={settingsForm.contactToggle}
                        />
                    </div>
                    <div className='login-toggle'>
                        <IonLabel>Require Activate</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateToggle(e, 'activateToggle');
                            }}
                            checked={settingsForm.activateToggle}
                        />
                    </div>
                    <div className='login-toggle'>
                        <IonLabel>Burn Ban</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateToggle(e, 'burnBanToggle');
                            }}
                            checked={settingsForm.burnBanToggle}
                        />
                    </div>
                    <div className='login-toggle'>
                        <IonLabel>End Of Year Warning</IonLabel>
                        <IonToggle
                            onIonChange={(e) => {
                                updateToggle(e, 'EOYWarningToggle');
                            }}
                            checked={settingsForm.EOYWarningToggle}
                        />
                    </div>
                    <br />
                    <p>
                        <h4> Risk widget iframe code:</h4>
                        {`
                        <iframe
                            src='https://firepermitonline.web.app/risk-level/parrysound'
                            width='100%'
                            height='230'
                            title='parrysound'
                        ></iframe>
                        `}
                    </p>
                </>
            )}
        </>
    );
}

export default LandingTogglesControl;
