import { profileFormStore, riskControlStore } from '../../store/Store';
import ReactSpeedometer from 'react-d3-speedometer';
import '../../theme/RiskWidget.css';
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
} from '@ionic/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchRiskByUsername } from '../../firebaseConfig';

function GeneralRisk() {
    const riskLevel = riskControlStore.useState((s) => s.riskLevel);
    const params = useParams();
    const city = params.city;
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);
    useEffect(() => {
        fetchRiskByUsername(city).then((data) => {
            riskControlStore.update((s) => {
                s.riskLevel = data;
            });
        });
    }, []);
    const labels = [
        {
            text: 'Low',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
        },
        {
            text: 'Moderate',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
        },
        {
            text: 'High',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
        },
        {
            text: 'Extreme',
            position: 'OUTSIDE',
            // color: isDark ? '#fff' : '#555',
            color: '#555',
        },
    ];
    if (city === 'parrysound' && settingsForm) {
        return (
            <IonCard mode='ios' className='risk-widget'>
                <div
                    style={{
                        width: 'auto',
                        height: '150px',
                        margin: '20px auto 25px auto',
                    }}
                >
                    <div className='ion-text-center'>
                        <ReactSpeedometer
                            paddingVertical={10}
                            maxValue={4}
                            value={settingsForm.riskLevel - 0.5}
                            needleColor={'#5260ff'}
                            needleHeightRatio={0.45}
                            segments={4}
                            // customSegmentStops={[0, 1.5, 2.5, 3.5, 5.0]}
                            segmentColors={[
                                // "#3dc2ff",
                                '#2dd36f',
                                '#ffc409',
                                '#ff6600',
                                '#ff0000',
                            ]}
                            customSegmentLabels={labels}
                            currentValueText={`${settingsForm.riskLevel}`}
                        />
                    </div>
                </div>
                <IonCardHeader>
                    <IonCardSubtitle>Current</IonCardSubtitle>
                    <IonCardTitle>
                        Fire Risk Level: {settingsForm && settingsForm.riskLevel}
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    {settingsForm && settingsForm.riskLevel === 1 ? (
                        <p>
                            Weather and ground conditions pose a low risk to accidental fire spread.
                            Please burn responsibly.
                        </p>
                    ) : settingsForm && settingsForm.riskLevel === 2 ? (
                        <p>
                            Use care when open air burning. Local conditions pose potential for
                            fires to spread accidentally, especially on dry or windy days.
                        </p>
                    ) : settingsForm && settingsForm.riskLevel === 3 ? (
                        <p>
                            Use caution when open air burning. Dry local conditions could allow
                            fires to spread easily and be difficult to control.
                        </p>
                    ) : settingsForm && settingsForm.riskLevel === 4 ? (
                        <p>
                            Use extreme caution. Fires could spread quickly and beyond ability to
                            control. Postponing open air burning is recommended.
                        </p>
                    ) : null}
                </IonCardContent>
            </IonCard>
        );
    } else if (riskLevel) {
        return (
            <IonCard mode='ios' className='risk-widget'>
                <div
                    style={{
                        width: 'auto',
                        height: '150px',
                        margin: '20px auto 25px auto',
                    }}
                >
                    <div className='ion-text-center'>
                        <ReactSpeedometer
                            paddingVertical={10}
                            maxValue={4}
                            value={riskLevel - 0.5}
                            needleColor={'#5260ff'}
                            needleHeightRatio={0.45}
                            segments={4}
                            // customSegmentStops={[0, 1.5, 2.5, 3.5, 5.0]}
                            segmentColors={[
                                // "#3dc2ff",
                                '#2dd36f',
                                '#ffc409',
                                '#ff6600',
                                '#ff0000',
                            ]}
                            customSegmentLabels={labels}
                            currentValueText={`${riskLevel}`}
                        />
                    </div>
                </div>
                <IonCardHeader>
                    <IonCardSubtitle>Current</IonCardSubtitle>
                    <IonCardTitle>Fire Risk Level: {riskLevel}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    {riskLevel === 1 ? (
                        <p>
                            Weather and ground conditions pose a low risk to accidental fire spread.
                            Please burn responsibly.
                        </p>
                    ) : riskLevel === 2 ? (
                        <p>
                            Use care when open air burning. Local conditions pose potential for
                            fires to spread accidentally, especially on dry or windy days.
                        </p>
                    ) : riskLevel === 3 ? (
                        <p>
                            Use caution when open air burning. Dry local conditions could allow
                            fires to spread easily and be difficult to control.
                        </p>
                    ) : riskLevel === 4 ? (
                        <p>
                            Use extreme caution. Fires could spread quickly and beyond ability to
                            control. Postponing open air burning is recommended.
                        </p>
                    ) : null}
                </IonCardContent>
            </IonCard>
        );
    } else {
        return (
            <IonCard mode='ios' className='risk-widget'>
                <div
                    style={{
                        width: 'auto',
                        height: '150px',
                        margin: '20px auto 25px auto',
                    }}
                >
                    <div className='ion-text-center'>
                        <ReactSpeedometer
                            paddingVertical={10}
                            maxValue={4}
                            value={0.5}
                            needleColor={'#5260ff'}
                            needleHeightRatio={0.45}
                            segments={4}
                            // customSegmentStops={[0, 1.5, 2.5, 3.5, 5.0]}
                            segmentColors={[
                                // "#3dc2ff",
                                '#2dd36f',
                                '#ffc409',
                                '#ff6600',
                                '#ff0000',
                            ]}
                            customSegmentLabels={labels}
                            currentValueText={`1`}
                        />
                    </div>
                </div>
                <IonCardHeader>
                    <IonCardSubtitle>Current</IonCardSubtitle>
                    <IonCardTitle>
                        Fire Risk Level: {settingsForm && settingsForm.riskLevel}
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <p>
                        Local conditions pose extreme fire risk. Serious fires can start easily,
                        spread quickly, burn intensely, and be very difficult to control. NO OPEN
                        AIR FIRE PERMITTED.
                    </p>
                </IonCardContent>
            </IonCard>
        );
    }
}

export default GeneralRisk;
