import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonImg,
    IonGrid,
    IonRow,
    IonCol,
    IonModal,
    IonLoading,
} from '@ionic/react';
import { useEffect } from 'react';
import { menu } from 'ionicons/icons';
import '../../theme/WidgetLanding.css';
import 'bootstrap/dist/css/bootstrap.css';
import WidgetMenu from '../../components/widget/WidgetMenu';
import {
    settingsToggleStore,
    appStore,
    codeStore,
    toggleStore,
} from '../../store/Store';
import logo from '../../images/firepermitonlinelogo.png';
import RiskWidget from '../../components/widget/RiskWidget';
import WeatherWidget from '../../components/widget/WeatherWidget';
import GeneralEmergency from '../../components/common/GeneralEmergency';
function WidgetPreview() {
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    const userInfo = appStore.useState((s) => s.userInfo);
    const iframeCode = codeStore.useState((s) => s.iframeCode);
    const iframeCodeModal = toggleStore.useState((s) => s.iframeCodeModal);

    useEffect(() => {
        if (!userInfo.username) {
            window.location.href = '/widget/control';
        }
    }, []);

    const clickWeather = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = false;
            s.isEmergency = false;
            s.isWeather = true;
        });
    };
    const openCode = (widgetType) => {
        if (widgetType === 'risk') {
            toggleStore.update((s) => {
                s.iframeCodeModal = true;
            });
            codeStore.update((s) => {
                s.iframeCode = ` <iframe
                src='https://firepermitonline.web.app/risk-level/${userInfo.username}'
                width='100%'
                height='230'
                title='${userInfo.username}'
            ></iframe>`;
            });
        } else if (widgetType === 'weather') {
            toggleStore.update((s) => {
                s.iframeCodeModal = true;
            });
            codeStore.update((s) => {
                s.iframeCode = ` <iframe
                src='https://firepermitonline.web.app/weather/${userInfo.username}'
                width='100%'
                height='230'
                title='${userInfo.username}'
            ></iframe>`;
            });
        } else if (widgetType === 'emergency') {
            toggleStore.update((s) => {
                s.iframeCodeModal = true;
            });
            codeStore.update((s) => {
                s.iframeCode = ` <iframe
                src='https://firepermitonline.web.app/emergency-sign-up/${userInfo.username}'
                width='100%'
                height='230'
                title='${userInfo.username}'
            ></iframe>`;
            });
        }
    };

    const closeCodeModal = () => {
        toggleStore.update((s) => {
            s.iframeCodeModal = false;
        });
    };
    const clickRisk = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = true;
            s.isEmergency = false;
            s.isWeather = false;
        });
    };

    const clickEmergency = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = false;
            s.isEmergency = true;
            s.isWeather = false;
        });
    };

    return (
        <IonApp>
            <IonModal isOpen={iframeCodeModal} onDidDismiss={closeCodeModal}>
                <p className='ion-padding'>{iframeCode}</p>

                <div style={{ height: 200 }} />
                <IonButton
                    shape='round'
                    expand='block'
                    color='tertiary'
                    onClick={closeCodeModal}
                    className='ion-padding'
                >
                    Close
                </IonButton>
            </IonModal>
            <IonSplitPane when='lg' contentId='main-content'>
                <IonMenu contentId='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary-contrast'>
                            <IonImg src={logo} />
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <WidgetMenu />
                    </IonContent>
                </IonMenu>

                <div className='ion-page' id='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                            <IonTitle></IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent className='ion-padding'>
                        <IonLoading
                            isOpen={loadingSpinner}
                            message={'Processing...'}
                            duration={50000}
                        />
                        <IonGrid className='widget-examples-grid'>
                            <IonRow>
                                <IonCol className='widget-preview' size='6'>
                                    <h4>Risk Level Widget:</h4>
                                    <RiskWidget />
                                    <br />
                                    <IonButton
                                        onClick={() => {
                                            openCode('risk');
                                        }}
                                    >
                                        Code
                                    </IonButton>
                                </IonCol>
                                <IonCol className='widget-preview' size='6'>
                                    <h4>Weather Widget:</h4>
                                    <WeatherWidget />
                                    <br />
                                    <IonButton
                                        onClick={() => {
                                            openCode('weather');
                                        }}
                                    >
                                        Code
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol className='widget-preview' size='12'>
                                    <h4>Emergency Notification Widget:</h4>
                                    <GeneralEmergency />
                                    <br />
                                    <IonButton
                                        onClick={() => {
                                            openCode('emergency');
                                        }}
                                    >
                                        Code
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </div>
            </IonSplitPane>
        </IonApp>
    );
}

export default WidgetPreview;
