import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonImg,
    IonLoading,
} from '@ionic/react';
import { add, menu } from 'ionicons/icons';
import '../../theme/UploadImg.css';
import AnnouncementList from '../../components/admin/AnnouncementList';
import AnnouncementForm from '../../components/admin/AnnouncementForm';
import Menu from '../../components/admin/Menu';
import { toggleStore, appStore } from '../../store/Store';
import logo from '../../images/firepermitonlinelogo.png';
function UploadImg() {
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    const announcementFormToggle = toggleStore.useState((s) => s.announcementFormToggle);

    const openAnnouncementForm = () => {
        toggleStore.update((s) => {
            s.announcementFormToggle = true;
        });
    };

    return (
        <IonApp>
            <IonSplitPane when='lg' contentId='main-content'>
                <IonMenu contentId='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary-contrast'>
                            <IonImg src={logo} />
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <Menu />
                    </IonContent>
                </IonMenu>

                <div className='ion-page' id='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                            <IonTitle></IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent className='ion-padding'>
                        <IonLoading
                            isOpen={loadingSpinner}
                            message={'Processing...'}
                            duration={50000}
                        />
                        {!announcementFormToggle && (
                            <>
                                <div className='add-type-btn'>
                                    <IonTitle className='ion-padding'>Announcement List </IonTitle>
                                    <IonButton onClick={openAnnouncementForm}>
                                        <IonIcon slot='icon-only' icon={add}></IonIcon>
                                    </IonButton>
                                </div>
                            </>
                        )}
                        {<AnnouncementList />}
                        {announcementFormToggle && (
                            <>
                                <IonTitle className='ion-padding'>Add New Announcement </IonTitle>
                                <AnnouncementForm />
                            </>
                        )}
                    </IonContent>
                </div>
            </IonSplitPane>
        </IonApp>
    );
}

export default UploadImg;
