import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonLoading,
    IonImg,
} from '@ionic/react';
// import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { menu } from 'ionicons/icons';
import '../../theme/Dashboard.css';

import AdminPermitList from '../../components/admin/AdminPermitList';
import NormalAdminPermitList from '../../components/admin/NormalAdminPermitList';
import Menu from '../../components/admin/Menu';
import { appStore } from '../../store/Store';
import logo from '../../images/firepermitonlinelogo.png';
function AllPermits() {
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    const adminId = appStore.useState((s) => s.adminId);
    const superAdminId = appStore.useState((s) => s.superAdminId);
    return (
        <IonApp>
            <IonSplitPane when='lg' contentId='main-content'>
                <IonMenu contentId='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary-contrast'>
                            <IonImg src={logo} />
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <Menu />
                    </IonContent>
                </IonMenu>

                <div className='ion-page' id='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                            <IonTitle></IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent className='ion-padding'>
                        <IonLoading
                            isOpen={loadingSpinner}
                            message={'Processing...'}
                            duration={50000}
                        />
                        {superAdminId && <AdminPermitList />}
                        {adminId && <NormalAdminPermitList />}
                    </IonContent>
                </div>
            </IonSplitPane>
        </IonApp>
    );
}

export default AllPermits;
