import { IonButton, IonCard, IonIcon, IonAlert } from '@ionic/react';
import '../../theme/MyProfile.css';
import ProfileModal from './ProfileModal';
import { useEffect } from 'react';
import { appStore, toggleStore, modalToggleStore } from '../../store/Store';
import { deleteAccountById, resendEmailVerification } from '../../firebaseConfig';
import { createOutline, warningOutline } from 'ionicons/icons';
import { getAuth } from 'firebase/auth';
function UserProfile() {
    // const emailVerified = toggleStore.useState((s) => s.emailVerified);
    const userInfo = appStore.useState((s) => s.userInfo);
    const deleteAccountAlert = toggleStore.useState((s) => s.deleteAccountAlert);
    // const sendVerificationEmail = toggleStore.useState((s) => s.sendVerificationEmail);
    const resetEmailCountDown = appStore.useState((s) => s.resetEmailCountDown);
    const openProfileModal = () => {
        modalToggleStore.update((s) => {
            s.profilePage = 1;
        });
    };
    useEffect(() => {
        if (resetEmailCountDown > 0) {
            setTimeout(() => {
                appStore.update((s) => {
                    s.resetEmailCountDown = resetEmailCountDown - 1;
                });
            }, 1000);
        } else {
            toggleStore.update((s) => {
                s.sendVerificationEmail = false;
            });
        }
    }, [resetEmailCountDown]);
    const closeDeleteAccountAlert = () => {
        toggleStore.update((s) => {
            s.deleteAccountAlert = false;
        });
    };
    const openDeleteAlert = () => {
        toggleStore.update((s) => {
            s.deleteAccountAlert = true;
        });
    };
    const sendResetPwEmail = () => {
        resendEmailVerification();

        appStore.update((s) => {
            s.resetEmailCountDown = 60;
        });

        toggleStore.update((s) => {
            s.sendVerificationEmail = true;
        });
    };

    const deleteAccount = async () => {
        await localStorage.clear();
        appStore.update((s) => {
            s.loadingSpinner = true;
        });

        const auth = getAuth();
        const id = auth.currentUser.uid;
        await deleteAccountById(id);
    };
    // commented out so that non-email-verified user can still apply for permit

    // if (!emailVerified) {
    //     return (
    //         <>
    //             An email has been sent to your inbox. It can take up to 3-5 min for it to appear in
    //             your inbox. Please verify your email first before you can access your account <br />{' '}
    //             <br />
    //             If you haven't receive the email, you can try to send it by clicking the button
    //             below.
    //             <br />
    //             <IonButton disabled={sendVerificationEmail} onClick={sendResetPwEmail}>
    //                 {sendVerificationEmail && `Send Verification Email  ${resetEmailCountDown}`}
    //                 {!sendVerificationEmail && 'Send Verification Email'}
    //             </IonButton>
    //         </>
    //     );
    // } else

    return (
        <>
            <ProfileModal />
            {userInfo.address && (
                <div className='my-profile'>
                    <IonCard mode='ios' className='profile-card'>
                        <div className='profile-item'>
                            <h1 id='profile-name'>
                                {userInfo.first} {userInfo.last}
                            </h1>
                        </div>
                        <h3>Email:</h3>
                        <p>{userInfo.email}</p>
                        <h3>Phone:</h3>
                        <p>{userInfo.phone}</p>
                        <h3>Address:</h3>
                        <p>{userInfo.address}</p>
                        <h3>Subscribe:</h3>
                        <p>
                            SMS:{userInfo.smsSub && 'On'}
                            {!userInfo.smsSub && 'Off'}&nbsp;&nbsp; Email:
                            {userInfo.emailSub && 'On'}
                            {!userInfo.emailSub && 'Off'}&nbsp;&nbsp; Emergency Notification:
                            {userInfo.emergencySub && 'On'}
                            {!userInfo.emergencySub && 'Off'}
                        </p>
                        <IonButton
                            shape='round'
                            expand='block'
                            fill='outline'
                            color='white'
                            onClick={openProfileModal}
                            className='expand-btn'
                        >
                            <IonIcon className='edit-icon' icon={createOutline} />
                            <span style={{ color: '#3880ff' }}>Edit</span>
                        </IonButton>
                        <br />
                        <p>Please note that email addresses cannot be changed at this time.</p>
                        <br />
                    </IonCard>
                </div>
            )}

            {userInfo.address && (
                <div onClick={openDeleteAlert} className='delete-div'>
                    <IonIcon className='delete-icon' icon={warningOutline} />
                    &nbsp;&nbsp;
                    <p className='delete-btn'> Delete my Account</p>
                </div>
            )}
            <IonAlert
                isOpen={deleteAccountAlert}
                onDidDismiss={closeDeleteAccountAlert}
                header={'Confirm'}
                message={'Are you sure you want to delete your account?'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Delete',

                        handler: () => {
                            deleteAccount();
                        },
                    },
                ]}
            />
            {!userInfo.address && (
                <div className='account-card'>
                    <h1>Please complete your profile before applying for a permit</h1>
                    <IonButton
                        color='success'
                        shape='round'
                        onClick={openProfileModal}
                        size='large'
                    >
                        Complete your profile
                    </IonButton>
                </div>
            )}
        </>
    );
}

export default UserProfile;
