import { IonButton } from '@ionic/react';
import '../../../theme/Home.css';
import PhoneInput from 'react-phone-input-2';
import { modalToggleStore, userFormStore } from '../../../store/Store';
const Profile3 = () => {
    const phoneEmpty = modalToggleStore.useState((s) => s.phoneEmpty);
    const detailUserForm = userFormStore.useState((s) => s.detailUserForm);
    const updateFormDoc = (value, field) => {
        if (value.length < 8) {
            modalToggleStore.update((s) => {
                s.phoneEmpty = true;
            });
        } else if (value && field === 'phone') {
            modalToggleStore.update((s) => {
                s.phoneEmpty = false;
            });
            userFormStore.update((s) => {
                s.detailUserForm = { ...s.detailUserForm, phone: value };
            });
        } else if (!value && field === 'phone') {
            modalToggleStore.update((s) => {
                s.phoneEmpty = true;
            });
        }
    };
    const openFourthPage = () => {
        modalToggleStore.update((s) => {
            s.profilePage = 4;
        });
    };
    return (
        <>
            <h2 className='ion-padding'>What is your phone number?</h2>
            <br />
            <div className='ion-padding-horizontal'>
                <PhoneInput
                    value={detailUserForm.phone}
                    id='phone-input'
                    onChange={(e) => {
                        updateFormDoc(e, 'phone');
                    }}
                    country='ca'
                />
            </div>
            <br /> <br />
            <h5 className='ion-padding-horizontal'>How will we use your phone number?</h5>
            <p className='ion-padding-horizontal'>
                Communications will come from Parry Sound Fire and Rescue Department and will be
                restricted to notifications of burn bans and other important notices. Your phone
                number will not be used for promotional or marketing purposes or shared with third
                parties.
            </p>
            <br /> <br />
            <IonButton
                className='ion-padding-horizontal'
                shape='round'
                expand='block'
                color='tertiary'
                onClick={openFourthPage}
                disabled={phoneEmpty}
            >
                Continue
            </IonButton>
        </>
    );
};

export default Profile3;
