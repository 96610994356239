import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Recaptcha from 'react-recaptcha';
import { IonToast, IonButton, IonModal } from '@ionic/react';
import '../../theme/Login.css';
import { userFormStore, appStore, toastStore, fileStore, toggleStore } from '../../store/Store';
import {
    loginUser,
    registerUser,
    loginGoogle,
    resetPassword,
    loginFacebook,
} from '../../firebaseConfig';
function Copyright(props) {
    return (
        <Typography variant='body2' color='text.secondary' align='center' {...props}>
            {'© FirePermit.Online'} {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

export default function Login() {
    const reCapVerified = toggleStore.useState((s) => s.reCapVerified);
    const allImg = fileStore.useState((s) => s.allImg);
    const signInEmailToggle = toggleStore.useState((s) => s.signInEmailToggle);
    const emailFailToggle = toggleStore.useState((s) => s.emailFailToggle);
    const registerToggle = toggleStore.useState((s) => s.registerToggle);
    const toastMsg = toastStore.useState((s) => s.toastMsg);
    const resetEmailCountDown = appStore.useState((s) => s.resetEmailCountDown);
    const toastToggle = toastStore.useState((s) => s.toastToggle);
    const forgotPwToggle = toggleStore.useState((s) => s.forgotPwToggle);
    const emailResetEmptyToggle = toggleStore.useState((s) => s.emailResetEmptyToggle);
    const upgradeFromEmergency = toggleStore.useState((s) => s.upgradeFromEmergency);

    const sendResetEmailToggle = toggleStore.useState((s) => s.sendResetEmailToggle);
    useEffect(() => {
        if (resetEmailCountDown > 0) {
            setTimeout(() => {
                appStore.update((s) => {
                    s.resetEmailCountDown = resetEmailCountDown - 1;
                });
            }, 1000);
        } else {
            toggleStore.update((s) => {
                s.sendResetEmailToggle = false;
            });
        }
    }, [resetEmailCountDown]);
    const userForm = userFormStore.useState((s) => s.userForm);
    const updateUserForm = (value, field) => {
        userFormStore.update((s) => {
            s.userForm = { ...s.userForm, [field]: value };
        });
    };
    const oneClickRegister = () => {
        toggleStore.update((s) => {
            s.signInEmailToggle = false;
        });
    };
    const redirectSignInEmail = () => {
        toggleStore.update((s) => {
            s.signInEmailToggle = true;
        });
    };
    const login = (e) => {
        e.preventDefault();
        loginUser(userForm).then((data) => {
            if (data.res) {
                window.location.href = '/';
            }
            if (!data.res) {
                toastStore.update((s) => {
                    s.toastToggle = true;
                    s.toastMsg = 'Invalid Credentials';
                });
            }
        });
    };

    const redirectForgotPw = () => {
        toggleStore.update((s) => {
            s.forgotPwToggle = true;
        });
        userFormStore.update((s) => {
            s.userForm = { ...s.userForm, email: '' };
        });
    };

    const directToRegister = () => {
        toggleStore.update((s) => {
            s.registerToggle = true;
        });
    };
    const directToLogin = () => {
        toggleStore.update((s) => {
            s.registerToggle = false;
        });
    };

    const register = (e) => {
        e.preventDefault();
        registerUser(userForm).then((data) => {
            if (data.res) {
                toastStore.update((s) => {
                    s.toastMsg = 'Successfully Registered';
                });
                toastStore.update((s) => {
                    s.toastToggle = true;
                });
                window.location.href = '/';
            } else if (data.upgradeToFull) {
                toggleStore.update((s) => {
                    s.upgradeFromEmergency = true;
                });
            } else if (data.error) {
                toastStore.update((s) => {
                    s.toastMsg = data.error.message;
                });
                toastStore.update((s) => {
                    s.toastToggle = true;
                });
            }
        });
    };
    const sendResetPwEmail = () => {
        if (userForm.email === '') {
            toggleStore.update((s) => {
                s.emailResetEmptyToggle = true;
            });
        } else if (validateEmail(userForm.email) === null) {
            toggleStore.update((s) => {
                s.emailFailToggle = true;
            });
            toggleStore.update((s) => {
                s.emailResetEmptyToggle = false;
            });
        } else {
            toggleStore.update((s) => {
                s.emailFailToggle = false;
            });
            appStore.update((s) => {
                s.resetEmailCountDown = 30;
            });
            toggleStore.update((s) => {
                s.emailResetEmptyToggle = false;
            });
            toggleStore.update((s) => {
                s.sendResetEmailToggle = true;
            });
            resetPassword(userForm.email);
        }
    };

    const signInWithGoogle = () => {
        loginGoogle().then((data) => {
            if (data) {
                window.location.href = '/';
            }
        });
    };
    const reCapCallBack = () => {};
    const verifyCallback = (res) => {
        if (res) {
            toggleStore.update((s) => {
                s.reCapVerified = true;
            });
        }
    };

    const signInWithFacebook = () => {
        loginFacebook();
    };
    const redirectLogin = () => {
        toggleStore.update((s) => {
            s.forgotPwToggle = false;
        });
    };
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const closeUpgradeModal = () => {
        window.location.href = 'login';
    };
    return (
        <>
            <IonModal isOpen={upgradeFromEmergency}>
                <p className='ion-padding'>
                    A password reset email has been sent because your email has previously been used
                    for Emergency Alerts. Please set a password and then login to continue.
                </p>

                <div style={{ height: 200 }} />
                <IonButton
                    shape='round'
                    expand='block'
                    color='tertiary'
                    onClick={closeUpgradeModal}
                    className='ion-padding'
                >
                    Close
                </IonButton>
            </IonModal>
            <ThemeProvider theme={theme}>
                <Grid container component='main' sx={{ height: '100vh' }}>
                    <CssBaseline />
                    {allImg && (
                        <Grid
                            item
                            xs={false}
                            sm={4}
                            md={7}
                            sx={{
                                backgroundImage: `url(${
                                    allImg.filter((img) => img.name === 'homeImage')[0].imageURL
                                })`,
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: (t) =>
                                    t.palette.mode === 'light'
                                        ? t.palette.grey[50]
                                        : t.palette.grey[900],
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        />
                    )}

                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {allImg && (
                                <Avatar
                                    alt='Example Alt'
                                    sx={{ height: '100px', width: '100px' }}
                                    src={
                                        allImg.filter((img) => img.name === 'homeLogo')[0].imageURL
                                    }
                                />
                            )}

                            {!forgotPwToggle && !registerToggle && (
                                <>
                                    {' '}
                                    <Typography component='h1' variant='h5'>
                                        Sign in
                                    </Typography>
                                    <Box
                                        component='form'
                                        noValidate
                                        onSubmit={login}
                                        sx={{ mt: 1 }}
                                    >
                                        {signInEmailToggle && (
                                            <>
                                                <TextField
                                                    onChange={(e) => {
                                                        updateUserForm(e.target.value, 'email');
                                                    }}
                                                    margin='normal'
                                                    required
                                                    fullWidth
                                                    id='email'
                                                    label='Email Address'
                                                    name='email'
                                                    autoComplete='email'
                                                    autoFocus
                                                />
                                                <TextField
                                                    margin='normal'
                                                    required
                                                    fullWidth
                                                    name='password'
                                                    label='Password'
                                                    type='password'
                                                    id='password'
                                                    autoComplete='current-password'
                                                    onChange={(e) => {
                                                        updateUserForm(e.target.value, 'password');
                                                    }}
                                                />
                                                <IonButton
                                                    type='submit'
                                                    shape='round'
                                                    expand='block'
                                                    color='tertiary'
                                                    style={{ margin: '10px 0' }}
                                                >
                                                    Sign In
                                                </IonButton>
                                                <Grid>
                                                    <Grid style={{ textAlign: 'center' }}>
                                                        <Link
                                                            onClick={oneClickRegister}
                                                            className='link-btns'
                                                            variant='body2'
                                                        >
                                                            {'Use One-Click Log in instead'}
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                            </>
                                        )}
                                        {!signInEmailToggle && (
                                            <>
                                                <IonButton
                                                    shape='round'
                                                    expand='block'
                                                    color='tertiary'
                                                    onClick={signInWithGoogle}
                                                    style={{ margin: '10px 0 20px' }}
                                                >
                                                    Sign In With Google
                                                </IonButton>
                                                <IonButton
                                                    shape='round'
                                                    expand='block'
                                                    color='tertiary'
                                                    onClick={signInWithFacebook}
                                                    style={{ margin: '10px 0' }}
                                                >
                                                    Sign In With Facebook
                                                </IonButton>
                                                <Typography
                                                    style={{ textAlign: 'center' }}
                                                    component='h1'
                                                    variant='h5'
                                                >
                                                    or
                                                </Typography>

                                                <IonButton
                                                    onClick={redirectSignInEmail}
                                                    shape='round'
                                                    expand='block'
                                                    color='light'
                                                    style={{ margin: '10px 0 20px' }}
                                                >
                                                    Use email & password instead
                                                </IonButton>
                                            </>
                                        )}

                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                                mb={3}
                                                style={{ textAlign: 'center' }}
                                            >
                                                <Link
                                                    className='link-btns'
                                                    onClick={redirectForgotPw}
                                                    variant='body2'
                                                >
                                                    Forgot password?
                                                </Link>
                                            </Grid>
                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                <Link
                                                    onClick={directToRegister}
                                                    className='link-btns'
                                                    variant='body2'
                                                >
                                                    &nbsp;{"Don't have an account? Sign Up"}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        <Copyright sx={{ mt: 5 }} />
                                    </Box>
                                </>
                            )}

                            {registerToggle && (
                                <>
                                    {' '}
                                    <Typography component='h1' variant='h5'>
                                        Sign up
                                    </Typography>
                                    <Box
                                        component='form'
                                        noValidate
                                        onSubmit={register}
                                        sx={{ mt: 1 }}
                                    >
                                        <TextField
                                            onChange={(e) => {
                                                updateUserForm(e.target.value, 'email');
                                            }}
                                            margin='normal'
                                            required
                                            fullWidth
                                            id='email'
                                            label='Email Address'
                                            name='email'
                                            autoComplete='email'
                                            autoFocus
                                        />
                                        <TextField
                                            margin='normal'
                                            required
                                            fullWidth
                                            name='password'
                                            label='Password'
                                            type='password'
                                            id='password'
                                            autoComplete='current-password'
                                            onChange={(e) => {
                                                updateUserForm(e.target.value, 'password');
                                            }}
                                        />

                                        <IonButton
                                            disabled={!reCapVerified}
                                            type='submit'
                                            shape='round'
                                            expand='block'
                                            color='tertiary'
                                            style={{ margin: '10px 0' }}
                                        >
                                            Register
                                        </IonButton>
                                        <div className='login-recap'>
                                            <Recaptcha
                                                sitekey='6Lc9obQeAAAAAIDliUxE-LL8jLllD8YFQADjihXB'
                                                render='explicit'
                                                onloadCallback={reCapCallBack}
                                                verifyCallback={verifyCallback}
                                            />
                                        </div>
                                        <Grid container>
                                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                <Link
                                                    onClick={directToLogin}
                                                    className='link-btns'
                                                    variant='body2'
                                                    style={{ margin: '20px 0' }}
                                                >
                                                    Back to Login
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        <Copyright sx={{ mt: 5 }} />
                                    </Box>
                                </>
                            )}

                            {forgotPwToggle && (
                                <>
                                    <Typography component='h1' variant='h5'>
                                        Reset Password
                                    </Typography>
                                    {emailResetEmptyToggle && (
                                        <Typography color='red' variant='body2'>
                                            *Please Enter Email
                                        </Typography>
                                    )}
                                    {emailFailToggle && (
                                        <Typography color='red' variant='body2'>
                                            *Please Enter a Valid Email
                                        </Typography>
                                    )}
                                    <Box
                                        component='form'
                                        noValidate
                                        onSubmit={login}
                                        sx={{ mt: 1 }}
                                    >
                                        <TextField
                                            onChange={(e) => {
                                                updateUserForm(e.target.value, 'email');
                                            }}
                                            margin='normal'
                                            required
                                            type='email'
                                            fullWidth
                                            id='email'
                                            label='Email Address'
                                            name='email'
                                            autoComplete='email'
                                            autoFocus
                                        />

                                        <IonButton
                                            disabled={sendResetEmailToggle}
                                            onClick={sendResetPwEmail}
                                            shape='round'
                                            expand='block'
                                            color='tertiary'
                                            style={{ margin: '10px 0' }}
                                        >
                                            {sendResetEmailToggle &&
                                                `Send Reset Email  ${resetEmailCountDown}`}
                                            {!sendResetEmailToggle && 'Send Reset Email'}
                                        </IonButton>

                                        <Grid container>
                                            <Grid item style={{ textAlign: 'center' }}>
                                                <Link
                                                    className='link-btns'
                                                    onClick={redirectLogin}
                                                    variant='body2'
                                                    style={{ margin: '30px 0' }}
                                                >
                                                    {'Back to login'}
                                                </Link>
                                            </Grid>
                                        </Grid>
                                        <Copyright sx={{ mt: 5 }} />
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
            <IonToast
                isOpen={toastToggle}
                onDidDismiss={() =>
                    toastStore.update((s) => {
                        s.toastToggle = false;
                    })
                }
                message={toastMsg}
                duration={1000}
            />
        </>
    );
}
