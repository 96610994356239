import { IonButton, IonRow, IonCol, IonGrid, IonImg } from '@ionic/react';
import '../../theme/Landing.css';
import { profileFormStore, fileStore } from '../../store/Store';
import AnnouncementWidget from './AnnouncementWidget';
import RiskWidget from './RiskWidget';
import WeatherWidget from './WeatherWidget';
import ContactWidget from './ContactWidget';
import EmergencyWidget from './EmergencyWidget';

function Landing() {
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);
    const homeLogoImg = fileStore.useState((s) => s.homeLogoImg);
    const directToLogin = () => {
        window.location.href = '/login';
    };
    return (
        <>
            <br />
            <div className='login-btn'>
                {homeLogoImg && (
                    <div className='landing-logo'>
                        <IonImg src={homeLogoImg.imageURL}></IonImg>
                    </div>
                )}
                <h1
                    style={{
                        maxWidth: 800,
                        margin: '50px auto',
                        textAlign: 'center',
                        fontSize: '3rem',
                    }}
                >
                    To buy a burn permit, please create an account or login using the button below:
                </h1>
                <IonButton color='success' shape='round' size='large' onClick={directToLogin}>
                    Create Account / Login
                </IonButton>
            </div>
            <div style={{ height: 100 }} />

            <IonGrid className='widget-grid'>
                <IonRow className='first-widget-row'>
                    {settingsForm.riskToggle && (
                        <IonCol size-sm='12' size-md='6' size-lg='6'>
                            <RiskWidget />
                        </IonCol>
                    )}
                    {settingsForm.weatherToggle && (
                        <IonCol size-sm='12' size-md='6' size-lg='6'>
                            <WeatherWidget />
                        </IonCol>
                    )}
                </IonRow>
                <IonRow>
                    <IonCol size='12'>
                        <EmergencyWidget />
                    </IonCol>
                </IonRow>

                <br />
                {settingsForm.announcementsToggle && (
                    <IonRow>
                        <IonCol size='12'>
                            <AnnouncementWidget />
                        </IonCol>
                    </IonRow>
                )}
            </IonGrid>
            <IonGrid className='widget-grid'>
                {settingsForm.contactToggle && (
                    <IonRow>
                        <IonCol size='12'>
                            <ContactWidget />
                        </IonCol>
                    </IonRow>
                )}
            </IonGrid>
        </>
    );
}

export default Landing;
