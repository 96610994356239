import { IonLabel, IonRow, IonCol, IonInput, IonButton, IonImg, IonItem } from '@ionic/react';
import '../../../theme/Home.css';
import { LoadScript } from '@react-google-maps/api';
import {
    modalToggleStore,
    addressFormStore,
    userFormStore,
    profileFormStore,
    toggleStore,
} from '../../../store/Store';
import poweredByGoogle from '../../../images/powered_by_google.png';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useStoreState } from 'pullstate';
const Profile4 = () => {
    const addressForm = addressFormStore.useState((s) => s.addressForm);
    const manualAddress = toggleStore.useState((s) => s.manualAddress);
    const addressEmpty = modalToggleStore.useState((s) => s.addressEmpty);
    const { mapsAPI, settingsForm } = useStoreState(profileFormStore);

    const editAddress = () => {
        toggleStore.update((s) => {
            s.manualAddress = true;
        });
    };
    const searchAddress = () => {
        toggleStore.update((s) => {
            s.manualAddress = false;
        });
    };
    const updateAddress = (value, field) => {
        if (field === 'address' && value && addressForm.city && addressForm.province) {
            modalToggleStore.update((s) => {
                s.addressEmpty = false;
            });
        } else if (field === 'city' && value && addressForm.address && addressForm.province) {
            modalToggleStore.update((s) => {
                s.addressEmpty = false;
            });
        } else if (field === 'province' && value && addressForm.address && addressForm.city) {
            modalToggleStore.update((s) => {
                s.addressEmpty = false;
            });
        } else {
            modalToggleStore.update((s) => {
                s.addressEmpty = true;
            });
        }
        addressFormStore.update((s) => {
            s.addressForm = { ...s.addressForm, [field]: value };
        });
    };
    const openFifthPage = () => {
        modalToggleStore.update((s) => {
            s.profilePage = 5;
        });
    };

    const updateFormDoc = (value, field) => {
        if (value && field === 'address') {
            userFormStore.update((s) => {
                s.detailUserForm = { ...s.detailUserForm, address: value };
            });
        }
    };
    const handleChange = (e) => {
        modalToggleStore.update((s) => {
            s.addressEmpty = false;
        });

        updateFormDoc(e, 'address');
        const result = e.split(',');
        //clearing stuff after comma should also clear corresponding field in  manual address
        if (result[0] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: '' };
                s.addressForm = { ...s.addressForm, city: '' };
                s.addressForm = { ...s.addressForm, province: '' };
            });
        } else if (result[1] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: '' };
                s.addressForm = { ...s.addressForm, province: '' };
            });
        } else if (result[2] === '') {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, province: '' };
            });
        }

        if (result[0]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: `${result[0]}` };
            });
        }

        // if this line is not added you cant enter comma
        if (result[0] && result[1] !== undefined) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, address: `${result[0]},` };
            });
        }
        if (result[1]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: `${result[1]}` };
            });
        }
        // if this line is not added you cant enter comma
        if (result[1] && result[2] !== undefined) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, city: `${result[1]},` };
            });
        }
        if (result[2]) {
            addressFormStore.update((s) => {
                s.addressForm = { ...s.addressForm, province: `${result[2]}` };
            });
        }
    };
    return (
        <>
            <h2 className='ion-padding'>What is your address ?</h2>

            <br />
            {!manualAddress && (
                <LoadScript
                    id='script-loader'
                    googleMapsApiKey={`${mapsAPI}&libraries=drawing,places`}
                    language='en'
                    region='us'
                >
                    <div className='modal-address-search'>
                        <GooglePlacesAutocomplete
                            selectProps={{
                                placeholder: 'Search Address...',
                                onChange: (e) => {
                                    handleChange(e.label);
                                },
                            }}
                            autocompletionRequest={{
                                bounds: [
                                    {
                                        lat: settingsForm.boundCoord.s,
                                        lng: settingsForm.boundCoord.w,
                                    },
                                    {
                                        lat: settingsForm.boundCoord.n,
                                        lng: settingsForm.boundCoord.e,
                                    },
                                ],
                                componentRestrictions: {
                                    country: ['ca'],
                                },
                                strictBounds: true,
                            }}
                        />
                        <IonImg id='google-logo' src={poweredByGoogle} />
                    </div>
                </LoadScript>
            )}
            {manualAddress && (
                <>
                    <div className='manual-address-form'>
                        <IonRow>
                            <IonCol size='10'>
                                <IonItem>
                                    <IonLabel>Address:</IonLabel>{' '}
                                    <IonInput
                                        value={addressForm.address}
                                        onIonChange={(e) => {
                                            updateAddress(e.target.value, 'address');
                                        }}
                                        required
                                        placeholder='Enter Address'
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='10'>
                                <IonItem>
                                    <IonLabel>City:</IonLabel>{' '}
                                    <IonInput
                                        value={addressForm.city}
                                        onIonChange={(e) => {
                                            updateAddress(e.target.value, 'city');
                                        }}
                                        required
                                        placeholder='Enter City'
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='10'>
                                <IonItem>
                                    <IonLabel>Province:</IonLabel>{' '}
                                    <IonInput
                                        value={addressForm.province}
                                        onIonChange={(e) => {
                                            updateAddress(e.target.value, 'province');
                                        }}
                                        required
                                        placeholder='Enter Province'
                                    />
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </div>
                    <div className='manual-address'>
                        <p className='manual-address-btn' onClick={searchAddress}>
                            search address
                        </p>
                    </div>
                </>
            )}
            <br />
            {!manualAddress && (
                <>
                    {' '}
                    <p className='ion-padding-horizontal'>
                        {' '}
                        Use the search box above by entering the address you're looking for. When
                        you see the correct address, please click/tap on it to set it.
                    </p>
                    <span className='ion-padding-horizontal'>Don't see your address listed?</span>
                    <p className='manual-address-btn' onClick={editAddress}>
                        manually enter address
                    </p>
                </>
            )}
            <br />
            <IonButton
                className='ion-padding-horizontal'
                shape='round'
                expand='block'
                color='tertiary'
                disabled={addressEmpty}
                onClick={openFifthPage}
            >
                Continue
            </IonButton>
        </>
    );
};

export default Profile4;
