import axios from 'axios';
import '../../theme/SearchBar.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { appStore, coordsStore, profileFormStore } from '../../store/Store';
import { IonButton } from '@ionic/react';
import { useStoreState } from 'pullstate';
function CheckAddress() {
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);
    const addressPass = appStore.useState((s) => s.addressPass);
    const searched = appStore.useState((s) => s.searched);
    const rectangleCoords = coordsStore.useState((s) => s.rectangleCoords);
    const circleCoords = coordsStore.useState((s) => s.circleCoords);
    const polygonCoords = coordsStore.useState((s) => s.polygonCoords);
    const address = appStore.useState((s) => s.address);
    const { mapsAPI } = useStoreState(profileFormStore);
    function checkAddress() {
        //change address input to lat and lng using geo api
        axios
            .get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: address,
                    key: mapsAPI,
                },
            })
            .then((response) => {
                appStore.update((s) => {
                    s.addressPass = true;
                });

                const checkLat = response.data.results[0].geometry.location.lat;
                const checkLng = response.data.results[0].geometry.location.lng;

                appStore.update((s) => {
                    s.markerCoord = {
                        lat: checkLat,
                        lng: checkLng,
                    };
                });

                // check if the input address coord falls into the circle areas
                circleCoords.forEach((coord) => {
                    const circleRaidusInDegree = (coord.radius / 1.11) * 0.00001;
                    const circleX = coord.coords.lng;
                    const circleY = coord.coords.lat;
                    const xDifference = Math.abs(checkLng - circleX);
                    const yDifference = Math.abs(checkLat - circleY);
                    const pointRaidus = Math.sqrt(
                        xDifference * xDifference + yDifference * yDifference
                    );

                    if (circleRaidusInDegree > pointRaidus) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                    }
                });
                // check if the input address coord falls into the rectangle areas
                rectangleCoords.forEach((coord) => {
                    if (
                        coord.coords[2].lat > checkLat &&
                        coord.coords[0].lat < checkLat &&
                        coord.coords[2].lng > checkLng &&
                        coord.coords[0].lng < checkLng
                    ) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                    }
                });
                // check if the input address coord falls into the polygon areas
                polygonCoords.forEach((coord) => {
                    let count = 0;
                    //check last polyline
                    if (
                        (coord.coords[0].lat > checkLat &&
                            coord.coords[coord.coords.length - 1].lat < checkLat) ||
                        (coord.coords[0].lat < checkLat &&
                            coord.coords[coord.coords.length - 1].lat > checkLat)
                    ) {
                        const slope =
                            (coord.coords[coord.coords.length - 1].lat - coord.coords[0].lat) /
                            (coord.coords[coord.coords.length - 1].lng - coord.coords[0].lng);
                        const intercept =
                            coord.coords[coord.coords.length - 1].lat -
                            slope * coord.coords[coord.coords.length - 1].lng;
                        const cross = (checkLat - intercept) / slope;
                        if (cross > checkLng) {
                            count = count + 1;
                        }
                    }
                    for (let i = 0; i < coord.coords.length - 1; i++) {
                        if (
                            (coord.coords[i].lat > checkLat &&
                                coord.coords[i + 1].lat < checkLat) ||
                            (coord.coords[i].lat < checkLat && coord.coords[i + 1].lat > checkLat)
                        ) {
                            const slope =
                                (coord.coords[i + 1].lat - coord.coords[i].lat) /
                                (coord.coords[i + 1].lng - coord.coords[i].lng);
                            const intercept =
                                coord.coords[i + 1].lat - slope * coord.coords[i + 1].lng;
                            const cross = (checkLat - intercept) / slope;
                            if (cross > checkLng) {
                                count = count + 1;
                            }
                        }
                    }

                    if (count % 2 === 1) {
                        appStore.update((s) => {
                            s.addressPass = false;
                        });
                    }
                });

                appStore.update((s) => {
                    s.searched = true;
                });
            });
    }
    const handleChange = (e) => {
        appStore.update((s) => {
            s.address = e;
        });
    };

    return (
        <>
            <div className='check-address'>
                {settingsForm && (
                    <GooglePlacesAutocomplete
                        selectProps={{
                            placeholder: 'Enter Address...',
                            onChange: (e) => {
                                handleChange(e.label);
                            },
                        }}
                        autocompletionRequest={{
                            bounds: [
                                { lat: settingsForm.boundCoord.s, lng: settingsForm.boundCoord.w },
                                { lat: settingsForm.boundCoord.n, lng: settingsForm.boundCoord.e },
                            ],
                            componentRestrictions: {
                                country: ['ca'],
                            },
                            strictBounds: true,
                        }}
                    />
                )}
            </div>

            <div id='check-btn'>
                <IonButton onClick={checkAddress}>Check</IonButton>
                {!addressPass && searched && (
                    <div className='pass'>
                        &nbsp;&nbsp;&nbsp; The address you entered falls in the restricted area
                    </div>
                )}
                {addressPass && searched && (
                    <div className='fail'>
                        &nbsp;&nbsp;&nbsp; The address you entered does not fall in the restricted
                        area
                    </div>
                )}
                <br /> <br />
            </div>
        </>
    );
}

export default CheckAddress;
