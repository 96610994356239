import { toggleStore, appStore, announcementStore } from '../../store/Store';
import { useEffect } from 'react';
import { IonIcon, IonAlert } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import '../../theme/Activity.css';
import { fetchAllAnnouncements, deleteAnnouncementById } from '../../firebaseConfig';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

function ActivityType() {
    //mui theme
    let theme = createTheme();
    theme = responsiveFontSizes(theme);
    const columns = ['Title', 'Created', 'Details', 'Delete'];
    const announcements = announcementStore.useState((s) => s.announcements);
    const deleteAnnouncementToggle = toggleStore.useState((s) => s.deleteAnnouncementToggle);
    const currentAnnouncementId = announcementStore.useState((s) => s.currentAnnouncementId);
    const announcementFormToggle = toggleStore.useState((s) => s.announcementFormToggle);

    const openDeleteAnnouncement = (id) => {
        announcementStore.update((s) => {
            s.currentAnnouncementId = id;
        });
        toggleStore.update((s) => {
            s.deleteAnnouncementToggle = true;
        });
    };
    const closeDeleteAnnouncement = (id) => {
        announcementStore.update((s) => {
            s.currentAnnouncementId = '';
        });
        toggleStore.update((s) => {
            s.deleteAnnouncementToggle = false;
        });
    };
    useEffect(() => {
        fetchAllAnnouncements().then((data) => {
            const finalDataArr = [];
            data.map((announcement) => {
                const deleteBtn = (
                    <IonIcon
                        onClick={() => {
                            openDeleteAnnouncement(announcement.id);
                        }}
                        icon={trashOutline}
                    />
                );
                const announcementArr = [];
                announcementArr.push(
                    announcement.title,
                    announcement.date_created,
                    announcement.details,
                    deleteBtn
                );
                finalDataArr.push(announcementArr);
            });
            announcementStore.update((s) => {
                s.announcements = finalDataArr;
            });
        });
    }, []);

    return (
        <>
            <IonAlert
                isOpen={deleteAnnouncementToggle}
                onDidDismiss={closeDeleteAnnouncement}
                header={'Confirm'}
                message={'Do you wish to delete this announcement'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Delete',

                        handler: () => {
                            appStore.update((s) => {
                                s.loadingSpinner = true;
                            });
                            deleteAnnouncementById(currentAnnouncementId);
                        },
                    },
                ]}
            />
            {announcements && !announcementFormToggle && (
                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        title={'All Announcements'}
                        data={announcements}
                        columns={columns}
                        options={{
                            selectableRows: 'none', // <===== will turn off checkboxes in rows
                            print: false,
                            viewColumns: false,
                        }}
                    />
                </ThemeProvider>
            )}
        </>
    );
}

export default ActivityType;
