import { Store } from 'pullstate';
export const coordsStore = new Store({
    polygonCoords: [],
    circleCoords: [],
    rectangleCoords: [],
    mapCenterCoord: { lat: 45.347923, lng: -80.033008 },
    selectedCoord: null,
    currentBurnCoord: '',
});

export const appStore = new Store({
    addressPass: true,
    searched: false,
    markerCoord: {},
    address: '',
    permitAddress: '',
    drawToggle: false,
    formToggle: false,
    userId: '',
    userInfo: {},
    adminId: '',
    superAdminId: '',
    loading: true,
    spinnerLoading: false,
    darkMode: false,
    subscribeLoading: false,
    archiveLoading: false,
    resetEmailCountDown: 0,
    loadingSpinner: false,
    noPwUserId: '',
    adminEmails: '',
    widgetUser: false,
});

export const shapeStore = new Store({
    currentShapeID: '',
    currentShape: '',
    currentShapeData: '',
    currentShapeDataForMap: '',
    allShapes: null,
});

export const shapeFormStore = new Store({
    shapeForm: {
        name: '',
        description: '',
    },
});

export const toggleStore = new Store({
    dashboardToggle: true,
    deleteAlertToggle: false,
    permitFormToggle: false,
    permitTypeFormToggle: false,
    permitAddressToggle: false,
    allPermitTypesToggle: false,
    deletePermitToggle: false,
    deletePermitTypeToggle: false,
    cancelSubToggle: false,
    reactivateSubToggle: false,
    payPermitToggle: false,
    refundToggle: false,
    unarchiveToggle: false,
    phoneFailToggle: false,
    emailFailToggle: false,
    addressEmptyToggle: false,
    typeEmptyToggle: false,
    forgotPwToggle: false,
    subscribeToggle: true,
    announcementFormToggle: false,
    sendResetEmailToggle: false,
    emailResetEmptyToggle: false,
    confirmAnnouncementEmailToggle: false,
    confirmAnnouncementSMSToggle: false,
    confirmAnnouncementToggle: false,
    typeValidEmptyToggle: false,
    adminTypeEmptyToggle: false,
    deleteAnnouncementToggle: false,
    emptyAnnouncementTitle: false,
    emptyAnnouncementDetails: false,
    registerToggle: false,
    profileModal: false,
    permitModal: false,
    firstPage: false,
    secondPage: false,
    thirdPage: false,
    fourthPage: false,
    fifthPage: false,
    agreementPage: false,
    manualAddress: false,
    userAddressInput: false,
    userManualAddressInput: false,
    chooseAddressToggle: 'auto',
    signInEmailToggle: false,
    agreeRules: false,
    deleteAccountAlert: false,
    pdfViewerToggle: false,
    userUpdateSub: false,
    userUpdateAct: false,
    emergencyEmailEmpty: false,
    emailVerified: false,
    reCapVerified: false,
    emailRegistered: false,
    deleteAdminToggle: false,
    addAdminToggle: false,
    sendVerificationEmail: false,
    upgradeFromEmergency: false,
    inspectToggle: false,
    inspectAlertMsg: '',
    isUpdateModal: false,
    registeredAdmin: false,
    pwlessSignUp: false,
    successfulSend: false,
    wrongEmailToggle: false,
    addAdminEmailInvalid: false,
    addAdminPwInvalid: false,
    adminEmailSub: false,
    iframeCodeModal: false,
    usernameNotUnique: false,
    usernameNotVerify: false,
    notesModalToggle: false,
});

export const adminStore = new Store({
    admins: [],
    adminId: '',
});

export const modalToggleStore = new Store({
    permitTypeEmpty: true,
    addressEmpty: true,
    firstNameEmpty: true,
    lastNameEmpty: true,
    phoneEmpty: true,
    permitPage: 0,
    profilePage: 0,
    emergencyModal: false,
});

export const userFormStore = new Store({
    userForm: {
        email: '',
        password: '',
    },
    detailUserForm: {
        first: '',
        last: '',
        phone: '',
        address: '',
        smsSub: true,
        emailSub: true,
        emergencySub: true,
    },
    noPwUserForm: {
        phone: '',
        emergencySub: true,
    },
});
export const adminUserFormStore = new Store({
    adminUserForm: {
        email: '',
        password: '',
        type: '',
    },
});

export const toastStore = new Store({
    darkModeToast: false,
    toastMsg: '',
    toastToggle: false,
    updateSubMsg: '',
});

export const permitFormStore = new Store({
    permitForm: {
        first: '',
        last: '',
        phone: '',
        address: '',
        type: '',
        burnCoord: {},
        email: '',
    },
});

export const addressFormStore = new Store({
    addressForm: {
        address: '',
        city: '',
        province: '',
    },
});

export const permitTypeFormStore = new Store({
    permitTypeForm: {
        name: '',
        cost: '',
        desc: '',
        valid: '',
    },
});

export const profileFormStore = new Store({
    profileForm: {
        name: '',
        email: '',
        address: '',
        byLaw: '',
        phone: '',
        blurb: '',
    },
    settingsForm: {},
    riskLevel: '',
});

export const permitsStore = new Store({
    permits: [],
    filteredPermits: [],
    permitsCoords: [],
    permitTypes: [],
    currentPermitId: '',
    currentPermitTypeId: '',
    idForActiveness: '',
    activeness: false,
    permit: {},
    searchPermitValue: '',
    currentPermit: {},
    tempPermits: null,
    tempPermitTypes: null,
    currentUserPermits: [],
    currentTypeValid: '',
    currentPermitAddress: '',
    permitIdForPDF: '',
});

export const rulesStore = new Store({
    rulesInDraft: null,
});

export const fileStore = new Store({
    homeImgFile: null,
    homeLogoFile: null,
    homeImg: null,
    homeLogoImg: null,
});

export const settingsToggleStore = new Store({
    isProfile: true,
    isRiskWidget: true,
    isHome: false,
    isAddAdmin: false,
    isLoginToggles: false,
    isTerms: false,
    isSubscription: false,
    isStreets: false,
    isWeather: false,
    isEmergency: false,
});

export const announcementFormStore = new Store({
    announcementForm: {
        title: '',
        details: '',
        link: '',
        sendViaEmail: false,
        sendViaSMS: false,
        sendToLanding: true,
        sendEmergency: false,
    },
    announcementTitle: '',
    announcementDetails: '',
});

export const announcementStore = new Store({
    announcements: [],
    currentAnnouncementId: '',
});

export const weatherStore = new Store({
    windSpeed: '',
    temperature: '',
    weatherIconId: '',
    weatherDesc: '',
});

export const emergencyStore = new Store({
    email: '',
    content: '',
});

export const widgetControlStore = new Store({
    weatherAddress: '',
    username: '',
});

export const riskControlStore = new Store({
    riskLevel: '',
});

export const codeStore = new Store({
    iframeCode: '',
});
