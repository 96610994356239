import { shapeFormStore, appStore, shapeStore } from '../../store/Store';
import {
    addPolygonCoords,
    addCircleCoords,
    addRectangleCoords,
    updateShapeInfo,
} from '../../firebaseConfig';
import { IonButton, IonInput, IonItem, IonLabel, IonText } from '@ionic/react';
import '../../theme/ShapeInfoForm.css';

function ShapeInfoForm() {
    const currentShape = shapeStore.useState((s) => s.currentShape);
    const shapeForm = shapeFormStore.useState((s) => s.shapeForm);
    const currentShapeData = shapeStore.useState((s) => s.currentShapeData);
    const currentShapeID = shapeStore.useState((s) => s.currentShapeID);
    const updateFormDoc = (value, field) => {
        shapeFormStore.update((s) => {
            s.shapeForm = { ...s.shapeForm, [field]: value };
        });
    };
    const back = () => {
        appStore.update((s) => {
            s.formToggle = false;
        });
        shapeStore.update((s) => {
            s.currentShape = null;
        });
    };
    const submitShapeInfoForm = async (e) => {
        e.preventDefault();
        // condition when user creating rectangle
        if (currentShape === 'Rectangle' && currentShapeData) {
            let rectangleCoords = [
                {
                    lng: currentShapeData.bounds.Qa.g,
                    lat: currentShapeData.bounds.zb.g,
                },
                {
                    lng: currentShapeData.bounds.Qa.g,
                    lat: currentShapeData.bounds.zb.h,
                },
                {
                    lng: currentShapeData.bounds.Qa.h,
                    lat: currentShapeData.bounds.zb.h,
                },
                {
                    lng: currentShapeData.bounds.Qa.h,
                    lat: currentShapeData.bounds.zb.g,
                },
            ];

            await addRectangleCoords(rectangleCoords, shapeForm);

            window.location.href = '/exclusion';
        } else if (currentShape === 'Rectangle' && !currentShapeData) {
            await updateShapeInfo(shapeForm, currentShapeID);
            window.location.href = '/exclusion';
        }

        // condition when user creating rectangle
        if (currentShape === 'Circle' && currentShapeData) {
            let circleCoords = {
                lat: currentShapeData.center.lat(),
                lng: currentShapeData.center.lng(),
            };

            await addCircleCoords(circleCoords, currentShapeData.radius, shapeForm);
            window.location.href = '/exclusion';
        } else if (currentShape === 'Circle' && !currentShapeData) {
            await updateShapeInfo(shapeForm, currentShapeID);
            window.location.href = '/exclusion';
        }

        // condition when user creating polygon
        if (currentShape === 'Polygon' && currentShapeData) {
            await addPolygonCoords(currentShapeData, shapeForm);
            window.location.href = '/exclusion';
        } else if (currentShape === 'Polygon' && !currentShapeData) {
            await updateShapeInfo(shapeForm, currentShapeID);
            window.location.href = '/exclusion';
        }
    };

    return (
        <form onSubmit={submitShapeInfoForm}>
            <div className='form'>
                <IonText>
                    <h2>Please Enter Area Info</h2>
                </IonText>
                <br />
                <IonItem>
                    <IonLabel>Name:</IonLabel>
                    <IonInput
                        required
                        onIonChange={(e) => {
                            updateFormDoc(e.target.value, 'name');
                        }}
                        placeholder='Enter Name'
                        value={shapeForm.name}
                    />
                </IonItem>

                <br />
                <IonItem>
                    <IonLabel>Description:</IonLabel>{' '}
                    <IonInput
                        required
                        onIonChange={(e) => {
                            updateFormDoc(e.target.value, 'description');
                        }}
                        placeholder='Description'
                        value={shapeForm.description}
                    />
                </IonItem>

                <br />
                <div>
                    <IonButton type='submit'>Submit</IonButton>
                    <IonButton onClick={back}>Back</IonButton>
                    <br />
                </div>
            </div>
        </form>
    );
}

export default ShapeInfoForm;
