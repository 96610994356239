import {
    appStore,
    toggleStore,
    permitsStore,
    permitFormStore,
    addressFormStore,
} from '../../store/Store';
import { useEffect } from 'react';
import { IonAlert, IonIcon } from '@ionic/react';
import '../../theme/Activity.css';
import {
    fetchPermits,
    deletePermitData,
    cancelSub,
    reactivateSub,
    refundPermit,
    updatePermitInspect,
} from '../../firebaseConfig';
import {
    createOutline,
    trashOutline,
    personRemoveOutline,
    personAddOutline,
    backspaceOutline,
    radioButtonOn,
    documentOutline,
} from 'ionicons/icons';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import PermitForm from './PermitForm';
function AdminPermitList() {
    const deletePermitToggle = toggleStore.useState((s) => s.deletePermitToggle);
    const refundToggle = toggleStore.useState((s) => s.refundToggle);
    const reactivateSubToggle = toggleStore.useState((s) => s.reactivateSubToggle);
    const inspectToggle = toggleStore.useState((s) => s.inspectToggle);
    const inspectAlertMsg = toggleStore.useState((s) => s.inspectAlertMsg);
    const spinnerLoading = appStore.useState((s) => s.spinnerLoading);
    const cancelSubToggle = toggleStore.useState((s) => s.cancelSubToggle);
    const permitFormToggle = toggleStore.useState((s) => s.permitFormToggle);
    const currentPermit = permitsStore.useState((s) => s.currentPermit);
    const currentPermitId = permitsStore.useState((s) => s.currentPermitId);
    const permits = permitsStore.useState((s) => s.permits);
    const tempPermits = permitsStore.useState((s) => s.tempPermits);

    useEffect(() => {
        // fetch all permits and change them to a form that MUI datatable can use
        fetchPermits().then((data) => {
            const finalDataArr = [];
            data.map((permit) => {
                //only show paid permit because unpaid permit are still in firestore until stripe session expired. If expired, it will automatically get deleted
                if (permit.paid) {
                    let payBtn;
                    let refundBtn;
                    let inspectBtn;
                    const permitArr = [];
                    // const editBtn = 'N/A'; // disalbe for now since edit form need some work
                    const editBtn = (
                        <IonIcon
                            onClick={() => {
                                editPermit(permit);
                            }}
                            icon={createOutline}
                        />
                    );
                    const deleteBtn = (
                        <IonIcon
                            id={permit.id}
                            onClick={() => {
                                deletePermit(permit);
                            }}
                            icon={trashOutline}
                        />
                    );

                    const pdfBtn = (
                        <IonIcon
                            id={permit.id}
                            onClick={() => {
                                pdfGenerate(permit.id);
                            }}
                            icon={documentOutline}
                        />
                    );

                    if (permit.autoRenew === true) {
                        payBtn = (
                            <IonIcon
                                id={permit.id}
                                onClick={() => {
                                    openCancelSubAlert(permit);
                                }}
                                style={{ color: 'red' }}
                                icon={personRemoveOutline}
                            />
                        );
                    } else {
                        payBtn = (
                            <IonIcon
                                id={permit.id}
                                onClick={() => {
                                    openReactivateSubAlert(permit);
                                    // reactivateSubscription(permit);
                                }}
                                style={{ color: 'red' }}
                                icon={personAddOutline}
                            />
                        );
                    }
                    if (permit.inspected === true) {
                        inspectBtn = (
                            <IonIcon
                                id={permit.id}
                                onClick={() => {
                                    openInspectAlert(permit);
                                }}
                                style={{ color: 'green' }}
                                icon={radioButtonOn}
                            />
                        );
                    } else {
                        inspectBtn = (
                            <IonIcon
                                id={permit.id}
                                onClick={() => {
                                    openInspectAlert(permit);
                                    // reactivateSubscription(permit);
                                }}
                                style={{ color: 'red' }}
                                icon={radioButtonOn}
                            />
                        );
                    }
                    if (permit.refundable) {
                        refundBtn = (
                            <IonIcon
                                id={permit.id}
                                onClick={() => {
                                    openRefundAlert(permit);
                                }}
                                icon={backspaceOutline}
                            />
                        );
                    } else {
                        refundBtn = 'N/A';
                    }
                    permitArr.push(
                        permit.first + ' ' + permit.last,
                        permit.phone,
                        permit.address,
                        permit.expired,
                        permit.type,
                        inspectBtn,
                        pdfBtn
                    );
                    finalDataArr.push(permitArr);
                }
            });

            permitsStore.update((s) => {
                s.permits = data;
                s.tempPermits = finalDataArr;
            });
        });
    }, []);
    const pdfGenerate = (id) => {
        window.open(`/permit/${id}`);
    };
    const openInspectAlert = (permit) => {
        permitsStore.update((s) => {
            s.currentPermit = permit;
        });
        if (permit.inspected) {
            toggleStore.update((s) => {
                s.inspectAlertMsg = 'mark permit as invalid';
                s.inspectToggle = true;
            });
        } else {
            toggleStore.update((s) => {
                s.inspectAlertMsg = 'mark permit as valid';
                s.inspectToggle = true;
            });
        }
    };

    const updateInspect = (permit) => {
        const id = permit.id;
        const inspected = !permit.inspected;
        updatePermitInspect(id, inspected);
    };

    const editPermit = (data) => {
        const result = data.address.split(',');
        toggleStore.update((s) => {
            s.permitFormToggle = true;
            s.permitAddressToggle = true;
        });
        permitFormStore.update((s) => {
            s.permitForm = data;
        });
        addressFormStore.update((s) => {
            s.addressForm = { ...s.addressForm, address: `${result[0]},` };
            s.addressForm = { ...s.addressForm, city: `${result[1]},` };
            s.addressForm = { ...s.addressForm, province: `${result[2]},` };
        });
        permitsStore.update((s) => {
            s.currentPermitId = data.id;
        });
    };
    const closeDeleteAlert = () => {
        toggleStore.update((s) => {
            s.deletePermitToggle = false;
        });
        permitsStore.update((s) => {
            s.currentPermit = {};
            s.currentPermitId = '';
        });
    };
    const deletePermit = (permit) => {
        permitsStore.update((s) => {
            s.currentPermit = permit;
        });
        toggleStore.update((s) => {
            s.deletePermitToggle = true;
        });
        permitsStore.update((s) => {
            s.currentPermitId = permit.id;
        });
    };
    const closeInspectAlert = () => {
        toggleStore.update((s) => {
            s.inspectToggle = false;
        });
    };
    const deletePermitWithID = () => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });

        // if the deleted permit is subscribed, also cancel sub otherwise just delete it from firestore
        if (currentPermit.subscriptionId) {
            deletePermitData(currentPermitId);
            const filterDeletedPermit = tempPermits.filter(
                (data) => data[data.length - 1] !== currentPermitId
            );
            permitsStore.update((s) => {
                s.tempPermits = filterDeletedPermit;
            });
            cancelSub(currentPermit).then((window.location.href = '/permits'));
        } else {
            deletePermitData(currentPermitId);
            const filterDeletedPermit = tempPermits.filter(
                (data) => data[data.length - 1] !== currentPermitId
            );
            permitsStore.update((s) => {
                s.tempPermits = filterDeletedPermit;
            });
        }
    };
    // setup mui theme
    let theme = createTheme();
    theme = responsiveFontSizes(theme);
    const columns = ['Name', 'Phone', 'Address', 'Expired', 'Type', 'Inspect', 'PDF'];

    const deleteSub = (permit) => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });
        //render out change without refresh
        const id = permit.id;
        const tempPermits = [...permits];
        const objIndex = tempPermits.findIndex((obj) => obj.id === id);
        const targetPermit = { ...permits[objIndex] };
        targetPermit.autoRenew = !targetPermit.autoRenew;
        tempPermits[objIndex] = targetPermit;
        permitsStore.update((s) => {
            s.permits = tempPermits;
        });

        cancelSub(permit).then((data) => {
            appStore.update((s) => {
                s.loadingSpinner = true;
            });
            window.location.href = '/permits';
        });
    };
    const reactivateSubscription = (permit) => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });

        //render out change without refresh
        const id = permit.id;
        const tempPermits = [...permits];
        const objIndex = tempPermits.findIndex((obj) => obj.id === id);
        const targetPermit = { ...permits[objIndex] };
        targetPermit.autoRenew = !targetPermit.autoRenew;
        tempPermits[objIndex] = targetPermit;
        permitsStore.update((s) => {
            s.permits = tempPermits;
        });

        reactivateSub(permit).then((data) => {
            appStore.update((s) => {
                s.loadingSpinner = true;
            });
            window.location.href = '/permits';
        });
    };

    const closeReactivateSubAlert = () => {
        toggleStore.update((s) => {
            s.reactivateSubToggle = false;
        });
        permitsStore.update((s) => {
            s.currentPermit = {};
        });
    };
    const openReactivateSubAlert = (permit) => {
        toggleStore.update((s) => {
            s.reactivateSubToggle = true;
        });
        permitsStore.update((s) => {
            s.currentPermit = permit;
        });
    };

    const closeCancelSubAlert = () => {
        toggleStore.update((s) => {
            s.cancelSubToggle = false;
        });
        permitsStore.update((s) => {
            s.currentPermit = {};
        });
        // appStore.update((s) => {
        //   s.subscribeLoading = true;
        // });
    };
    const openCancelSubAlert = (permit) => {
        toggleStore.update((s) => {
            s.cancelSubToggle = true;
        });
        permitsStore.update((s) => {
            s.currentPermit = permit;
        });
    };

    const closePayPermitAlert = () => {
        permitsStore.update((s) => {
            s.currentPermit = {};
        });
    };
    const closeRefundAlert = () => {
        permitsStore.update((s) => {
            s.currentPermit = {};
        });
        toggleStore.update((s) => {
            s.refundToggle = false;
        });
    };
    const confirmRefundPermit = async (permit) => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });
        await refundPermit(permit);
        window.location.href = '/permits';
    };
    const openRefundAlert = (permit) => {
        permitsStore.update((s) => {
            s.currentPermit = permit;
        });
        toggleStore.update((s) => {
            s.refundToggle = true;
        });
    };
    return (
        <>
            {permitFormToggle && <PermitForm />}
            {tempPermits && !permitFormToggle && !spinnerLoading && (
                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        title={'All Permits'}
                        data={tempPermits}
                        columns={columns}
                        options={{
                            selectableRows: 'none', // <===== will turn off checkboxes in rows
                            print: false,
                            viewColumns: false,
                        }}
                    />
                </ThemeProvider>
            )}
            {!tempPermits && !permitFormToggle && !spinnerLoading && (
                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        title={'All Permits'}
                        columns={columns}
                        options={{
                            selectableRows: 'none', // <===== will turn off checkboxes in rows
                            print: false,
                            viewColumns: false,
                        }}
                    />
                </ThemeProvider>
            )}
            <IonAlert
                isOpen={deletePermitToggle}
                onDidDismiss={closeDeleteAlert}
                header={'Confirm'}
                message={'Are you sure you want delete this permit???'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Delete',

                        handler: () => {
                            deletePermitWithID();
                        },
                    },
                ]}
            />

            <IonAlert
                isOpen={reactivateSubToggle}
                onDidDismiss={closeReactivateSubAlert}
                header={'Confirm'}
                message={'Do you wish to activate subscription for this permit?'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Activate',

                        handler: () => {
                            reactivateSubscription(currentPermit);
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={cancelSubToggle}
                onDidDismiss={closeCancelSubAlert}
                header={'Confirm'}
                message={'Do you wish to cancel subscription for this permit?'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Cancel',

                        handler: () => {
                            deleteSub(currentPermit);
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={refundToggle}
                onDidDismiss={closeRefundAlert}
                header={'Confirm'}
                message={'Do you wish to refund this permit?'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Confirm',

                        handler: () => {
                            confirmRefundPermit(currentPermit);
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={inspectToggle}
                onDidDismiss={closeInspectAlert}
                header={'Confirm'}
                message={inspectAlertMsg}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Confirm',

                        handler: () => {
                            updateInspect(currentPermit);
                        },
                    },
                ]}
            />
        </>
    );
}

export default AdminPermitList;
