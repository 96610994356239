import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonImg,
    IonCard,
    IonLoading,
    IonItem,
    IonLabel,
    IonInput,
} from '@ionic/react';
import { useEffect } from 'react';
import { menu } from 'ionicons/icons';
import '../../theme/Settings.css';
import 'bootstrap/dist/css/bootstrap.css';
import WidgetMenu from '../../components/widget/WidgetMenu';
import {
    settingsToggleStore,
    widgetControlStore,
    profileFormStore,
    rulesStore,
    toggleStore,
    appStore,
} from '../../store/Store';
import { fetchProfile, updateUserName } from '../../firebaseConfig';
import logo from '../../images/firepermitonlinelogo.png';
import WeatherWidgetControl from '../../components/widget/WeatherWidgetControl';
import EmergencyWidgetControl from '../../components/widget/EmergencyWidgetControl';
import RiskWidgetControl from '../../components/widget/RiskWidgetControl';
function WidgetControl() {
    const username = widgetControlStore.useState((s) => s.username);
    const userInfo = appStore.useState((s) => s.userInfo);
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    const isRiskWidget = settingsToggleStore.useState((s) => s.isRiskWidget);
    const isEmergency = settingsToggleStore.useState((s) => s.isEmergency);
    const isWeather = settingsToggleStore.useState((s) => s.isWeather);
    const usernameNotVerify = toggleStore.useState((s) => s.usernameNotVerify);
    const usernameNotUnique = toggleStore.useState((s) => s.usernameNotUnique);
    useEffect(() => {
        fetchProfile('parrysound').then((data) => {
            if (data) {
                profileFormStore.update((s) => {
                    s.settingsForm = data;
                });
                profileFormStore.update((s) => {});
            }
        });
    }, []);

    const clickWeather = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = false;
            s.isEmergency = false;
            s.isWeather = true;
        });
    };

    const clickRisk = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = true;
            s.isEmergency = false;
            s.isWeather = false;
        });
    };

    const validateUsername = () => {
        toggleStore.update((s) => {
            s.usernameNotUnique = false;
        });
        if (!username) {
            toggleStore.update((s) => {
                s.usernameNotVerify = true;
            });
        } else if (!/[^a-zA-Z]/.test(username)) {
            toggleStore.update((s) => {
                s.usernameNotVerify = false;
            });

            let newUsername = username.toLowerCase();
            updateUserName(userInfo.id, newUsername).then((res) => {
                if (!res) {
                    toggleStore.update((s) => {
                        s.usernameNotUnique = true;
                    });
                }
            });
        } else {
            toggleStore.update((s) => {
                s.usernameNotVerify = true;
            });
        }
    };

    const clickEmergency = () => {
        settingsToggleStore.update((s) => {
            s.isRiskWidget = false;
            s.isEmergency = true;
            s.isWeather = false;
        });
    };

    const changeUserName = (e) => {
        widgetControlStore.update((s) => {
            s.username = e;
        });
    };

    if (userInfo.username) {
        return (
            <IonApp>
                <IonSplitPane when='lg' contentId='main-content'>
                    <IonMenu contentId='main-content'>
                        <IonHeader>
                            <IonToolbar color='primary-contrast'>
                                <IonImg src={logo} />
                                <IonButtons slot='start'>
                                    <IonMenuToggle>
                                        <IonButton>
                                            {' '}
                                            <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                        </IonButton>
                                    </IonMenuToggle>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent>
                            <WidgetMenu />
                        </IonContent>
                    </IonMenu>

                    <div className='ion-page' id='main-content'>
                        <IonHeader>
                            <IonToolbar color='primary'>
                                <IonButtons slot='start'>
                                    <IonMenuToggle>
                                        <IonButton>
                                            {' '}
                                            <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                        </IonButton>
                                    </IonMenuToggle>
                                </IonButtons>
                                <IonTitle></IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent className='ion-padding'>
                            <IonLoading
                                isOpen={loadingSpinner}
                                message={'Processing...'}
                                duration={50000}
                            />
                            <IonCard>
                                <div className='container'>
                                    <div className='row justify-content-center'>
                                        <div className='col-12 col-lg-10 col-xl-8 mx-auto'>
                                            <br />
                                            <div className='my-4'>
                                                <ul
                                                    className='nav nav-tabs mb-4'
                                                    id='myTab'
                                                    role='tablist'
                                                >
                                                    <li className='nav-item'>
                                                        <p
                                                            className={
                                                                isRiskWidget
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            id='contact-tab'
                                                            data-toggle='tab'
                                                            onClick={clickRisk}
                                                            role='tab'
                                                            aria-controls='contact'
                                                            aria-selected='false'
                                                        >
                                                            Risk Widget
                                                        </p>
                                                    </li>
                                                    <li className='nav-item'>
                                                        <p
                                                            className={
                                                                isWeather
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            id='contact-tab'
                                                            data-toggle='tab'
                                                            onClick={clickWeather}
                                                            role='tab'
                                                            aria-controls='contact'
                                                            aria-selected='false'
                                                        >
                                                            Weather Widget
                                                        </p>
                                                    </li>

                                                    <li className='nav-item'>
                                                        <p
                                                            className={
                                                                isEmergency
                                                                    ? 'nav-link active'
                                                                    : 'nav-link'
                                                            }
                                                            id='contact-tab'
                                                            data-toggle='tab'
                                                            onClick={clickEmergency}
                                                            role='tab'
                                                            aria-controls='contact'
                                                            aria-selected='false'
                                                        >
                                                            Emergency Notification Widget
                                                        </p>
                                                    </li>
                                                </ul>

                                                {isRiskWidget && (
                                                    <>
                                                        <RiskWidgetControl />
                                                        <br />
                                                    </>
                                                )}
                                                {isWeather && (
                                                    <>
                                                        <WeatherWidgetControl />
                                                        <br />
                                                    </>
                                                )}
                                                {isEmergency && (
                                                    <>
                                                        <EmergencyWidgetControl />
                                                        <br />
                                                    </>
                                                )}

                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </IonCard>
                        </IonContent>
                    </div>
                </IonSplitPane>
            </IonApp>
        );
    } else {
        return (
            <IonApp>
                <IonSplitPane when='lg' contentId='main-content'>
                    <IonMenu contentId='main-content'>
                        <IonHeader>
                            <IonToolbar color='primary-contrast'>
                                <IonImg src={logo} />
                                <IonButtons slot='start'>
                                    <IonMenuToggle>
                                        <IonButton>
                                            {' '}
                                            <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                        </IonButton>
                                    </IonMenuToggle>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent>
                            <WidgetMenu />
                        </IonContent>
                    </IonMenu>

                    <div className='ion-page' id='main-content'>
                        <IonHeader>
                            <IonToolbar color='primary'>
                                <IonButtons slot='start'>
                                    <IonMenuToggle>
                                        <IonButton>
                                            {' '}
                                            <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                        </IonButton>
                                    </IonMenuToggle>
                                </IonButtons>
                                <IonTitle></IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent>
                            <IonLoading
                                isOpen={loadingSpinner}
                                message={'Processing...'}
                                duration={50000}
                            />
                            <IonItem className='ion-padding'>
                                <IonLabel>Username:</IonLabel>
                                <IonInput
                                    onIonChange={(e) => {
                                        changeUserName(e.target.value);
                                    }}
                                    required
                                    placeholder='Enter a unique username'
                                    // value={weatherAddress}
                                />

                                <IonButton
                                    onClick={() => {
                                        validateUsername();
                                    }}
                                >
                                    Save
                                </IonButton>
                            </IonItem>

                            <p className={usernameNotVerify ? 'err' : 'hide'}>
                                &nbsp; &nbsp; &nbsp; Please only enter alphabets
                            </p>
                            <p className={usernameNotUnique ? 'err' : 'hide'}>
                                &nbsp; &nbsp; &nbsp; This username has already been taken, please
                                try again
                            </p>
                            <br />
                        </IonContent>
                    </div>
                </IonSplitPane>
            </IonApp>
        );
    }
}

export default WidgetControl;
