import { Document, Page } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import { useParams } from 'react-router-dom';
import { permitsStore, profileFormStore, rulesStore } from '../../store/Store';
import { useEffect } from 'react';
import { fetchPermitWithID } from '../../firebaseConfig';
import '../../theme/PDFDoc.css';
import { useStoreState } from 'pullstate';

const PDFDoc = () => {
    const permit = permitsStore.useState((s) => s.permit);
    const rulesInDraft = rulesStore.useState((s) => s.rulesInDraft);
    const permitIdForPDF = permitsStore.useState((s) => s.permitIdForPDF);
    const params = useParams();
    const mapsAPI = useStoreState(profileFormStore);
    let id;
    if (permitIdForPDF) {
        id = permitIdForPDF;
    } else {
        id = params.id;
    }
    useEffect(() => {
        fetchPermitWithID(id).then((data) => {
            permitsStore.update((s) => {
                s.permit = data;
            });
        });
    });
    let html = ``;

    if (permit.id) {
        html = `<html>
    <body>
      <style>
      h1 {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
      }
      h2 {
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
      }
      h3 {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      p {
        margin: 5px;
        text-align: center;
        margin-left: 10px;
        margin-right: 10px;
      }
      #permit-title {
        color: red;
        margin-bottom:0
      }
      #permit-desc {
        margin: 5px;
        text-align: left;
      }
      #logo-img {
        width: 150;
        height: 150;
        display: block;
        margin: 0;
      }
      #map-img {
        width: 500;
        height: 250;

      }

        </style>
        <p>
        <img id="logo-img" src="https://firebasestorage.googleapis.com/v0/b/firepermitonline.appspot.com/o/Parry%20Sound%20FD%20LOGO.png?alt=media&token=27d39d16-0ed3-4ae5-8a6c-6d6d533b495a" />
        </p>
      <h2 id="permit-title">FIRE PERMIT</h2>
      <h2>${permit.inspected ? 'Inspected' : 'Not Yet Inspected. Burning Not Authorized.'}</h2>
      <p>${permit.date_created.slice(0, 4)}</p>
      <h3 >Type: <span>${permit.type}</span></h3>
      <h3 >Applicant: <span>${permit.first}${permit.last}</span></h3>
      <h3 >Valid: <span>${permit.date_created} to ${permit.expired}</span></h3>
      <h3 >Ref#: <span>${permit.id}</span></h3>
      <h3 >Burn Location: <span>${permit.address}, as shown below</span></h3>
  <p>
      <img id="map-img" src=https://maps.googleapis.com/maps/api/staticmap?center=${
          permit.burnCoord.lat
      },${permit.burnCoord.lng}&zoom=18&size=1000x400&maptype=satellite&markers=color:red%7C${
            permit.burnCoord.lat
        },${permit.burnCoord.lng}&key=${mapsAPI} />
  </p>

      <br/>
      <br/>
      `;
    }

    if (rulesInDraft) {
        const rules = JSON.parse(rulesInDraft).blocks;

        //add a <br/> to the end and start so that rules[index+1].type does not === undefined
        if (rules[0].type === 'ordered-list-item' || rules[0].type === 'unordered-list-item') {
            rules.unshift({
                key: '1uug522435f',
                text: '',
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {},
            });
        }
        rules.push({
            key: '1uug5235f',
            text: '',
            type: 'unstyled',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
        });

        //to find out when an unorder list or order list end
        rules.map((rule) => {
            const index = rules.indexOf(rule);
            if (rule.text === '') {
                html = html + `<br/>`;
            } else if (rule.text && rule.type === 'header-one') {
                html = html + `<h1 >${rule.text}</h1>`;
            } else if (rule.text && rule.type === 'header-two') {
                html = html + `<h2 >${rule.text}</h2>`;
            } else if (rule.text && rule.type === 'unstyled') {
                html = html + `<p">${rule.text}</p>`;
            } else if (
                rule.text &&
                rule.type === 'ordered-list-item' &&
                rules[index - 1].type !== 'ordered-list-item' &&
                rules[index + 1].type !== 'ordered-list-item'
            ) {
                html = html + `<ol"><li>${rule.text}</li></ol>`;
            } else if (
                rule.text &&
                rule.type === 'ordered-list-item' &&
                rules[index - 1].type !== 'ordered-list-item' &&
                rules[index + 1].type === 'ordered-list-item'
            ) {
                html = html + `<ol"><li>${rule.text}</li>`;
            } else if (
                rule.text &&
                rule.type === 'ordered-list-item' &&
                rules[index - 1].type === 'ordered-list-item' &&
                rules[index + 1].type === 'ordered-list-item'
            ) {
                html = html + `<li>${rule.text}</li>`;
            } else if (
                rule.text &&
                rule.type === 'ordered-list-item' &&
                rules[index - 1].type === 'ordered-list-item' &&
                rules[index + 1].type !== 'ordered-list-item'
            ) {
                html = html + `<li>${rule.text}</li></ol>`;
            } else if (
                rule.text &&
                rule.type === 'unordered-list-item' &&
                rules[index - 1].type !== 'unordered-list-item' &&
                rules[index + 1].type !== 'unordered-list-item'
            ) {
                html = html + `<ul"><li>${rule.text}</li></ul>`;
            } else if (
                rule.text &&
                rule.type === 'unordered-list-item' &&
                rules[index - 1].type !== 'unordered-list-item' &&
                rules[index + 1].type === 'unordered-list-item'
            ) {
                html = html + `<ul"><li>${rule.text}</li>`;
            } else if (
                rule.text &&
                rule.type === 'unordered-list-item' &&
                rules[index - 1].type === 'unordered-list-item' &&
                rules[index + 1].type === 'unordered-list-item'
            ) {
                html = html + `<li>${rule.text}</li>`;
            } else if (
                rule.text &&
                rule.type === 'unordered-list-item' &&
                rules[index - 1].type === 'unordered-list-item' &&
                rules[index + 1].type !== 'unordered-list-item'
            ) {
                html = html + `<li>${rule.text}</li></ul>`;
            }
        });
    }

    html =
        html +
        `</body> 
          </html>`;

    return (
        <PDFViewer className={permitIdForPDF ? 'auto' : 'full'}>
            <Document>
                <Page>
                    {!permit.id && <Html style={{ fontSize: 12 }}>{`<h1>Loading...</h1>`}</Html>}
                    {permit.id && <Html style={{ fontSize: 12 }}>{html}</Html>}
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default PDFDoc;
