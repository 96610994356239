import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonImg,
    IonLoading,
} from '@ionic/react';
import { menu } from 'ionicons/icons';
import ApplyPermit from '../../components/user/ApplyPermit';
import UserMenu from '../../components/user/UserMenu';
import { appStore } from '../../store/Store';
import logo from '../../images/firepermitonlinelogo.png';
import '../../theme/LandingPage.css';
import Landing from '../../components/common/Landing';
function LandingPage() {
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    const userId = appStore.useState((s) => s.userId);
    if (userId) {
        return (
            <IonApp>
                <IonSplitPane when='lg' contentId='main-content'>
                    <IonMenu contentId='main-content'>
                        <IonHeader>
                            <IonToolbar color='primary-contrast'>
                                <IonImg src={logo} />
                                <IonButtons slot='start'>
                                    <IonMenuToggle>
                                        <IonButton>
                                            <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                        </IonButton>
                                    </IonMenuToggle>
                                </IonButtons>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent>
                            <UserMenu />
                        </IonContent>
                    </IonMenu>

                    <div className='ion-page' id='main-content'>
                        <IonHeader>
                            <IonToolbar color='primary'>
                                <IonButtons slot='start'>
                                    <IonMenuToggle>
                                        <IonButton>
                                            <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                        </IonButton>
                                    </IonMenuToggle>
                                </IonButtons>
                                <IonTitle></IonTitle>
                            </IonToolbar>
                        </IonHeader>

                        <IonContent className='ion-padding'>
                            <IonLoading
                                isOpen={loadingSpinner}
                                message={'Processing...'}
                                duration={50000}
                            />
                            <ApplyPermit />
                        </IonContent>
                    </div>
                </IonSplitPane>
            </IonApp>
        );
    } else {
        return (
            <IonApp>
                {' '}
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonMenuToggle>
                                <IonButton>
                                    {' '}
                                    <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                </IonButton>
                            </IonMenuToggle>
                        </IonButtons>
                        <IonTitle>
                            {' '}
                            <div className='landing-title'>
                                <span className='landing-title-text'>
                                    Parry Sound Fire and Rescue Department - FirePermits.Online
                                </span>
                            </div>
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className='ion-padding'>
                    <IonLoading
                        isOpen={loadingSpinner}
                        message={'Processing...'}
                        duration={50000}
                    />
                    <Landing />
                </IonContent>{' '}
            </IonApp>
        );
    }
}

export default LandingPage;
