import React, { useEffect, useState } from 'react';
import {
    getFirestore,
    collection,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    onSnapshot,
} from 'firebase/firestore';
import MUIDataTable from 'mui-datatables';
import { TextField, Button, IconButton, Alert, Snackbar } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function StreetsEditor() {
    const municipality = 'parrysound'; // Hardcoded municipality for now
    const [streets, setStreets] = useState([]);
    const [newStreet, setNewStreet] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success'); // 'success' or 'error'
    const [alertOpen, setAlertOpen] = useState(false);

    const firestore = getFirestore();

    // Fetch streets in real-time and sort alphabetically
    useEffect(() => {
        const unsubscribe = onSnapshot(
            collection(firestore, `municipalities/${municipality}/streets`),
            (snapshot) => {
                const streetsList = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    name: doc.data().name || '', // Default to empty string if name is undefined
                }));

                // Sort streets alphabetically by name with safety checks
                streetsList.sort((a, b) => {
                    if (!a.name || !b.name) return 0; // If either name is undefined, treat them as equal
                    return a.name.localeCompare(b.name);
                });

                setStreets(streetsList);
            }
        );

        return () => unsubscribe();
    }, [firestore, municipality]);

    // Handle alert closing
    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    // Show alert message
    const showAlert = (message, severity = 'success') => {
        setResponseMessage(message);
        setAlertSeverity(severity); // Set severity: 'success' or 'error'
        setAlertOpen(true);
        setTimeout(() => {
            setAlertOpen(false);
        }, 3000); // Hide alert after 3 seconds
    };

    // Add a new street
    const handleAddStreet = async () => {
        if (newStreet.trim() === '') {
            showAlert('Street name cannot be empty', 'error');
            return;
        }

        // Convert the street name to uppercase
        const formattedStreetName = newStreet.trim().toUpperCase();

        try {
            await addDoc(collection(firestore, `municipalities/${municipality}/streets`), {
                name: formattedStreetName,
            });
            showAlert('Street added successfully', 'success');
            setNewStreet(''); // Clear the input
        } catch (error) {
            console.error('Error adding street:', error);
            showAlert('Failed to add street', 'error');
        }
    };

    // Update an existing street
    const handleUpdateStreet = async (id, updatedName) => {
        try {
            const streetDocRef = doc(firestore, `municipalities/${municipality}/streets`, id);
            await updateDoc(streetDocRef, { name: updatedName.toUpperCase() });
            showAlert('Street updated successfully', 'success');
        } catch (error) {
            console.error('Error updating street:', error);
            showAlert('Failed to update street', 'error');
        }
    };

    // Delete a street
    const handleDeleteStreet = async (id) => {
        try {
            const streetDocRef = doc(firestore, `municipalities/${municipality}/streets`, id);
            await deleteDoc(streetDocRef);
            showAlert('Street deleted successfully', 'success');
        } catch (error) {
            console.error('Error deleting street:', error);
            showAlert('Failed to delete street', 'error');
        }
    };

    // Columns for MUIDataTable
    const columns = [
        {
            name: 'name',
            label: 'Street Name',
            options: {
                customBodyRender: (value, tableMeta) => {
                    return (
                        <TextField
                            value={value}
                            onChange={(e) =>
                                handleUpdateStreet(tableMeta.rowData[1], e.target.value)
                            }
                            fullWidth
                        />
                    );
                },
            },
        },
        {
            name: 'id',
            label: 'Actions',
            options: {
                customBodyRender: (value) => {
                    return (
                        <IconButton onClick={() => handleDeleteStreet(value)}>
                            <DeleteIcon />
                        </IconButton>
                    );
                },
            },
        },
    ];

    // Options for MUIDataTable
    const options = {
        selectableRows: 'none', // Disable row selection
        print: false,
        download: false,
        viewColumns: false,
        filter: false,
    };

    return (
        <div>
            <h1>Add or Manage Streets</h1>

            <div style={{ marginBottom: '20px' }}>
                <TextField
                    label='New Street Name'
                    value={newStreet}
                    onChange={(e) => setNewStreet(e.target.value)}
                    variant='outlined'
                    fullWidth
                />
                <Button
                    onClick={handleAddStreet}
                    variant='contained'
                    color='primary'
                    style={{ marginTop: '10px' }}
                >
                    Add Street
                </Button>
            </div>

            <Snackbar
                open={alertOpen}
                autoHideDuration={3000}
                onClose={handleAlertClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Adjust position for visibility
            >
                <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
                    {responseMessage}
                </Alert>
            </Snackbar>

            <MUIDataTable
                title={'Street List'}
                data={streets}
                columns={columns}
                options={options}
            />
        </div>
    );
}

export default StreetsEditor;
