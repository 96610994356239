import {
    cancelSub,
    deletePermitData,
    reactivateSub,
    refundPermit,
    updatePermitInspect,
    updatePermitNotes,
} from '../firebaseConfig';
import {
    addressFormStore,
    appStore,
    permitFormStore,
    permitsStore,
    toggleStore,
} from '../store/Store';
import { formatPermitData } from './permitListHelpers';

export const openCancelSubAlert = (permit) => {
    toggleStore.update((s) => {
        s.cancelSubToggle = true;
    });
    permitsStore.update((s) => {
        s.currentPermit = permit;
    });
};

export const openReactivateSubAlert = (permit) => {
    toggleStore.update((s) => {
        s.reactivateSubToggle = true;
    });
    permitsStore.update((s) => {
        s.currentPermit = permit;
    });
};

export const openInspectAlert = (permit) => {
    permitsStore.update((s) => {
        s.currentPermit = permit;
    });
    toggleStore.update((s) => {
        s.inspectAlertMsg = permit.inspected ? 'mark permit as invalid' : 'mark permit as valid';
        s.inspectToggle = true;
    });
};

export const openRefundAlert = (permit) => {
    permitsStore.update((s) => {
        s.currentPermit = permit;
    });
    toggleStore.update((s) => {
        s.refundToggle = true;
    });
};

export const editPermit = (permit) => {
    const [address, city, province] = permit.address.split(',');

    toggleStore.update((s) => {
        s.permitFormToggle = true;
        s.permitAddressToggle = true;
    });

    permitFormStore.update((s) => {
        s.permitForm = permit;
    });

    addressFormStore.update((s) => {
        s.addressForm = {
            ...s.addressForm,
            address: `${address},`,
            city: `${city},`,
            province: `${province},`,
        };
    });

    permitsStore.update((s) => {
        s.currentPermitId = permit.id;
    });
};

export const deletePermit = (permit) => {
    permitsStore.update((s) => {
        s.currentPermit = permit;
    });
    toggleStore.update((s) => {
        s.deletePermitToggle = true;
    });
    permitsStore.update((s) => {
        s.currentPermitId = permit.id;
    });
};

export const pdfGenerate = (id) => {
    window.open(`/permit/${id}`);
};

export const handleDeletePermit = async (permitId, currentPermit, tempPermits) => {
    appStore.update((s) => {
        s.loadingSpinner = true;
    });

    if (currentPermit.subscriptionId) {
        await deletePermitData(permitId);
        await cancelSub(currentPermit);
    } else {
        await deletePermitData(permitId);
    }

    const updatedPermits = tempPermits.filter((data) => data[data.length - 1] !== permitId);

    permitsStore.update((s) => {
        s.tempPermits = updatedPermits;
    });

    appStore.update((s) => {
        s.loadingSpinner = false;
    });

    window.location.href = '/permits';
};

export const handleInspectPermit = async (permit) => {
    const { id, inspected } = permit;
    await updatePermitInspect(id, !inspected);

    // Update local state
    const updatedPermits = permitsStore
        .getRawState()
        .permits.map((p) => (p.id === id ? { ...p, inspected: !inspected } : p));

    permitsStore.update((s) => {
        s.permits = updatedPermits;
    });
};

export const handleSubscriptionChange = async (permit, action) => {
    appStore.update((s) => {
        s.loadingSpinner = true;
    });

    const { id } = permit;
    const updatedPermits = permitsStore
        .getRawState()
        .permits.map((p) => (p.id === id ? { ...p, autoRenew: action === 'activate' } : p));

    permitsStore.update((s) => {
        s.permits = updatedPermits;
    });

    if (action === 'activate') {
        await reactivateSub(permit);
    } else {
        await cancelSub(permit);
    }

    appStore.update((s) => {
        s.loadingSpinner = false;
    });

    window.location.href = '/permits';
};

export const handleRefund = async (permit) => {
    appStore.update((s) => {
        s.loadingSpinner = true;
    });

    await refundPermit(permit);

    appStore.update((s) => {
        s.loadingSpinner = false;
    });

    window.location.href = '/permits';
};

export const openNotesModal = (permit) => {
    console.log('Opening notes modal for permit:', permit);
    toggleStore.update((s) => {
        s.notesModalToggle = true;
    });
    permitsStore.update((s) => {
        s.currentPermit = permit;
    });
};

export const handleSaveNotes = async (permitId, notes) => {
    console.log('Attempting to save notes for permit ID:', permitId);
    try {
        await updatePermitNotes(permitId, notes);

        console.log('Notes saved successfully');

        // Update local state
        permitsStore.update((s) => {
            const updatedPermits = s.permits.map((p) =>
                p.id === permitId ? { ...p, notes: notes } : p
            );
            s.permits = updatedPermits;
            s.tempPermits = formatPermitData(updatedPermits);
        });

        toggleStore.update((s) => {
            s.notesModalToggle = false;
        });

        // Show success message
        appStore.update((s) => {
            s.toastMessage = 'Notes saved successfully';
            s.showToast = true;
        });
    } catch (error) {
        console.error('Error saving notes:', error);

        // Show error message to the user
        appStore.update((s) => {
            s.toastMessage = `Error saving notes: ${error.message}`;
            s.showToast = true;
        });
    }
};
