// importing components for react-google-map
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import { IonButton } from '@ionic/react';
import activeLogo from '../../../images/ActiveFlameLogo.png';
import '../../../theme/Home.css';
import { useRef } from 'react';
import { modalToggleStore, coordsStore, profileFormStore } from '../../../store/Store';
import { useStoreState } from 'pullstate';
const ApplyPermit5 = () => {
    const currentBurnCoord = coordsStore.useState((s) => s.currentBurnCoord);
    const { mapsAPI } = useStoreState(profileFormStore);
    const mapRef = useRef(null);
    const onMapLoad = (map) => {
        mapRef.current = map;
    };
    const handleCenterAndBurnCoord = (e) => {
        const burnCoord = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };
        coordsStore.update((s) => {
            s.currentBurnCoord = burnCoord;
            s.mapCenterCoord = burnCoord;
        });
    };

    function handleCenter() {
        if (!mapRef.current) return;
        const lat = mapRef.current.getCenter().lat();
        const lng = mapRef.current.getCenter().lng();
        const newCenter = { lat, lng };
        coordsStore.update((s) => {
            s.mapCenterCoord = newCenter;
        });
    }
    const openSixthPage = () => {
        modalToggleStore.update((s) => {
            s.permitPage = 6;
        });
    };

    return (
        <>
            <div className='profile-main'>
                <div className='profile-App'>
                    <LoadScript
                        id='script-loader'
                        googleMapsApiKey={`${mapsAPI}&libraries=drawing,places`}
                        language='en'
                        region='us'
                    >
                        <br />
                        {currentBurnCoord && (
                            <GoogleMap
                                mapContainerClassName='App-map'
                                zoom={18}
                                mapTypeId='satellite'
                                onLoad={onMapLoad}
                                onDragEnd={handleCenter}
                                onClick={handleCenterAndBurnCoord}
                                center={currentBurnCoord}
                                version='weekly'
                                on
                            >
                                <Marker icon={activeLogo} position={currentBurnCoord} />
                                );
                            </GoogleMap>
                        )}
                    </LoadScript>
                </div>
            </div>

            <p className='ion-padding-horizontal'>
                It's important to know where the burn will take place on your property. Please tap
                or click on the map to reposition the flame to indicate where you will conduct the
                burn.
            </p>
            <div style={{ width: '100%' }}>
                <IonButton
                    className='ion-padding-horizontal'
                    shape='round'
                    expand='block'
                    color='tertiary'
                    onClick={openSixthPage}
                >
                    Continue
                </IonButton>
            </div>
        </>
    );
};

export default ApplyPermit5;
