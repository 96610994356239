import {
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
} from '@ionic/react';
import '../../theme/DashboardCounts.css';
import { permitsStore } from '../../store/Store';

function DashboardCounts() {
    const permits = permitsStore.useState((s) => s.permits);
    return (
        <>
            <IonGrid>
                <IonRow>
                    {/* <IonCol
                        className='count-card'
                        size-xs='12'
                        size-sm='12'
                        size-md='6'
                        size-lg='3'
                    >
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Total Permits</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                {!permits[0] && <h1>0</h1>}
                                {permits[0] && <h1>{permits.length}</h1>}
                            </IonCardContent>
                        </IonCard>
                    </IonCol> */}
                    <IonCol
                        className='count-card'
                        size-xs='12'
                        size-sm='12'
                        size-md='6'
                        size-lg='3'
                    >
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Total Permits</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <h1>
                                    {permits.length -
                                        permits.filter(
                                            (permit) => permit.subscriptionId === undefined
                                        ).length}
                                </h1>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    {/* <IonCol
                        className='count-card'
                        size-xs='12'
                        size-sm='12'
                        size-md='6'
                        size-lg='3'
                    >
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Non-paid Permits</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <h1>
                                    {
                                        permits.filter(
                                            (permit) => permit.subscriptionId === undefined
                                        ).length
                                    }
                                </h1>
                            </IonCardContent>
                        </IonCard>
                    </IonCol> */}
                    <IonCol
                        className='count-card'
                        size-xs='12'
                        size-sm='12'
                        size-md='6'
                        size-lg='3'
                    >
                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Inspected Permits</IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <h1>
                                    {permits.filter((permit) => permit.inspected === true).length}
                                </h1>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </>
    );
}

export default DashboardCounts;
