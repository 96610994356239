import { IonButton, IonToggle } from '@ionic/react';
import '../../../theme/Home.css';
import { saveDetailUserInfo } from '../../../firebaseConfig';
import {
    userFormStore,
    addressFormStore,
    toggleStore,
    appStore,
} from '../../../store/Store';
const Profile5 = () => {
    const addressForm = addressFormStore.useState((s) => s.addressForm);
    const userId = appStore.useState((s) => s.userId);
    const detailUserForm = userFormStore.useState((s) => s.detailUserForm);
    const manualAddress = toggleStore.useState((s) => s.manualAddress);

    const updateFormDoc = (field) => {
        if (field === 'smsSub') {
            userFormStore.update((s) => {
                s.detailUserForm = {
                    ...s.detailUserForm,
                    [field]: !detailUserForm.smsSub,
                };
            });
        } else if (field === 'emailSub') {
            userFormStore.update((s) => {
                s.detailUserForm = {
                    ...s.detailUserForm,
                    [field]: !detailUserForm.emailSub,
                };
            });
        } else if (field === 'emergencySub') {
            userFormStore.update((s) => {
                s.detailUserForm = {
                    ...s.detailUserForm,
                    [field]: !detailUserForm.emergencySub,
                };
            });
        }
    };
    const saveUserInfo = () => {
        let formAddress;
        let formCity;
        let formProvince;
        if (addressForm.address[addressForm.address.length - 1] !== ',') {
            formAddress = `${addressForm.address}, `;
        } else {
            formAddress = addressForm.address;
        }

        if (addressForm.city[addressForm.city.length - 1] !== ',') {
            formCity = `${addressForm.city}, `;
        } else {
            formCity = addressForm.city;
        }

        formProvince = addressForm.province;

        const address = `${formAddress}${formCity}${formProvince}`;

        if (manualAddress) {
            saveDetailUserInfo(detailUserForm, address, userId);
        } else {
            saveDetailUserInfo(detailUserForm, detailUserForm.address, userId);
        }
    };
    return (
        <>
            <h2 className='ion-padding'>Subscribe to Important Alerts?</h2>
            <p className='ion-padding-horizontal'>
                The Fire Department may issue alerts for changes to Fire Risk level, Burn Bans, or
                Emergencies including train derailments. You can subscribe to receive these alerts
                below. Your email and phone number will not be used for promotional or marketing
                purposes or shared with third parties.
            </p>
            <p style={{ fontWeight: 'bold' }} className='ion-padding-horizontal'>
                Fire Risk Alerts
            </p>
            <div className='profile-toggle'>
                SMS (Text Messages):
                <IonToggle
                    color='tertiary'
                    onIonChange={(e) => {
                        updateFormDoc('smsSub');
                    }}
                    checked={detailUserForm.smsSub}
                />{' '}
            </div>
            <div className='profile-toggle'>
                Emails:
                <IonToggle
                    color='tertiary'
                    onIonChange={(e) => {
                        updateFormDoc('emailSub');
                    }}
                    checked={detailUserForm.emailSub}
                />
            </div>
            <p style={{ fontWeight: 'bold' }} className='ion-padding-horizontal ion-padding-top'>
                Emergency Alerts
            </p>
            <div className='profile-toggle'>
                Emergency Broadcasts:
                <IonToggle
                    color='tertiary'
                    onIonChange={(e) => {
                        updateFormDoc('emergencySub');
                    }}
                    checked={detailUserForm.emergencySub}
                />
            </div>
            <br /> <br />
            <IonButton
                className='ion-padding-horizontal'
                shape='round'
                expand='block'
                color='tertiary'
                onClick={saveUserInfo}
            >
                Save
            </IonButton>
        </>
    );
};

export default Profile5;
