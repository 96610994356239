import React, { useEffect, useState } from 'react';
import { IonAlert, IonButton, IonContent, IonModal, IonTextarea } from '@ionic/react';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { appStore, toggleStore, permitsStore } from '../../store/Store';
import { fetchPermits } from '../../firebaseConfig';
import PermitForm from './PermitForm';

import '../../theme/Activity.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { columns, formatPermitData } from '../../helpers/permitListHelpers';
import {
    handleDeletePermit,
    handleInspectPermit,
    handleRefund,
    handleSaveNotes,
    handleSubscriptionChange,
} from '../../helpers/permitActions';

function AdminPermitList() {
    const {
        deletePermitToggle,
        refundToggle,
        reactivateSubToggle,
        inspectToggle,
        inspectAlertMsg,
        cancelSubToggle,
        permitFormToggle,
        notesModalToggle,
    } = toggleStore.useState((state) => state);

    const { spinnerLoading } = appStore.useState((state) => state);
    const { currentPermit, currentPermitId, tempPermits } = permitsStore.useState((state) => state);

    const [notes, setNotes] = useState('');
    useEffect(() => {
        fetchPermits().then((data) => {
            const finalDataArr = formatPermitData(data);
            permitsStore.update((s) => {
                s.permits = data;
                s.tempPermits = finalDataArr;
            });
        });
    }, []);
    useEffect(() => {
        if (currentPermit) {
            setNotes(currentPermit.notes || '');
        }
    }, [currentPermit]);
    const theme = responsiveFontSizes(createTheme());

    const tableOptions = {
        selectableRows: 'none',
        print: false,
        viewColumns: false,
        sortOrder: {
            name: 'Expires',
            direction: 'desc',
        },
    };

    return (
        <>
            {permitFormToggle && <PermitForm />}
            {tempPermits && !permitFormToggle && !spinnerLoading && (
                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        title={'All Permits'}
                        data={tempPermits}
                        columns={columns}
                        options={tableOptions}
                    />
                </ThemeProvider>
            )}
            <IonAlert
                isOpen={deletePermitToggle}
                onDidDismiss={() =>
                    toggleStore.update((s) => {
                        s.deletePermitToggle = false;
                    })
                }
                header={'Confirm'}
                message={'Are you sure you want delete this permit?'}
                buttons={[
                    { text: 'Cancel' },
                    {
                        text: 'Delete',
                        handler: () =>
                            handleDeletePermit(currentPermitId, currentPermit, tempPermits),
                    },
                ]}
            />
            <IonAlert
                isOpen={reactivateSubToggle}
                onDidDismiss={() =>
                    toggleStore.update((s) => {
                        s.reactivateSubToggle = false;
                    })
                }
                header={'Confirm'}
                message={'Do you wish to activate subscription for this permit?'}
                buttons={[
                    { text: 'Cancel' },
                    {
                        text: 'Activate',
                        handler: () => handleSubscriptionChange(currentPermit, 'activate'),
                    },
                ]}
            />
            <IonAlert
                isOpen={cancelSubToggle}
                onDidDismiss={() =>
                    toggleStore.update((s) => {
                        s.cancelSubToggle = false;
                    })
                }
                header={'Confirm'}
                message={'Do you wish to cancel subscription for this permit?'}
                buttons={[
                    { text: 'Cancel' },
                    {
                        text: 'Confirm',
                        handler: () => handleSubscriptionChange(currentPermit, 'cancel'),
                    },
                ]}
            />
            <IonAlert
                isOpen={refundToggle}
                onDidDismiss={() =>
                    toggleStore.update((s) => {
                        s.refundToggle = false;
                    })
                }
                header={'Confirm'}
                message={'Do you wish to refund this permit?'}
                buttons={[
                    { text: 'Cancel' },
                    { text: 'Confirm', handler: () => handleRefund(currentPermit) },
                ]}
            />
            <IonAlert
                isOpen={inspectToggle}
                onDidDismiss={() =>
                    toggleStore.update((s) => {
                        s.inspectToggle = false;
                    })
                }
                header={'Confirm'}
                message={inspectAlertMsg}
                buttons={[
                    { text: 'Cancel' },
                    { text: 'Confirm', handler: () => handleInspectPermit(currentPermit) },
                ]}
            />
            <IonModal
                isOpen={notesModalToggle}
                onDidDismiss={() =>
                    toggleStore.update((s) => {
                        s.notesModalToggle = false;
                    })
                }
            >
                <IonContent className='ion-padding'>
                    <h2>Notes for {currentPermit.address}</h2>
                    <IonTextarea
                        value={notes}
                        onIonChange={(e) => setNotes(e.detail.value)}
                        placeholder='Enter notes here...'
                    />
                    <IonButton onClick={() => handleSaveNotes(currentPermit.id, notes)}>
                        Save Notes
                    </IonButton>
                </IonContent>
            </IonModal>
        </>
    );
}

export default AdminPermitList;
