import { adminStore, adminUserFormStore, toggleStore, appStore } from '../../store/Store';
import { useEffect } from 'react';
import {
    IonRow,
    IonGrid,
    IonCol,
    IonInput,
    IonLabel,
    IonItem,
    IonSelectOption,
    IonSelect,
    IonAlert,
    IonIcon,
    IonButton,
    IonModal,
} from '@ionic/react';
import { trash } from 'ionicons/icons';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import '../../theme/AdminList.css';
import { fetchAdmins, deleteAdminAccountById, registerUser } from '../../firebaseConfig';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
function AdminList() {
    let theme = createTheme();
    theme = responsiveFontSizes(theme);
    const columns = ['Email', 'Type', 'Delete'];
    const registeredAdmin = toggleStore.useState((s) => s.registeredAdmin);
    const admins = adminStore.useState((s) => s.admins);
    const adminUserForm = adminUserFormStore.useState((s) => s.adminUserForm);
    const adminTypeEmptyToggle = toggleStore.useState((s) => s.adminTypeEmptyToggle);
    const adminId = adminStore.useState((s) => s.adminId);
    const addAdminToggle = toggleStore.useState((s) => s.addAdminToggle);
    const addAdminPwInvalid = toggleStore.useState((s) => s.addAdminPwInvalid);
    const addAdminEmailInvalid = toggleStore.useState((s) => s.addAdminEmailInvalid);
    const deleteAdminToggle = toggleStore.useState((s) => s.deleteAdminToggle);
    const openDeleteAdminAlert = (id) => {
        toggleStore.update((s) => {
            s.deleteAdminToggle = true;
        });
        adminStore.update((s) => {
            s.adminId = id;
        });
    };
    const updateFormDoc = (value, field) => {
        adminUserFormStore.update((s) => {
            s.adminUserForm = { ...s.adminUserForm, [field]: value };
        });
    };

    useEffect(() => {
        const finalDataArr = [];
        fetchAdmins().then((data) => {
            data.map((data) => {
                const adminArr = [];

                const deleteBtn = (
                    <IonIcon
                        onClick={() => {
                            openDeleteAdminAlert(data.id);
                        }}
                        className='delete-admin'
                        icon={trash}
                    />
                );
                if (data.superAdmin) {
                    adminArr.push(data.email, 'Super Admin', deleteBtn);
                } else {
                    adminArr.push(data.email, 'Admin', deleteBtn);
                }

                finalDataArr.push(adminArr);
            });
            adminStore.update((s) => {
                s.admins = finalDataArr;
            });
        });
    }, []);
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const closeDeleteAlert = () => {
        toggleStore.update((s) => {
            s.deleteAdminToggle = false;
        });
        adminStore.update((s) => {
            s.adminId = '';
        });
    };
    const deleteAdmin = () => {
        deleteAdminAccountById(adminId);
    };
    const addAdmin = () => {
        toggleStore.update((s) => {
            s.addAdminToggle = true;
        });
    };
    const backToAdminList = () => {
        toggleStore.update((s) => {
            s.addAdminToggle = false;
        });
    };
    const closeRegisteredModal = () => {
        window.location.href = '/settings';
    };
    const submitForm = async (e) => {
        e.preventDefault();
        if (validateEmail(adminUserForm.email)) {
            toggleStore.update((s) => {
                s.addAdminEmailInvalid = false;
            });
        } else {
            toggleStore.update((s) => {
                s.addAdminEmailInvalid = true;
            });
        }
        if (!adminUserForm.type) {
            toggleStore.update((s) => {
                s.adminTypeEmptyToggle = true;
            });
        } else {
            toggleStore.update((s) => {
                s.adminTypeEmptyToggle = false;
            });
        }
        if (adminUserForm.password.length < 6) {
            toggleStore.update((s) => {
                s.addAdminPwInvalid = true;
            });
        } else {
            toggleStore.update((s) => {
                s.addAdminPwInvalid = false;
            });
        }
        if (
            adminUserForm.password.length >= 6 &&
            adminUserForm.type &&
            validateEmail(adminUserForm.email)
        ) {
            appStore.update((s) => {
                s.loadingSpinner = true;
            });
            registerUser(adminUserForm).then((data) => {
                // toggleStore.update((s) => {
                //     s.registeredAdmin = true;
                // });
            });
        }
    };
    return (
        <>
            <IonModal isOpen={registeredAdmin}>
                <p className='ion-padding'>
                    Please allow us a couple minutes to create your new admin account. The account
                    will be ready to use when you see it in the admin list.
                </p>

                <div style={{ height: 200 }} />
                <IonButton
                    shape='round'
                    expand='block'
                    color='tertiary'
                    onClick={closeRegisteredModal}
                    className='ion-padding'
                >
                    Close
                </IonButton>
            </IonModal>
            {!addAdminToggle && admins && (
                <>
                    <div className='add-admin-btn'>
                        {/* <IonTitle className='ion-padding'>Permit Types </IonTitle> */}
                        <IonButton onClick={addAdmin}>Add New Admin</IonButton>
                    </div>
                    <ThemeProvider theme={theme}>
                        <MUIDataTable
                            title={'All Admins'}
                            data={admins}
                            columns={columns}
                            options={{
                                selectableRows: 'none', // <===== will turn off checkboxes in rows
                                print: false,
                                viewColumns: false,
                                elevation: 0,
                            }}
                        />
                    </ThemeProvider>
                </>
            )}

            {addAdminToggle && (
                <form className='permit-form' onSubmit={submitForm}>
                    <IonGrid className='permit-form-grid'>
                        <h2 className='ion-padding-horizontal'>Register Admin</h2>

                        <IonRow>
                            <IonCol size='8'>
                                {' '}
                                <IonItem>
                                    <IonLabel>Email:</IonLabel>
                                    <IonInput
                                        className='form-inputs'
                                        type='email'
                                        onIonChange={(e) => {
                                            updateFormDoc(e.target.value, 'email');
                                        }}
                                        required
                                        placeholder='Enter email'
                                    />
                                </IonItem>
                                <p
                                    className={
                                        addAdminEmailInvalid ? 'error-msg' : 'hide-error-msg'
                                    }
                                >
                                    The email you enter is invalid{' '}
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='8'>
                                {' '}
                                <IonItem>
                                    <IonLabel>Password:</IonLabel>
                                    <IonInput
                                        onIonChange={(e) => {
                                            updateFormDoc(e.target.value, 'password');
                                        }}
                                        required
                                        placeholder='Enter password'
                                    />
                                </IonItem>
                                <p className={addAdminPwInvalid ? 'error-msg' : 'hide-error-msg'}>
                                    Password need to be at least 6 characters.{' '}
                                </p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='8'>
                                {' '}
                                <IonItem>
                                    <IonLabel>Admin Type:</IonLabel>
                                    <IonSelect
                                        required
                                        placeholder='type'
                                        okText='Okay'
                                        cancelText='Cancel'
                                        onIonChange={(e) => {
                                            updateFormDoc(e.target.value, 'type');
                                        }}
                                    >
                                        <IonSelectOption value='admin'>Admin</IonSelectOption>
                                        <IonSelectOption value='superAdmin'>
                                            Super Admin
                                        </IonSelectOption>
                                    </IonSelect>
                                </IonItem>
                                <p
                                    className={
                                        adminTypeEmptyToggle ? 'error-msg' : 'hide-error-msg'
                                    }
                                >
                                    Please choose an admin type{' '}
                                </p>
                            </IonCol>
                        </IonRow>
                        <br />
                        <div>
                            <IonButton
                                className='ion-padding-horizontal'
                                type='submit'
                                id='permit-submit'
                            >
                                Register
                            </IonButton>
                            <IonButton
                                onClick={backToAdminList}
                                className='ion-padding-horizontal'
                                id='permit-submit'
                            >
                                Back
                            </IonButton>
                        </div>
                        <br />
                    </IonGrid>
                </form>
            )}

            <IonAlert
                isOpen={deleteAdminToggle}
                onDidDismiss={closeDeleteAlert}
                header={'Confirm'}
                message={'Are you sure you want to delete this admin?'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {
                            closeDeleteAlert();
                        },
                    },
                    {
                        text: 'Confirm',

                        handler: () => {
                            deleteAdmin();
                        },
                    },
                ]}
            />
        </>
    );
}

export default AdminList;
