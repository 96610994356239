import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonLoading,
    IonIcon,
    IonImg,
} from '@ionic/react';
import { add, menu } from 'ionicons/icons';
import '../../theme/AllPermitTypes.css';

import AdminTypeList from '../../components/admin/AdminTypeList';
import PermitTypeForm from '../../components/admin/PermitTypeForm';
import Menu from '../../components/admin/Menu';
import { toggleStore, appStore } from '../../store/Store';
import logo from '../../images/firepermitonlinelogo.png';
function AdminPermitTypes() {
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    const permitTypeFormToggle = toggleStore.useState((s) => s.permitTypeFormToggle);
    const openPermitTypeForm = () => {
        toggleStore.update((s) => {
            s.permitTypeFormToggle = true;
        });
    };
    return (
        <IonApp>
            <IonSplitPane when='lg' contentId='main-content'>
                <IonMenu contentId='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary-contrast'>
                            <IonImg src={logo} />
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <Menu />
                    </IonContent>
                </IonMenu>

                <div className='ion-page' id='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                            <IonTitle></IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent className='ion-padding'>
                        <IonLoading
                            isOpen={loadingSpinner}
                            message={'Processing...'}
                            duration={50000}
                        />

                        {permitTypeFormToggle && <PermitTypeForm />}
                        {!permitTypeFormToggle && (
                            <>
                                <div className='add-type-btn'>
                                    {/* <IonTitle className='ion-padding'>Permit Types </IonTitle> */}
                                    <IonButton onClick={openPermitTypeForm}>
                                        <IonIcon slot='icon-only' icon={add}></IonIcon>
                                    </IonButton>
                                </div>
                                <AdminTypeList />
                            </>
                        )}
                    </IonContent>
                </div>
            </IonSplitPane>
        </IonApp>
    );
}

export default AdminPermitTypes;
