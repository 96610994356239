import { modalToggleStore, emergencyStore, toggleStore, appStore } from '../../store/Store';
import '../../theme/EmergencyWidget.css';
import {
    IonCardContent,
    IonCard,
    IonInput,
    IonButton,
    IonCardTitle,
    IonModal,
    IonCardSubtitle,
} from '@ionic/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {  subToCity } from '../../firebaseConfig';

function GeneralEmergency() {
    const resetEmailCountDown = appStore.useState((s) => s.resetEmailCountDown);
    const pwlessSignUp = toggleStore.useState((s) => s.pwlessSignUp);
    const isUpdateModal = toggleStore.useState((s) => s.isUpdateModal);
    const successfulSend = toggleStore.useState((s) => s.successfulSend);
    const emergencyEmailEmpty = toggleStore.useState((s) => s.emergencyEmailEmpty);
    const email = emergencyStore.useState((s) => s.email);
    const emergencyModal = modalToggleStore.useState((s) => s.emergencyModal);
    const params = useParams();
    const city = params.city;
    useEffect(() => {
        if (resetEmailCountDown > 0) {
            setTimeout(() => {
                appStore.update((s) => {
                    s.resetEmailCountDown = resetEmailCountDown - 1;
                });
            }, 1000);
        } else {
            toggleStore.update((s) => {
                s.pwlessSignUp = true;
            });
        }
    }, [resetEmailCountDown]);
    const closeEmergencyModal = () => {
        modalToggleStore.update((s) => {
            s.emergencyModal = false;
        });
        window.location.href = '/';
    };
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const openEmergencyModal = () => {
        if (validateEmail(email)) {
            toggleStore.update((s) => {
                s.pwlessSignUp = false;
                s.emergencyEmailEmpty = false;
                s.successfulSend = true;
            });
            appStore.update((s) => {
                s.resetEmailCountDown = 30;
            });
            // noPwSignUp(email, city);
            subToCity(email, city);
        } else {
            toggleStore.update((s) => {
                s.emergencyEmailEmpty = true;
            });
        }
    };

    const updateEmail = (e) => {
        emergencyStore.update((s) => {
            s.email = e.detail.value;
        });
    };

    const toUpdateModal = () => {
        toggleStore.update((s) => {
            s.isUpdateModal = true;
        });
    };
    const toSignupModal = () => {
        toggleStore.update((s) => {
            s.isUpdateModal = false;
        });
    };
    if (!isUpdateModal) {
        return (
            <>
                <IonCard className='emergency-card' mode='ios'>
                    <IonCardContent>
                        <IonCardTitle>Sign Up For Emergency Notifications</IonCardTitle>
                        <IonCardSubtitle>Please enter your email below to sign up.</IonCardSubtitle>
                        <div className='email-input-div ion-padding-vertical'>
                            <IonInput
                                onIonChange={(e) => {
                                    updateEmail(e);
                                }}
                                className='email-input'
                                placeholder='Enter Email'
                                mode='ios'
                                required
                                pattern='email'
                                type='email'
                                inputMode='email'
                                lines='1'
                            />
                            <IonButton
                                disabled={!pwlessSignUp}
                                onClick={openEmergencyModal}
                                color='tertiary'
                                className='ion-padding-horizontal'
                            >
                                {pwlessSignUp && `Sign up `}
                                {!pwlessSignUp && `Sign up  ${resetEmailCountDown}`}
                            </IonButton>
                        </div>
                        <p className={emergencyEmailEmpty ? 'err' : 'hide'}>
                            &nbsp; Please enter a valid email*
                        </p>
                        <p className={successfulSend ? 'success' : 'hide'}>
                            &nbsp; Email Successfully Subscribed!
                        </p>
                        <div className='update-div'>
                            <IonCardSubtitle className='ion-margin-vertical'>
                                Already signed up?{' '}
                                <span
                                    className='emergency-btns'
                                    onClick={toUpdateModal}
                                    style={{ color: '#3880ff' }}
                                >
                                    Manage my notifications
                                </span>
                            </IonCardSubtitle>
                        </div>
                    </IonCardContent>
                </IonCard>
                <IonModal isOpen={emergencyModal}>
                    <h2 className='ion-padding'>
                        Thank you for subsribing to Parry Sound Emergency Notifications.
                    </h2>

                    <p className='ion-padding'>
                        We have sent you an email to verify your address. Please click the link
                        within to set your notification preferences.
                    </p>
                    <p className='ion-padding'>
                        <strong>Thank you,</strong>
                        <br />
                        FirePermit.Online
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <IonButton
                        shape='round'
                        expand='block'
                        color='tertiary'
                        onClick={closeEmergencyModal}
                        className='ion-padding'
                    >
                        Close
                    </IonButton>
                </IonModal>
            </>
        );
    } else {
        return (
            <>
                <IonCard className='emergency-card' mode='ios'>
                    <IonCardContent>
                        <IonCardTitle>Update Profile</IonCardTitle>
                        <IonCardSubtitle>
                            Please enter your email and we will send you a new login link.
                        </IonCardSubtitle>
                        <div className='email-input-div ion-padding-vertical'>
                            <IonInput
                                onIonChange={(e) => {
                                    updateEmail(e);
                                }}
                                className='email-input'
                                placeholder='Enter Email'
                                mode='ios'
                                required
                                pattern='email'
                                type='email'
                                inputMode='email'
                                lines='1'
                            />
                            <IonButton
                                disabled={!pwlessSignUp}
                                onClick={openEmergencyModal}
                                color='tertiary'
                                className='ion-padding-horizontal'
                            >
                                {pwlessSignUp && `Update `}
                                {!pwlessSignUp && `Update  ${resetEmailCountDown}`}
                            </IonButton>
                        </div>
                        <p className={emergencyEmailEmpty ? 'err' : 'hide'}>
                            &nbsp; Please enter a valid email*
                        </p>
                        <p className={successfulSend ? 'success' : 'hide'}>
                            &nbsp; Email Successfully Subscribed!
                        </p>
                        <div className='update-div'>
                            <IonCardSubtitle className='ion-margin-vertical'>
                                Need to sign up?{' '}
                                <span
                                    className='emergency-btns'
                                    onClick={toSignupModal}
                                    style={{ color: '#ec335b' }}
                                >
                                    Back to Sign Up
                                </span>
                            </IonCardSubtitle>
                        </div>
                    </IonCardContent>
                </IonCard>
                <IonModal isOpen={emergencyModal}>
                    <p className='ion-padding'>
                        Thanks for subsribing to Parry Sound - emergency notification.
                        <br />
                        Please follow the link inside the email we sent you to update your profile.
                        {/* We have sent a verification email to "{email}".
                    ,only verified emails will received emergency
                    notifications. It can take up to 3-5 min for the email to appear in your inbox,
                    and don't forget to check your spam or junk email */}
                    </p>

                    {/* <p className='ion-padding'>
                    If the email has been used for registeration, you won't receive the verification
                    email. However, you will be able to manage subscription through your account's
                    profile page
                </p> */}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <IonButton
                        shape='round'
                        expand='block'
                        color='tertiary'
                        onClick={closeEmergencyModal}
                        className='ion-padding'
                    >
                        Close
                    </IonButton>
                </IonModal>
            </>
        );
    }
}

export default GeneralEmergency;
