import NoPwRegisterModal from '../../components/user/NoPwRegisterModal';
import '../../theme/SignUpForm.css';
function SignUpForm() {
    return (
        <>
            <NoPwRegisterModal />
        </>
    );
}

export default SignUpForm;
