import {
    IonCard,
    IonRow,
    IonCol,
    IonCardHeader,
    IonCardTitle,
    IonGrid,
    IonCardSubtitle,
    IonIcon,
    IonButton,
    IonToggle,
    IonAlert,
    IonText,
} from '@ionic/react';
import { useEffect } from 'react';
import '../../theme/MyPermitList.css';
import {
    permitsStore,
    appStore,
    toggleStore,
    profileFormStore,
    toastStore,
} from '../../store/Store';
import { documentText } from 'ionicons/icons';
import PDFDoc from '../common/PDFDoc';
import {
    updatePermitActiveness,
    resendEmailVerification,
    cancelSub,
    reactivateSub,
} from '../../firebaseConfig';
function UserPermitList() {
    const userUpdateAct = toggleStore.useState((s) => s.userUpdateAct);
    const currentPermit = permitsStore.useState((s) => s.currentPermit);
    const userUpdateSub = toggleStore.useState((s) => s.userUpdateSub);
    const currentUserPermits = permitsStore.useState((s) => s.currentUserPermits);
    const pdfViewerToggle = toggleStore.useState((s) => s.pdfViewerToggle);
    const permitIdForPDF = permitsStore.useState((s) => s.permitIdForPDF);
    const settingsForm = profileFormStore.useState((s) => s.settingsForm);
    const updateSubMsg = toastStore.useState((s) => s.updateSubMsg);
    const resetEmailCountDown = appStore.useState((s) => s.resetEmailCountDown);
    useEffect(() => {
        if (resetEmailCountDown > 0) {
            setTimeout(() => {
                appStore.update((s) => {
                    s.resetEmailCountDown = resetEmailCountDown - 1;
                });
            }, 1000);
        } else {
            toggleStore.update((s) => {
                s.sendVerificationEmail = false;
            });
        }
    }, [resetEmailCountDown]);

    const openPDF = (id) => {
        toggleStore.update((s) => {
            s.pdfViewerToggle = true;
        });
        permitsStore.update((s) => {
            s.permitIdForPDF = id;
        });
    };
    const back = () => {
        toggleStore.update((s) => {
            s.pdfViewerToggle = false;
        });
    };
    const openActivateAlert = (permit) => {
        if (permit.activate) {
            toastStore.update((s) => {
                s.updateSubMsg = 'Do you wish to change the status of this permit to inactive';
            });
        } else {
            toastStore.update((s) => {
                s.updateSubMsg = 'Do you wish to change the status of this permit to active';
            });
        }

        toggleStore.update((s) => {
            s.userUpdateAct = true;
        });
        permitsStore.update((s) => {
            s.currentPermit = permit;
        });
    };
    const closeActivateAlert = () => {
        toggleStore.update((s) => {
            s.userUpdateAct = false;
        });
    };

    const changeActiveness = () => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });

        //update state of currentUserPermits
        const tempUserPermits = [...currentUserPermits];

        let index;
        tempUserPermits.map((permit) => {
            if (permit.id === currentPermit.id) {
                index = tempUserPermits.indexOf(permit);
            }
        });

        const tempPermit = { ...tempUserPermits[index] };
        tempPermit.activate = !tempPermit.activate;
        tempUserPermits[index] = tempPermit;
        permitsStore.update((s) => {
            s.currentUserPermits = tempUserPermits;
        });
        if (currentPermit.activate) {
            updatePermitActiveness(currentPermit.id, false).then(() => {
                permitsStore.update((s) => {
                    s.currentPermit = '';
                });
                appStore.update((s) => {
                    s.loadingSpinner = false;
                });
            });
        } else {
            updatePermitActiveness(currentPermit.id, true).then(() => {
                permitsStore.update((s) => {
                    s.currentPermit = '';
                });
                appStore.update((s) => {
                    s.loadingSpinner = false;
                });
            });
        }
    };

    const openSubAlert = (permit) => {
        if (permit.autoRenew) {
            toastStore.update((s) => {
                s.updateSubMsg = 'Do you wish to cancel subscription for this permit';
            });
        } else {
            toastStore.update((s) => {
                s.updateSubMsg = 'Do you wish to activate subscription for this permit';
            });
        }
        toggleStore.update((s) => {
            s.userUpdateSub = true;
        });
        permitsStore.update((s) => {
            s.currentPermit = permit;
        });
    };
    const updateSub = () => {
        appStore.update((s) => {
            s.loadingSpinner = true;
        });
        const tempUserPermits = [...currentUserPermits];
        let index;
        tempUserPermits.map((permit) => {
            if (permit.id === currentPermit.id) {
                index = tempUserPermits.indexOf(permit);
            }
        });
        const tempPermit = { ...tempUserPermits[index] };
        tempPermit.autoRenew = !tempPermit.autoRenew;
        tempUserPermits[index] = tempPermit;
        permitsStore.update((s) => {
            s.currentUserPermits = tempUserPermits;
        });
        //before change is true so cancel sub
        if (currentPermit.autoRenew === true) {
            cancelSub(currentPermit).then(() => {
                appStore.update((s) => {
                    s.loadingSpinner = false;
                });
            });
        } else {
            reactivateSub(currentPermit).then(() => {
                appStore.update((s) => {
                    s.loadingSpinner = false;
                });
            });
        }
    };
    const closeSubAlert = () => {
        toggleStore.update((s) => {
            s.userUpdateSub = false;
        });
    };

    // commented out so that non-email-verified user can still apply for permit
    // if (!emailVerified) {
    //     return (
    //         <>
    //             An email has been sent to your inbox. It can take up to 3-5 min for it to appear in
    //             your inbox. Please verify your email first before you can access your account <br />{' '}
    //             <br />
    //             If you haven't receive the email, you can try to send it by clicking the button
    //             below.
    //             <br />
    //             <IonButton disabled={sendVerificationEmail} onClick={sendResetPwEmail}>
    //                 {sendVerificationEmail && `Send Verification Email  ${resetEmailCountDown}`}
    //                 {!sendVerificationEmail && 'Send Verification Email'}
    //             </IonButton>
    //         </>
    //     );
    // } else
    if (permitIdForPDF && pdfViewerToggle) {
        return (
            <>
                <div className='pdf-open'>
                    <IonButton onClick={back} shape='round' color='tertiary' className='ion-margin'>
                        Back to My Permits
                    </IonButton>
                    <PDFDoc />
                </div>
            </>
        );
    } else {
        return (
            <>
                <IonAlert
                    isOpen={userUpdateAct}
                    onDidDismiss={closeActivateAlert}
                    header={'Confirm'}
                    message={updateSubMsg}
                    buttons={[
                        {
                            text: 'Cancel',
                            handler: () => {
                                permitsStore.update((s) => {
                                    s.currentPermit = null;
                                });
                            },
                        },
                        {
                            text: 'Confirm',

                            handler: () => {
                                changeActiveness();
                            },
                        },
                    ]}
                />
                <IonAlert
                    isOpen={userUpdateSub}
                    onDidDismiss={closeSubAlert}
                    header={'Confirm'}
                    message={updateSubMsg}
                    buttons={[
                        {
                            text: 'Cancel',
                            handler: () => {
                                permitsStore.update((s) => {
                                    s.currentPermit = null;
                                });
                            },
                        },
                        {
                            text: 'Confirm',

                            handler: () => {
                                updateSub();
                            },
                        },
                    ]}
                />
                <IonGrid>
                    <IonRow>
                        {currentUserPermits &&
                            currentUserPermits.map((permit) => {
                                let documentColor;

                                if (permit.inspected) {
                                    documentColor = '#0099ff';
                                } else {
                                    documentColor = '#ec335b';
                                }
                                if (permit.paid) {
                                    return (
                                        <IonCol
                                            key={permit.id}
                                            size-sm='12'
                                            size-md='6'
                                            size-lg='4'
                                        >
                                            <IonCard className='ion-padding' key={permit.id}>
                                                <div
                                                    className='permit-card'
                                                    onClick={() => {
                                                        openPDF(permit.id);
                                                    }}
                                                >
                                                    <div className='permit-icon-div'>
                                                        <IonIcon
                                                            style={{ color: documentColor }}
                                                            className='permit-icon'
                                                            icon={documentText}
                                                        />
                                                        {permit.inspected && (
                                                            <IonText
                                                                className='ion-padding-horizontal'
                                                                color='success'
                                                                style={{ fontWeight: 'bold' }}
                                                            >
                                                                Your permit has been inspected and
                                                                approved. You may conduct your burn.
                                                            </IonText>
                                                        )}
                                                        {!permit.inspected && (
                                                            <IonText
                                                                className='ion-padding-horizontal'
                                                                color='danger'
                                                                style={{ fontWeight: 'bold' }}
                                                            >
                                                                Your permit is not yet inspected.
                                                                This status will change once
                                                                inspected. Burning is currently not
                                                                authorized.
                                                            </IonText>
                                                        )}
                                                    </div>
                                                    <IonCardHeader>
                                                        <IonCardTitle>
                                                            <span id='permit-type'>
                                                                {permit.type}
                                                            </span>
                                                        </IonCardTitle>
                                                        Expiry Date: {permit.expired}
                                                        <IonCardSubtitle id='weather-current'>
                                                            {permit.address}
                                                        </IonCardSubtitle>
                                                    </IonCardHeader>
                                                </div>
                                                <div className='toggle-bar ion-margin-start'>
                                                    {' '}
                                                    <div className='toggle'>
                                                        <div className='toggle-text'>Subscribe</div>
                                                        <IonToggle
                                                            onClick={(e) => {
                                                                openSubAlert(permit);
                                                            }}
                                                            checked={permit.autoRenew}
                                                        />
                                                    </div>
                                                    {settingsForm && settingsForm.activateToggle && (
                                                        <div className='toggle'>
                                                            <div className='toggle-text'>
                                                                Activate
                                                            </div>
                                                            <IonToggle
                                                                onClick={() => {
                                                                    openActivateAlert(permit);
                                                                }}
                                                                checked={permit.activate}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </IonCard>
                                        </IonCol>
                                    );
                                }
                            })}
                    </IonRow>
                </IonGrid>
            </>
        );
    }
}

export default UserPermitList;
