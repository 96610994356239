import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonMenu,
    IonSplitPane,
    IonMenuToggle,
    IonButton,
    IonApp,
    IonButtons,
    IonIcon,
    IonImg,
    IonLoading,
} from '@ionic/react';
import { menu } from 'ionicons/icons';
import UserProfile from '../../components/user/UserProfile';
import UserMenu from '../../components/user/UserMenu';
import { appStore } from '../../store/Store';
import logo from '../../images/firepermitonlinelogo.png';
function Profile() {
    const loadingSpinner = appStore.useState((s) => s.loadingSpinner);
    return (
        <IonApp>
            <IonSplitPane when='lg' contentId='main-content'>
                <IonMenu contentId='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary-contrast'>
                            <IonImg src={logo} />
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        <UserMenu />
                    </IonContent>
                </IonMenu>

                <div className='ion-page' id='main-content'>
                    <IonHeader>
                        <IonToolbar color='primary'>
                            <IonButtons slot='start'>
                                <IonMenuToggle>
                                    <IonButton>
                                        {' '}
                                        <IonIcon slot='icon-only' icon={menu}></IonIcon>
                                    </IonButton>
                                </IonMenuToggle>
                            </IonButtons>
                            <IonTitle></IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent className='ion-padding'>
                        <UserProfile />
                    </IonContent>
                    <IonLoading
                        isOpen={loadingSpinner}
                        message={'Processing...'}
                        duration={50000}
                    />
                </div>
            </IonSplitPane>
        </IonApp>
    );
}

export default Profile;
