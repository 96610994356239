import { weatherStore } from '../../store/Store';
import { useEffect } from 'react';
import WeatherIcon from 'react-icons-weather';
import axios from 'axios';
import '../../theme/WeatherWidget.css';
import {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonCardSubtitle,
} from '@ionic/react';

function WeatherWidget() {
    const weatherIconId = weatherStore.useState((s) => s.weatherIconId);
    const windSpeed = weatherStore.useState((s) => s.windSpeed);
    const temperature = weatherStore.useState((s) => s.temperature);
    const weatherDesc = weatherStore.useState((s) => s.weatherDesc);
    useEffect(() => {
        axios
            .get(
                `https://api.openweathermap.org/data/2.5/weather?lat=45.3505945&lon=-80.0325275&appid=a6e74de9b3eb9f1dbcaf190984c0f0d5&units=metric`
            )
            .then((data) => {
                weatherStore.update((s) => {
                    s.weatherIconId = `${data.data.weather[0].id}`;
                    s.temperature = Math.round(data.data.main.temp);
                    s.windSpeed = data.data.wind.speed;
                    s.weatherDesc = data.data.weather[0].description;
                });
            });
    }, []);

    return (
        <IonCard mode='ios' className='weather-card'>
            <br />
            <div
                style={{
                    width: 'auto',
                    height: 'auto',
                    margin: '20px auto 20px auto',
                }}
            >
                <IonCardHeader>
                    {' '}
                    {weatherIconId && (
                        <div className='weather-icon'>
                            <WeatherIcon
                                style={{
                                    fontSize: '100px',
                                }}
                                name='owm'
                                iconId={weatherIconId}
                                flip='horizontal'
                            />
                            &nbsp;&nbsp;&nbsp;
                            <p id='temp-number'>
                                {temperature}
                                <span id='temp-degree'>°</span>
                            </p>
                        </div>
                    )}
                    <br />
                    <IonCardSubtitle id='weather-current'>Current</IonCardSubtitle>
                    <IonCardTitle>Weather</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <p>
                        Conditions are described as "{weatherDesc}" and the wind speed is currently{' '}
                        {windSpeed}kph
                    </p>
                </IonCardContent>
            </div>
        </IonCard>
    );
}

export default WeatherWidget;
