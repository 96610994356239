import { coordsStore, shapeFormStore, appStore, shapeStore, toggleStore } from '../../store/Store';
import { useEffect } from 'react';
import { deleteShapeData, fetchAllShapes, fetchCurrentShapeData } from '../../firebaseConfig';
import '../../theme/ShapeInfoList.css';
import { IonIcon, IonAlert } from '@ionic/react';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import { createOutline, trashOutline } from 'ionicons/icons';
function ShapeInfoForm() {
    const allShapes = shapeStore.useState((s) => s.allShapes);
    useEffect(() => {
        fetchAllShapes().then((data) => {
            const finalDataArr = [];
            data.map((shape) => {
                let editBtn;
                let deleteBtn;
                if (shape.shape === 'polygon') {
                    editBtn = <IonIcon id={shape.id} onClick={editPolygon} icon={createOutline} />;
                    deleteBtn = (
                        <IonIcon
                            id={shape.id}
                            onClick={deletePolygon}
                            icon={trashOutline}
                        ></IonIcon>
                    );
                }
                if (shape.shape === 'circle') {
                    editBtn = <IonIcon id={shape.id} onClick={editCircle} icon={createOutline} />;
                    deleteBtn = (
                        <IonIcon id={shape.id} onClick={deleteCircle} icon={trashOutline}></IonIcon>
                    );
                }
                if (shape.shape === 'rectangle') {
                    editBtn = (
                        <IonIcon id={shape.id} onClick={editRectangle} icon={createOutline} />
                    );
                    deleteBtn = (
                        <IonIcon
                            id={shape.id}
                            onClick={deleteRectangle}
                            icon={trashOutline}
                        ></IonIcon>
                    );
                }
                const shapeArr = [];
                shapeArr.push(
                    shape.shape,
                    shape.info.name,
                    shape.info.description,
                    editBtn,
                    deleteBtn
                );
                finalDataArr.push(shapeArr);
            });
            shapeStore.update((s) => {
                s.allShapes = finalDataArr;
            });
        });
    }, []);

    let theme = createTheme();
    theme = responsiveFontSizes(theme);
    const deleteAlertToggle = toggleStore.useState((s) => s.deleteAlertToggle);
    const currentShapeID = shapeStore.useState((s) => s.currentShapeID);
    const formToggle = appStore.useState((s) => s.formToggle);
    const currentShape = shapeStore.useState((s) => s.currentShape);

    const closeDeleteAlert = () => {
        toggleStore.update((s) => {
            s.deleteAlertToggle = false;
        });
    };

    const deleteShape = async () => {
        if (currentShape === 'Circle') {
            await deleteShapeData(currentShapeID);
            coordsStore.update((s) => {
                s.selectedCoord = null;
            });
            appStore.update((s) => {
                s.formToggle = false;
            });
        }

        if (currentShape === 'Rectangle') {
            await deleteShapeData(currentShapeID);

            coordsStore.update((s) => {
                s.selectedCoord = null;
            });
            appStore.update((s) => {
                s.formToggle = false;
            });
        }

        if (currentShape === 'Polygon') {
            await deleteShapeData(currentShapeID);

            coordsStore.update((s) => {
                s.selectedCoord = null;
            });
            appStore.update((s) => {
                s.formToggle = false;
            });
        }
        window.location.href = '/exclusion';
    };
    const deleteCircle = (e) => {
        const id = e.target.getAttribute('id');
        shapeStore.update((s) => {
            s.currentShapeID = id;
        });
        shapeStore.update((s) => {
            s.currentShape = 'Circle';
        });
        toggleStore.update((s) => {
            s.deleteAlertToggle = true;
        });
    };
    const deleteRectangle = (e) => {
        const id = e.target.getAttribute('id');
        shapeStore.update((s) => {
            s.currentShapeID = id;
        });
        shapeStore.update((s) => {
            s.currentShape = 'Rectangle';
        });
        toggleStore.update((s) => {
            s.deleteAlertToggle = true;
        });
    };
    const deletePolygon = (e) => {
        const id = e.target.getAttribute('id');

        shapeStore.update((s) => {
            s.currentShapeID = id;
        });
        shapeStore.update((s) => {
            s.currentShape = 'Polygon';
        });
        toggleStore.update((s) => {
            s.deleteAlertToggle = true;
        });
    };

    const editCircle = async (e) => {
        const id = e.target.getAttribute('id');

        shapeStore.update((s) => {
            s.currentShape = 'Circle';
        });
        shapeStore.update((s) => {
            s.currentShapeID = id;
        });
        await fetchCurrentShapeData(id).then((data) => {
            shapeFormStore.update((s) => {
                s.shapeForm = data.info;
            });
        });
        appStore.update((s) => {
            s.formToggle = true;
        });
    };

    const editRectangle = async (e) => {
        const id = e.target.getAttribute('id');
        shapeStore.update((s) => {
            s.currentShape = 'Rectangle';
        });
        shapeStore.update((s) => {
            s.currentShapeID = id;
        });
        await fetchCurrentShapeData(id).then((data) => {
            shapeFormStore.update((s) => {
                s.shapeForm = data.info;
            });
        });
        appStore.update((s) => {
            s.formToggle = true;
        });
    };

    const editPolygon = async (e) => {
        const id = e.target.getAttribute('id');
        shapeStore.update((s) => {
            s.currentShape = 'Polygon';
        });
        shapeStore.update((s) => {
            s.currentShapeID = id;
        });
        await fetchCurrentShapeData(id).then((data) => {
            shapeFormStore.update((s) => {
                s.shapeForm = data.info;
            });
        });
        appStore.update((s) => {
            s.formToggle = true;
        });
    };

    const columns = ['Shape', 'Name', 'Desc', 'Edit', 'Delete'];
    return (
        <>
            {allShapes && !formToggle && (
                <ThemeProvider theme={theme}>
                    <br />
                    <MUIDataTable
                        title={'All Exlusions'}
                        data={allShapes}
                        columns={columns}
                        options={{
                            selectableRows: 'none', // <===== will turn off checkboxes in rows
                            print: false,
                            viewColumns: false,
                        }}
                    />
                </ThemeProvider>
            )}
            <IonAlert
                isOpen={deleteAlertToggle}
                onDidDismiss={closeDeleteAlert}
                header={'Confirm'}
                message={'Are you sure you want delete this shape???'}
                buttons={[
                    {
                        text: 'Cancel',
                        handler: () => {},
                    },
                    {
                        text: 'Delete',

                        handler: () => {
                            deleteShape();
                        },
                    },
                ]}
            />
        </>
    );
}

export default ShapeInfoForm;
