import { IonItem, IonLabel, IonButton, IonInput } from '@ionic/react';
import { useEffect } from 'react';
import '../../theme/DepartmentInfo.css';
import axios from 'axios';
import { appStore, profileFormStore, widgetControlStore } from '../../store/Store';
import { updateWeather } from '../../firebaseConfig';
import { useStoreState } from 'pullstate';

function WeatherWidgetControl() {
    const userInfo = appStore.useState((s) => s.userInfo);
    const weatherAddress = widgetControlStore.useState((s) => s.weatherAddress);
    const { mapsAPI } = useStoreState(profileFormStore);

    useEffect(() => {
        widgetControlStore.update((s) => {
            s.weatherAddress = userInfo.weatherAddress.address;
        });
    }, []);
    const changeAddress = (value) => {
        widgetControlStore.update((s) => {
            s.weatherAddress = value;
        });
    };

    const updateAddressCoord = () => {
        let addressForm = {};
        axios
            .get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: weatherAddress,
                    key: mapsAPI,
                },
            })
            .then((response) => {
                addressForm.address = weatherAddress;
                addressForm.lat = response.data.results[0].geometry.location.lat;
                addressForm.lng = response.data.results[0].geometry.location.lng;
                updateWeather(userInfo.id, addressForm);
            });
    };

    return (
        <>
            {' '}
            <IonItem>
                <IonLabel>Address:</IonLabel>
                <IonInput
                    onIonChange={(e) => {
                        changeAddress(e.target.value);
                    }}
                    required
                    placeholder='Enter name'
                    value={weatherAddress}
                />
            </IonItem>
            <br />
            <IonButton
                onClick={() => {
                    updateAddressCoord();
                }}
            >
                Save
            </IonButton>
        </>
    );
}

export default WeatherWidgetControl;
